import React from "react";
import { useContext } from "react";
import { Globalinformation } from "../MainSectionProvider";
import {
    BASE_URL_IMG,
  } from "../../Utils/services";
  import {motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SaveForum = () => {
    const {saveForm,setFilterValue} =useContext(Globalinformation)
    let navigate = useNavigate();
    const { t } = useTranslation(['home'])

 
const handleRedirectpage = (data) => {
    navigate(`/forum/${data._id}`, {
      state: {
        img: data.media[0].link,
        qustion: data.qustion,
        commentcount: data.commentcount,
        tag: data.tag,
        item:data,
        likes: data.likes,
        contant: data.contant,
        _id: data._id,
      },
    });
  };

  return (
    <section className="bg-white rounded-lg px-4 pb-4 savedForum ">
      <h3>{`${t('Saved Forum')}`}</h3>

      <div className="h-40 overflow-y-scroll" id="filtersrollbar">
        {saveForm &&
          saveForm.map((data, index) => {
            return (
              <React.Fragment key={index}>
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 0.9 }}
                  transition={{ type: "spring", stiffness: 260, damping: 20 }}
                >
                  <div className="flex items-center my-4 cursor-pointer" onClick={()=>handleRedirectpage(data)}>
                    <img
                      src={`${BASE_URL_IMG}${data.media[0].link}`}
                      alt="forum img"
                      className="w-12 h-12"
                    />
                    <h4 className="ml-2">{data.question}</h4>
                  </div>
                </motion.div>
              </React.Fragment>
            );
          })}
      </div>
    </section>
  );
};

export default SaveForum;
