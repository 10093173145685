import React from 'react'
import { useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Filter from '../../Common/Filter'
import SearchEvent from '../../Common/SearchHeader/SearchEvent'
import { getEvent } from '../../Utils/services'
import RealatedThread from '../Forum/commentsection/RealatedThread'
import ForumRightSidebar from '../Forum/ForumRightSidebar'
import CalenderDetail from './CalenderDetail'
import EventMain from './EventMain'

const  Events = () => {
   const [titleMonth,setTitleMonth] =useState()
    const [eventAllData,setEventAllData] =useState([])
    const getEventApiData = async (year, month) => {
        //console.log((year, month,"data")
        try {
          const result = await getEvent(
            {
              "month":month,
              "year": year
            }
          );
          
          //console.log((result.res,"events")
          if (result.res.data.length > 0) {
          setEventAllData(result.res.data);
          }
          else{
            setEventAllData([])
          }
        } catch (error) {
          //console.log((error);
        }
      };



  return (
    <Container>
    <Row>
       <div className="mobilesearch mt-20">
        <SearchEvent  eventAllData={eventAllData}
         getEventApiData={getEventApiData}  />
       </div>
       
      <Col lg={7} className="eventresponsivemain" >
        <EventMain  showEvent={eventAllData} titleMonth={titleMonth} />
      </Col>
      <Col lg={5} className="eventsidemain">
        <CalenderDetail 
         eventAllData={eventAllData}
         getEventApiData={getEventApiData}
         setTitleMonth={setTitleMonth}
        />
      </Col>
    </Row>
  </Container>
  )
}

export default Events