import React from 'react'
import { toast } from 'react-toastify'
import courseType from '../../../Assets/images/elearning/courseType.png'
import time from '../../../Assets/images/elearning/time.svg'
import { enrollCoursed } from '../../../Utils/services'
import { useTranslation } from 'react-i18next'

const Details = ({ skilldetails,subTopic,enrollCourse }) => {
  const { t } = useTranslation(['home'])
  
  const handleEnrollNow =async () => {
    const result = await enrollCoursed({
      "courseId": skilldetails._id
    })
    toast.success(result.res.message)
    window.location.reload(); 

  }

  return (
    <div className="mt-3 skill-details">
      <h3>{skilldetails?.title}</h3>

      <div className="flex items-center justify-between skill-media pt-2">
        <div className="flex">
          <div className="flex items-center">
            <img src={time} alt="" className="h-3" />
            <span className="text-blue ml-1">
              {skilldetails.CourseDuretion}
            </span>
          </div>

            <div className="ml-3 flex items-center">
                <img src={courseType} alt="" className="h-3" />
                <span className="text-blue ml-1">{skilldetails.CourseType}</span>
            </div>
        </div>
        <div className='d-flex items-center'>
          <span>{`${t('Uploaded on')}`} 26,dec,22</span>
        </div>
      </div>

      <div dangerouslySetInnerHTML={{__html:skilldetails?.description}}/>
       <h3 className='pt-2'>What You'll Learn</h3>
      {
        !enrollCourse?.includes(skilldetails._id)
        ?
        <div className='mt-4'>
        <button className='enroll-btn' onClick={()=>handleEnrollNow()}>{`${t('Enroll Now')}`}</button>
       </div>
       :""
      }
      
     
    </div>
  )
}

export default Details
