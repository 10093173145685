import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext, useState } from "react";
import { ModalContext } from "../Context";
import { FormReportComment, galleryReportPost } from "../Utils/services";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const issuesData = [
  {
    id: 2,
    title: "It's spam",
  },
  {
    id: 3,
    title: "Nudity or sexual activity",
  },
  {
    id: 4,
    title: "Hate speech or symbols",
  },
  {
    id: 5,
    title: "False information",
  },
  {
    id: 6,
    title: "Bullying or harassment",
  },
  {
    id: 7,
    title: "Scam or fraud",
  },
  {
    id: 8,
    title: "Violence or dangerous organizations",
  },
  {
    id: 9,
    title: "Intellectual property violation",
  },
  {
    id: 10,
    title: "Sale of illegal or regulated goods",
  },
  {
    id: 11,
    title: "Suicide or self-injury",
  },
  {
    id: 12,
    title: "Inappropriate language",
  },
  {
    id: 13,
    title: "Something else",
  },
];

const ForumReplyReport = ({ threadId, handlegetComment, ReplyId }) => {
  const { t } = useTranslation(["home"]);
  const [issue, setIssue] = useState("");
  const [loader, setLoader] = useState(false);
  const [comment, setComment] = useState("");
  const [showOther, setShowOther] = useState(false);

  const modalContext = useContext(ModalContext);
  const { closeModal } = modalContext;
  const handleReportSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      if (issue === "") {
        toast.error("Please Select a Issue")
      } else {
        const result = FormReportComment({
          postId: threadId,
          issue: issue,
          comment: comment,
          replyId: ReplyId,
        });
      closeModal();

      }

    } catch (error) {
      setLoader(false);
    }
  };

  const handleIssueData = (val) => {
    setIssue(val);
    if (val === "Something else") {
      setShowOther(true);
    } else {
      setShowOther(false);
    }
  };

  return (
    <div>
      <Autocomplete
        id="report"
        options={issuesData}
        getOptionLabel={(option) => option.title}
        inputValue={issue}
        onInputChange={(event, newInputValue) => handleIssueData(newInputValue)}
        renderInput={(params) => (
          <TextField id="report" {...params} label="Select the Issue" />
        )}
      />

      {showOther && (
        <div>
          <div className="Address mt-4">
            <div className="AddLoction mb-2">
              <h3>Other</h3>
            </div>
            <div className="loction">
              <div component="div" className="post mb-2">
                <textarea
                  id=""
                  cols="35"
                  rows="7"
                  className="outline-none ask_question"
                  placeholder="Comment here for other issue"
                  name="comment"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      )}

      <div component="div" className="mt-8 flex justify-end items-center">
        <div className="mr-8">
          <button className="cancel-btn" onClick={closeModal}>{`${t(
            "Cancel"
          )}`}</button>
        </div>
        <div>
          <button className="delete-btn" onClick={handleReportSubmit}>{`${t(
            "Report"
          )}`}</button>
        </div>
      </div>
    </div>
  );
};

export default ForumReplyReport;
