import logo from "../../Assets/images/glplogo.png";
import { loginServiceFirst, otpVerificationApi, otpVerificationLoginApi } from "../../Utils/services";
import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import {  useNavigate } from "react-router";
import { Link } from "react-router-dom";
import authBg from "../../Assets/images/bg_banner.png"
import OtpInput from "react-otp-input";
import AuthContext from "../../Utils/auth-context";
import CornerImg from "../../Assets/images/signincorner.png"
import Logo from "../../Assets/images/glp-logo-white.png"
import { useLocation } from "react-router/dist";
import { useTranslation } from "react-i18next";


const LoginOtpVerification = () => {
    const { t } = useTranslation(['home'])
    const [otp, setOtp] = useState("");
    const location = useLocation();
    const [emailValue, setEmailValue] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    const authCtx = useContext(AuthContext);
    const [isButtonActive, setIsButtonActive] = useState(false);

    useEffect(() => {
        if (location.state !== undefined) {
            const { fcmToken, email } = location.state;
            setEmailValue(email)
            setVerificationCode(fcmToken)
            //console.log((fcmToken, email, "fcmToken, email,")
        }
        if (location.state === undefined) {
            history.push("/login")
        }
    }, []);

    const handleOtpChange = (e) => {
        //console.log((e, "Value")
        setOtp(e);
    }
    const handleOtpSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            const result = await otpVerificationLoginApi({
                "email": emailValue,
                "otp": parseInt(otp)
            });
            if (result.res.success) {
                localStorage.setItem('adminId', result.res.data.id)
                localStorage.setItem('language', result.res.data.language)
                toast.success(result.res.message || "User Authenticated!");
                authCtx.login(result.res.data.accessToken, result.res.data.refreshToken, result.res.data.isSuperAdmin);
                setLoading(false)
                toast.success(result.res.message)
                history.replace("/");
            }
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }

    return (
        <div className="w-full bg-loginBg">
            <div className="grid lg:grid-cols-2 md:grid-cols-1  lg:gap-4  md:gap-0   mobileresposiveauth">
                <div className="w-full h-full">
                    <div className="glpLoginBgBanner">
                        <img src={Logo} alt="bg banner" className="w-1/2" />
                    </div>
                </div>
                <div className="flex justify-center items-center  mobileLoginscreen">
                    <div className="w-2/3 mx-auto auth-container">
                        <img src={CornerImg} alt="corner img" className="h-24 w-24 absolute right-0 top-0" />
                        <div className="w-1/2 mx-auto">
                            
                            <h2 className="primary-color font-sans text-2xl	 font-bold mb-10">
                            {`${t('Sign In')}`}
                            </h2>
                            <form className="authFormInputStyle" onSubmit={e => handleOtpSubmit(e)}>
                            <div className="mt-2 flex otpSection mb-8">
                                    <OtpInput
                                        value={otp}
                                        onChange={handleOtpChange}
                                        numInputs={5}
                                        isInputNum={true}
                                        renderInput={(props) => <input {...props} />}
                                    />
                                </div>
                                <button className={`${isButtonActive ? 'ActiveGetOtpBtn' : 'inactiveGetOtpBtn'}`}>{`${t('Get Otp')}`}</button>
                            </form>
                            <div className="text-center signUpSectionLogin">
                                <p>{`${t('Not Registered Yet')}`} ?</p>
                                <button className="btn signUpBtn">{`${t('Sign Up')}`}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default LoginOtpVerification;