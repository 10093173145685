import React from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import { ModalContext } from '../Context';
import { FormEditComment, storyEditcomment } from '../Utils/services';
import inputbtn from "../Assets/images/Social/inputbtn.png"

const StoryEditReply = ({id,commentid, handlegetComment,commentdata,ReplyId}) => {

    const [comment,setcoment] =useState(commentdata);


    const modalContext = useContext(ModalContext);
    const { closeModal} = modalContext;

    const EditComment = async (e) => {
         e.preventDefault();
        const result = await storyEditcomment({
          "postId": id,
          "commentId": commentid,
          "contents": comment,
          "replyId" :ReplyId

         });
        handlegetComment(id);
        closeModal();    
      }


  return (
     <form action="" onSubmit={EditComment} >
    <div id="focusinput" className="flex justify-between border p-2 my-3 rounded-xl">
     <input type="text" 
      value={comment}
      placeholder="Edit comment"
      className="commentinput"
      required
      onChange={(e) => setcoment(e.target.value)} />
     <button className="w-10 " ><img src={inputbtn} alt="" className="w-5" /></button>
    </div>
    </form>
  )


}

export default StoryEditReply