import logo from "../../Assets/images/glplogo.png";
import { forgotPasswordApi, loginService } from "../../Utils/services";
import { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import authBg from "../../Assets/images/bg_banner.png"
import CornerImg from "../../Assets/images/signincorner.png"
import Logo from "../../Assets/images/glp-logo-white.png"
import mibilelogo from "../../Assets/images/glplogotwo.png"

import { useLocation } from 'react-router-dom';
import CountryModal from "../../ModalTemplate/CountryModal";
import india from "../../Assets/images/country/india.png";
import { useNavigate } from "react-router/dist";
import { useTranslation } from "react-i18next";





const ForgotPassword = () => {
    const [userData, setUserData] = useState({
        phone: "",password:""
    })
    const { t } = useTranslation(['home'])
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [deviceToken,setdeviceToken] =useState()
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    
    const [countryCode,setCountryCode] =useState({
        Icon:india,
        Code:"91",
        cntyCode:"+91"     
    })
    let location =useLocation()
    const navigate = useNavigate();
  
    const handleForgetPassword = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            const result = await forgotPasswordApi({
                phone: userData.phone,
                fcmToken: deviceToken,
                code:countryCode?.Code
            });
            if (result.res.success) {
                setLoading(false);
                toast.success(result.res.message);
                navigate("/otp",{
                    state: {
                        verification_key: result.res.data.details,
                        phone: userData.phone,
                        code:countryCode?.Code
                    },
                });
            }
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
            //console.log((error);
        }
    };



    const handleChange = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value,
        })
    }

    useEffect(() => {
        if (userData?.phone === "") {
            setIsButtonActive(false)
        }
        else if (userData?.phone !== "") {
            setIsButtonActive(true)
        }
    }, [userData])


   useEffect(()=>{
    if (location.state !== undefined) {
    const {fcmToken} =location.state;
    setdeviceToken(fcmToken)
    //console.log((fcmToken,"data")
    }
   },[])


    return (
        // <div className="w-full relative">
        //     <img src={authBg} alt="" />
        //     <div className="flex absolute top-1/2 left-0 w-full">
        //         <div className="w-2/3 mx-auto auth-container">
        //             <div className="w-1/2 mx-auto text-center">
        //                 <img
        //                     src={logo}
        //                     className="mx-auto mb-10"
        //                     alt="logo"
        //                 />

        //                 <h2 className="primary-color font-sans text-2xl	 font-bold mb-10">
        //                     FORGOT PASSWORD ?
        //                 </h2>

        //                 <form className="auth-form" onSubmit={handleForgetPassword}>
        //                     <input
        //                         type="email"
        //                         placeholder="Email ID"
        //                         name="email"
        //                         value={email}
        //                         onChange={(e) => setEmail(e.target.value)}
        //                         required
        //                     />
        //                     {
        //                         loading ? <p>Loading...</p> :
        //                             <button
        //                                 className="primary-btn"
        //                                 type="submit"
        //                             >
        //                                 Send
        //                             </button>
        //                     }

        //                     <span
        //                         className="text-btn"
        //                     >
        //                         <Link to="/login">Remember password ?</Link>
        //                     </span>
        //                 </form>
        //             </div>
        //         </div>
        //     </div>
        // </div>

        <>
           <div className="w-full bg-loginBg">
            <div className="grid lg:grid-cols-2 md:grid-cols-1  lg:gap-4  md:gap-0   mobileresposiveauth">
                <div className="w-full h-full">
                    <div className="glpLoginBgBanner">
                    <img src={Logo} alt="bg banner" className="w-1/2"  />
                    </div>
                </div>
                <div className="flex justify-center items-center  mobileLoginscreen">
                <div className="w-2/3 mx-auto auth-container">
                <img src={CornerImg} alt="corner img" className="h-24 w-24 absolute right-0 top-0 cornerImg" />
                <div className="w-1/2 mx-auto mobileformscreen">
                   <div className="flex justify-center mobilelogo">
                          <img src={mibilelogo} alt="" />
                          </div>
                  <h2 className="primary-color font-sans text-2xl font-bold mb-10 mobilescreentitle">
                 { `${t('Forgot Password')} ?`}
                  </h2>
                <div className="auth-form authFormInputStyle ">

            
                <form  onSubmit={handleForgetPassword}>
                <div className="flex ">
                            <div className="flex items-center countrycode cursor-pointer" onClick={() => setModalShow(true)}>
                              <div className="country-img">
                                <img src={countryCode.Icon} alt="" />
                              </div>
                          
                              <p>{countryCode.cntyCode}</p>
                            </div>
                            
                            <input
                           type="text"
                           placeholder="Enter Phone Number"
                           name="phone"
                           value={userData.phone}
                           onChange={(e) => handleChange(e)}
                           required
                         />
                   
                        </div>
                 
                    <button className={`${isButtonActive ? 'ActiveGetOtpBtn' : 'inactiveGetOtpBtn'}`}>{`${t('Get Otp')}`}</button>

                </form>
                    <Link to="/login" className="mt-2">Remember password ?</Link>
                </div>
                
              
                  </div>
                 </div>
                </div>
            </div>
            <CountryModal    
             show={modalShow}                 
             onHide={() => setModalShow(false)}
             setModalShow={setModalShow}
             setCountryCode={setCountryCode}

            />
        </div>
        
        
        </>
    )
}

export default ForgotPassword;