import React from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import { ModalContext } from '../Context';
import { FormEditComment } from '../Utils/services';
import inputbtn from "../Assets/images/Social/inputbtn.png"


const FroumEditComment = ({id,commentid, handlegetComment,commentdata}) => {
    const [comment,setcoment] =useState(commentdata);


    const modalContext = useContext(ModalContext);
    const { closeModal} = modalContext;
    const EditComment = async (e) => {
         e.preventDefault();
        const result = await FormEditComment({
          "threadId": id,
          "commentId": commentid,
          "contents": comment

         });
          handlegetComment(id);
          closeModal();    
      }

  return (
    <div>
    <form action="" onSubmit={EditComment} >
    <div id="focusinput" className="flex justify-between border p-2 my-3 rounded-xl">
     <input type="text" 
      value={comment}
      placeholder="Edit comment"
      className="commentinput"
      onChange={(e) => setcoment(e.target.value)} required/>
     <button className="w-10 " ><img src={inputbtn} alt="" className="w-5" /></button>
    </div>
   </form> 
    </div>
  )
}

export default FroumEditComment