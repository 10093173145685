import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import releatedserch from '../../../Assets/images/releatedsearchimage.png'
import { formServiceapi } from '../../../Utils/services'
import { BASE_URL_IMG } from '../../../Utils/services'

const RealatedThread = () => {
  const [userData, setUserData] = useState([])
  const [forumDetails, setFormDetails] = useState([])
  const [content, setContent] = useState('')
  const location = useLocation()
  const id = location && location.state && location.state._id
  const { t } = useTranslation(['home'])

  const formservicedata = async () => {
    try {
      const result = await formServiceapi()
      const Fourm = result.res.data.threads.filter((item) => item._id !== id)
      //console.log((Fourm, 'realeted thread')
      setFormDetails(Fourm)
      setContent(Fourm[0].content)
      if (result.res.data.userDetails.likedThreads.length > 0) {
        setUserData(result.res.data.userDetails.likedThreads)
      }
      //   //console.log((Fourm[0].content,"working")
    } catch (error) {}
  }

  useEffect(() => {
    if (id) {
      formservicedata()
    }
  }, [id])

  return (
    <div className="rightSideBar">
      <div className="bg-white p-4 rounded-lg">
        <div className="bg-gray p-2 search-wrapper">
          <div className="inputsearch">
            <input
              type="text"
              placeholder={`${t('Search')}`}
              className="mx-3 px-3"
            />
          </div>
        </div>
        <section className="filterSection mt-4">
          <h3>{`${t('Related Threads ')}`} </h3>

          <div className="h-48 overflow-y-scroll " id="filtersrollbar">
            {forumDetails &&
              forumDetails.map((item) => {
                return (
                  <>
                    <div className="flex justify-end border-b py-2 mb-1">
                      <div className="realeted_search">
                        <img
                          src={`${BASE_URL_IMG}${item?.media[0]?.link}`}
                          alt=""
                          className=""
                        />
                      </div>
                      <div className="reatedtext">
                        <p className=" leading-5 ps-4 pe-2 filterContent">
                          {item.question.slice(0, 50)}
                          {/* Government programme for training and development. */}
                        </p>
                      </div>
                    </div>
                  </>
                )
              })}

            {/*   <div className="flex justify-end border-b py-2 mb-1">
              <div className="realeted_search">
                <img src={releatedserch} alt="" className="" />
              </div>
              <div className="reatedtext">
                <p className=" leading-5 ps-4">
                  Government programme for training and development.
                </p>
              </div>
            </div>
            <div className="flex justify-end border-b py-2 mb-1">
              <div className="realeted_search">
                <img src={releatedserch} alt="" className="" />
              </div>
              <div className="reatedtext">
                <p className=" leading-5 ps-4">
                  Government programme for training and development.
                </p>
              </div>
            </div>
            <div className="flex justify-end border-b py-2 mb-1">
              <div className="realeted_search">
                <img src={releatedserch} alt="" className="" />
              </div>
              <div className="reatedtext">
                <p className=" leading-5 ps-4">
                  Government programme for training and development.
                </p>
              </div>
            </div> */}
          </div>
        </section>
      </div>
    </div>
  )
}

export default RealatedThread
