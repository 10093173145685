import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row, Spinner } from 'reactstrap';
import Filter from '../../Common/Filter';
import SearchFilter from '../../Common/SearchFilter';
import SearchTool from '../../Common/SearchHeader/SearchTool';
import { DownloadResourceCount, getCategary, getResource, ViewResource } from '../../Utils/services';
import Legalmain from '../Legal_Provisions/Legalmain';

const Finacial = () => {
    const [getCategaryId,setCategoryId] =useState("")
    const [loader, setloader] = useState(false);
    const [filter,setFilter] =useState("")

    const [pdfData,setPdfData] =useState([])
    const [videoData,setvideoData] =useState([])
  
    const getCetegaryeData= async ()=>{
      setloader(true);
      const result = await getCategary()
      const _getcategaryFilter =await result.res.filter((item)=>item.name === "Financial literacy")
      setloader(false);
      setCategoryId(_getcategaryFilter[0]._id)
  
    }
   
  
    useEffect(()=>{
      getCetegaryeData()
    },[])
  
  
  
    const getResourceData=async(id)=>{
      const result = await getResource({
        "id":id
      });
      setPdfData(result.res.pdfdata)
      setvideoData(result.res.videodata)
     
  
    }

    const HandaleViewCount =async(item) =>{
      const result = await ViewResource({
        "id":item._id
      }
    )  
    }

    const HandleDownloadCount =async(item) =>{
      const result =await DownloadResourceCount( {   
         "id":item._id
        }
      )

    }
   
    useEffect(()=>{
      if(getCategaryId){
        getResourceData(getCategaryId)
      }
    },[getCategaryId])
  
  

  return (
  <Container>
    <Row>
       <div className="mobilesearch mt-20">
        <SearchTool setFilterForum={setFilter} />
       </div>
      <Col lg={7} className="financial_mainbar" >

        {loader ? (
            <div className="mt-20 flex justify-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Legalmain HandleDownloadCount={HandleDownloadCount} HandaleViewCount={HandaleViewCount} pdfData={pdfData} videoData={videoData} filter={filter} />
          )}
            
      </Col>
      <Col lg={5} className="financial_sidebar">
       {/* <Filter setGalleryFilter={setFilter} /> */}
       <SearchFilter setGalleryFilter={setFilter}  />
      </Col>
    </Row>
  </Container>
  )
}

export default Finacial;