import React, { useContext } from "react";
import { ModalContext } from "../Context";
import { blockUser } from "../Utils/services";
import { useTranslation } from "react-i18next";

const BlockUser = ({ id, setTogleBlock, togaleBlock }) => {
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const { t } = useTranslation(["home"]);
  const handleBlock = async () => {
    const result = await blockUser(id);
    if (result.res.success) {
      setTogleBlock(!togaleBlock);
      closeModal();
    }
  };

  return (
    <div>
      <h2 className="mb-5 block">{`${t(
        "Are you sure you want to " + (togaleBlock ? "unblock" : "block") + "?"
      )}`}</h2>
      <div className="flex justify-end">
        <button className="yesbtn" onClick={handleBlock}>
          yes
        </button>
      </div>
    </div>
  );
};

export default BlockUser;
