import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import swal from 'sweetalert';
import { ModalContext } from "../Context";

import { mobileVerifictionApi, mobileVerifictionOTPApi, otpVerificationApi } from "../Utils/services";
import OtpInput from 'react-otp-input';
import { useTranslation } from "react-i18next";

const MobileOtpVerify = () => {
  const [otp, setOtp] = useState();
  const { t } = useTranslation(['home'])
  const phone =localStorage.getItem('phone');
  const modalContext = useContext(ModalContext);
  const { closeModal,handleModalData} = modalContext;
  const country =localStorage.getItem("country")


  const handleOtpChange = (e) => {
    setOtp(e);
  };

  const handleOtpSubmit = async (e) => {
    // setLoading(true);
    e.preventDefault();
    try {
      const result = await mobileVerifictionOTPApi({
        "phone": phone,
        "otp": parseInt(otp),
        "code" :country
      });
      

      if (result.res.success) {
        // setLoading(false);
        closeModal()
        localStorage.setItem('phoneverification','true')
        swal(result.res.message, "success");
        toast.success(result.res.message);
       

      }
    } catch (error) {
    //   setLoading(false);
      toast.error(error.message);
    }
  };
   

  return (
    <div>
      <h3 className="text-center text-blue font-medium">{`${t('OTP Verification')}`}</h3>
      <p className="text-center font-medium">
         Enter the OTP recived to {phone} or via notifation
      </p>
      <form
        className="flex flex-col items-center"
        onSubmit={handleOtpSubmit}
      >
        <div className="mt-2 flex otpSection justify-center items-center">
          <OtpInput
            value={otp}
            onChange={handleOtpChange}
            numInputs={5}
            isInputNum={true}
            renderInput={(props) => <input {...props} />}
          />
        </div>
        <div className="flex flex-column">
          <button className="primary-btn mt-10 " type="submit">
            Verify OTP
          </button>
        </div>
      </form>
    </div>
  );
};

export default MobileOtpVerify;
