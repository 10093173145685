import React, { useContext, useEffect, useState } from "react";
import storyImge from "../../Assets/images/story/story.svg";

import socialmark from "../../Assets/images/Social/bookmark3.svg";
import socialmark2 from "../../Assets/images/Social/bookmark2.svg";
import diamond from "../../Assets/images/Social/dimond2.svg";
import diamond2 from "../../Assets/images/Social/diomand3.svg";
import hr from "../../Assets/images/Social/hrtwo.svg";
import comment from "../../Assets/images/Social/comments.svg";
import { BASE_URL_IMG, TRANSLATE_URL, likeStory } from "../../Utils/services";
import { toast } from "react-toastify";
import TimeAgo from "react-timeago";
import { Globalinformation } from "../../Common/MainSectionProvider";
import { MotionConfig, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import TexttoSpeach from "../TexttoSpeach";
import { useTranslation } from "react-i18next";

const StoryMain = ({ item, getStoryData }) => {
  const [qusTion, setQustion] = useState(item.title);
  const [Totallikes, settotallikes] = useState(item?.likes?.length);
  const { t } = useTranslation(["home"]);

  const [content, setContent] = useState(item.caption);
  const [TranslatedqusTion, setTranslatedQustion] = useState("");
  const [Translatedcontent, setTranslatedContent] = useState("");
  const [isTranslated, setisTranslated] = useState(false);

  const [hasClicked, setHasClicked] = React.useState(false);
  const admin_id = localStorage.getItem("adminId");
  const [adminId] = useState(admin_id);
  let navigate = useNavigate();
  const { translateLanguage } = useContext(Globalinformation);

  const times = new Date(item.date);

  const handlelike = async () => {
    setHasClicked(!hasClicked);
    try {
      const result = await likeStory({
        postId: item._id,
      });

      if (result.res.success) {
        // toast.success(result.res.message)
        if (!hasClicked) {
          settotallikes(Totallikes + 1);
        } else {
          settotallikes(Totallikes - 1);
        }
        setHasClicked(!hasClicked);
        // getStoryData()
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    const result =
      item && item?.likes?.map((item) => item.user).includes(adminId);
    if (result) {
      setHasClicked(true);
    } else {
      setHasClicked(false);
    }


    // item && item.likes.filter((id) => {
    //   if (id.user === adminId) {
    //     setHasClicked(true);
    //   }
    // })
    // const data =item.like.map((items)=>items)
  }, [item]);

  const handleStoryInnerPage = (id, item) => {
    navigate(`/stories/${id}`);
  };

  function getText(html) {
    var tmp = document.createElement("div");
    tmp.innerHTML = html;

    return tmp.textContent || tmp.innerText;
  }

  const handleChangeButton = () => {
    setisTranslated(!isTranslated);
    if (TranslatedqusTion === "" || Translatedcontent === "") {
      let toLang = translateLanguage; // translate to norwegian
      let text = `${qusTion}`;
      let textContent = `${getText(content)}`;
      let url = TRANSLATE_URL;
      url += "&q=" + encodeURI(text);
      url += "&q=" + encodeURI(textContent);
      url += `&target=${toLang}`;
      const token = localStorage.getItem("token");

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`, // notice the Bearer before your token
        },
      })
        .then((res) => res.json())
        .then((response) => {
          setTranslatedQustion(
            response.data.translations[0].translatedText.replaceAll(
              "&quot;",
              ""
            )
          );
          setTranslatedContent(
            response.data.translations[1].translatedText.replaceAll(
              "&quot;",
              ""
            )
          );
        })
        .catch((error) => {
        });
    }
  };

  return (
    <div className="my-6">
      <div className="card">
        <div className="page-story">
          <img
            className="h-80 object-cover cursor-pointer storyimage "
            src={`${BASE_URL_IMG}${item?.media[0]?.link}`}
            alt=""
            onClick={() => handleStoryInnerPage(item._id, item)}
          />
          <p>
            {isTranslated
              ? TranslatedqusTion === ""
                ? qusTion
                : TranslatedqusTion
              : qusTion}
            {/* {qusTion ? qusTion : item.title} */}
            <span className="mr-2 flex items-center h-5 ">
              {" "}
              <img src={hr} alt="" className=" cursor-pointer" />{" "}
              <span className="px-2 cursor-pointer storyFontsize">
                <TimeAgo date={times} />
              </span>{" "}
            </span>
          </p>
          <div className="storytranslatebtn-v2" >
            <button
              onClick={handleChangeButton}
            >{`${t("Translate")}`}</button>
            <TexttoSpeach
              text={isTranslated
                ? TranslatedqusTion === ""
                  ? qusTion
                  : TranslatedqusTion
                : qusTion} />
          </div>
        </div>
        <div className="card-body">
          <div className="flex mt-2 socialbtn  ">
            <p className="mr-2 flex items-center h-5 cursor-pointer ">
              {" "}
              <motion.div
                whileTap={{ scale: 4 }}
                transition={{ duration: 0.5 }}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={hasClicked ? diamond : diamond2}
                  alt=""
                  className=""
                  onClick={() => handlelike()}
                />
              </motion.div>
              <span className="px-2 storyFontsize">
                {Totallikes} {Totallikes > 1 ? "Likes" : "Like"}
              </span>
            </p>
            <p className="mr-2 flex items-center h-5 ">
              {" "}
              <img src={comment} alt="" className=" cursor-pointer" />{" "}
              <span className="px-2 cursor-pointer storyFontsize">
                {item.comments} Comments
              </span>
            </p>
            {/* <p className='mr-2 flex items-center h-5 '> <img src={hr} alt="" className=' cursor-pointer' /> <span className='px-2 cursor-pointer storyFontsize'><TimeAgo date={times} /></span> </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoryMain;
