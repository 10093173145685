import React, { useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import completed from "../../../Assets/images/elearning/completedcourse.png";
import lock from "../../../Assets/images/elearning/lock.png";
import { Download_course, SkillComplete } from "../../../Utils/services";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import FileSaver from "file-saver";
import { toast } from "react-toastify";

const Curriculum = ({
  circulum,
  subTopic,
  skillId,
  enrollCourse,
  completedcourse,
}) => {
  let navigate = useNavigate();
  const { t } = useTranslation(["home"]);
  const [downloadview, setdownloadview] = useState(false);

  useEffect(() => {
    completedcourse.map((item) => {
      if (item.id === skillId) {
        setdownloadview(true);
      }
    });
  }, []);

  const handleCertificatedownload = async () => {
    const result = await Download_course({
      courseId: skillId,
    });
    FileSaver.saveAs(result.res, `certificate.pdf`);
  };

  const handleCourse = (items) => {
    const subTopicData = subTopic.filter(
      (item) => item.completedQuestion === items._id
    );

    if (subTopicData.length > 0) {
      return (
        <>
          <img src={completed} alt="" />
        </>
      );
    } else {
      return (
        <>
          <img src={lock} alt="" />
        </>
      );
    }
  };

  const handleCompletePage = async (items, nextItem, index, circulum, i,isEnd) => {
    const nexItems = nextItem[index + 1];
    const circulamData = circulum[i + 1]?.subdata[0]?._id;
    const CirculamSubData = circulum[i + 1]?.subdata[0]?.title;
    const result = await SkillComplete({
      Sub_sessionId: items._id,
      type: items?.SessionType,
      sessionName: items?.title,
      nextQuestionIs: `${
        nexItems?._id !== undefined
          ? nexItems?._id
          : circulamData !== undefined
          ? circulamData
          : "No Data"
      }`,
      completedQuestion: items._id,
      nextSessionIs: `${
        nexItems?.title !== undefined
          ? nexItems?.title
          : CirculamSubData !== undefined
          ? CirculamSubData
          : "No Data"
      }`,

      nextSessionFirstSubtopic: `${
        circulamData !== undefined ? circulamData : "No Data"
      }`,
    });

    navigate("/skills-training/task", {
      state: {
        circulumId: skillId,
        sessionId: circulum[i]?._id,
        subId: items._id,
        isEnd:isEnd
      },
    });
  };

  const handleIncompletePage = async (
    subTopic,
    items,
    nextItem,
    index,
    circulum,
    i,
    isEnd
  ) => {
    const nexItems = nextItem[index + 1];
    const circulamData = circulum[i + 1]?.subdata[0]?._id;
    const CirculamSubData = circulum[i + 1]?.subdata[0]?.title;
    const subTopicData = subTopic.filter(
      (item) => item.nextQuestionIs === items._id
    );

    if (subTopicData.length > 0) {
      const result = await SkillComplete({
        Sub_sessionId: items._id,
        type: items?.SessionType,
        sessionName: items?.title,
        nextQuestionIs: `${
          nexItems?._id !== undefined
            ? nexItems?._id
            : circulamData !== undefined
            ? circulamData
            : "No Data"
        }`,
        completedQuestion: items._id,
        nextSessionIs: `${
          nexItems?.title !== undefined
            ? nexItems?.title
            : CirculamSubData !== undefined
            ? CirculamSubData
            : "No Data"
        }`,

        nextSessionFirstSubtopic: `${
          circulamData !== undefined ? circulamData : "No Data"
        }`,
      });
      navigate("/skills-training/task", {
        state: {
          circulumId: skillId,
          sessionId: circulum[i]?._id,
          subId: items._id,
          isEnd:isEnd
        },
      });
    } else {
      if (index == 0 && i == 0) {
        const result = await SkillComplete({
          Sub_sessionId: items._id,
          type: items?.SessionType,
          sessionName: items?.title,
          nextQuestionIs: `${
            nexItems?._id !== undefined
              ? nexItems?._id
              : circulamData !== undefined
              ? circulamData
              : "No Data"
          }`,
          completedQuestion: items._id,
          nextSessionIs: `${
            nexItems?.title !== undefined
              ? nexItems?.title
              : CirculamSubData !== undefined
              ? CirculamSubData
              : "No Data"
          }`,

          nextSessionFirstSubtopic: `${
            circulamData !== undefined ? circulamData : "No Data"
          }`,
        });

        navigate({
          pathname: `skills-training/details/task`,
          state: {
            item: "hello",
          },
        });
      } else {
        toast.info("Please complete previous sub topic");
      }
    }
  };

  const handleOpenNextPage = (items, nextItem, index, circulum, i, isEnd) => {
    const subTopicData = subTopic.filter(
      (item) => item.completedQuestion === items._id
    );
    if (subTopicData.length > 0) {
      return (
        <>
          <p
            className="ml-4 cursor-pointer"
            onClick={() =>
              handleCompletePage(items, nextItem, index, circulum, i,isEnd)
            }
          >
            {items?.title}
          </p>
        </>
      );
    } else {
      return (
        <>
          <p
            className="ml-4 cursor-pointer"
            onClick={() => {
              handleIncompletePage(
                subTopic,
                items,
                nextItem,
                index,
                circulum,
                i,
                isEnd
              );
            }}
          >
            {items?.title}
          </p>
        </>
      );
    }
  };
  return (
    <div>
      {circulum?.map((item, i) => (
        <div className="curriculum">
          <h3 className="mb-1">{item?.session}</h3>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {item?.subdata.length} Sub-topics
              </Accordion.Header>
              <Accordion.Body>
                {enrollCourse.includes(skillId) ? (
                  <div className="">
                    {item?.subdata?.map((items, index) => (
                      <div className="flex course-line">
                        <div className="flex  justify-center flex-column items-center">
                          {handleCourse(items)}
                          {item?.subdata.length - 1 > index ? (
                            <div className="verticleline"></div>
                          ) : (
                            ""
                          )}
                        </div>

                        {handleOpenNextPage(
                          items,
                          item?.subdata,
                          index,
                          circulum,
                          i,
                          circulum.length-1 === i && item?.subdata.length-1 === index
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>Enroll your course first</div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      ))}
      {downloadview ? (
        <button className="enroll-btn" onClick={handleCertificatedownload}>
          {`${t("Download Certificate")}`}
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default Curriculum;
