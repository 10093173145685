import React, { useContext, useMemo } from "react";
import { useState } from "react";
import avtor from "../../../Assets/images/avtar.png";
import SocialComment from "../../../Common/SocialComment";
import {
  addCommentForum,
  BASE_URL_IMG,
  getComments,
  TRANSLATE_URL,
} from "../../../Utils/services";
import TimeAgo from "react-timeago";
import InputText from "../../../Common/InputText";
import SocialCommentThree from "../../../Common/SocialCommentThree";
import SocialReplay from "../../../Common/SocialReplay";
import { ModalContext } from "../../../Context";
import VerifyNumber from "../../../ModalTemplate/VerifyNumber";
import platfromadmin from "../../../Assets/images/Social/plateformadmin.png";
import { useTranslation } from "react-i18next";
import { Globalinformation } from "../../../Common/MainSectionProvider";
import { useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { DeletedAccount } from "../../../Common/Deletedaccount";


const ForumInnerComment = ({
  id,
  handlegetComment,
  item,
  userData,
  forumUser,
  userReply,
}) => {
  const [addcomment, setAddcomment] = useState();
  const [qusTion, setQustion] = useState("");
  const [translatedqusTion, settranslatedqusTion] = useState("");
  const [isTranslated, setisTranslated] = useState(false);
  const [subcomment, setSubcomment] = useState("");
  const [commentName, setCommentName] = useState("");
  const [Id, setId] = useState("");

  const [viewReplies, setViewReplies] = useState(false);
  const [reply, setRepley] = useState(false);
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const { translateLanguage } = useContext(Globalinformation);

  const admin_id = localStorage.getItem("adminId");
  let navigate = useNavigate();

  const { t } = useTranslation(["home"]);

  const IsVerify = localStorage.getItem("phoneverification");
  const times = new Date(item.date);

  const handleReply = () => {
    setRepley(true);
    setViewReplies(true);
  };

  const handleViewReply = () => {
    setViewReplies(true);
    setRepley(true);
  };

  const handleVeriFication = () => {
    let VERIFYPHONE = <VerifyNumber />;
    handleModalData(VERIFYPHONE, "sm");
  };

  const handleAddcomment = async (event) => {
    event.preventDefault();
    if (addcomment && addcomment.length > 0) {
      if (IsVerify == "true") {
        const result = await addCommentForum({
          commentId: item._id,
          threadId: id,
          comment: addcomment,
        });
        handlegetComment(id);
        setAddcomment("");
      } else {
        handleVeriFication();
      }
    }
  };

  const handleChangeButton = () => {
    //console.log(('check')
    setisTranslated(!isTranslated)
    if (qusTion === "") {
      let toLang = translateLanguage; // translate to norwegian
      let text = `${item?.content}`;
      let url = TRANSLATE_URL;
      url += "&q=" + encodeURI(text);
      url += `&target=${toLang}`;
      const token = localStorage.getItem("token");

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`, // notice the Bearer before your token
        },
      })
        .then((res) => res.json())
        .then((response) => {
          setQustion(response.data.translations[0].translatedText);
        })
        .catch((error) => {
          //console.log(('There was an error with the translation request: ', error)
        });
    }
  };

  const handleSubComment = ({ commentdata, index }) => {
    //console.log((commentdata, index, 'check text')
    let toLang = translateLanguage; // translate to norwegian
    let text = `${commentdata}`;
    let url = TRANSLATE_URL;
    url += "&q=" + encodeURI(text);
    url += `&target=${toLang}`;
    const token = localStorage.getItem("token");

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`, // notice the Bearer before your token
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setSubcomment(response.data.translations[0].translatedText);
        setId(index);
      })
      .catch((error) => {
        //console.log(('There was an error with the translation request: ', error)
      });
  };

  const handleuserProfile = (item) => {
    //console.log((id, 'profile')
    //console.log((item, 'id')

    if (item.user === admin_id) {
      navigate({
        pathname: `/profile`,
      });
    } else if (!item.isAdmin)
      navigate(`/userprofile`, {
        state: {
          Id: item.user,
          deletebool: deletebool,
        },
      });
  };

  useEffect(() => {
    const userName = forumUser.filter((items) => items._id == item.user);
    setCommentName(userName[0]);
  }, [forumUser]);

  const handleCommentName = (item) => {
    const userName = userReply.filter((items) => items._id == item.user);
    const userNames = userName[0];
    return userNames?.aliasName;
  };
  const deletebool = DeletedAccount(item.user, forumUser);
  return (
    <>
      <div className="bg-grey mt-2 w-11/12  p-3 rounded-md ">
        <div class="cards flex w-100">
          <div
            className="w-14"
            onClick={() => handleuserProfile(item, deletebool)}
          >
            <img
              src={
                item.isAdmin
                  ? platfromadmin
                  : `${BASE_URL_IMG}/user/${item.user}/${item.user}.jpeg` ||
                    avtor
              }
              alt=""
              className="w-9 h-9 rounded-full cursor-pointer"
            />
          </div>
          <div className="w-3/4  pb-2">
            <h6
              className="text-blue font-bold text-sm"
              onClick={() => handleuserProfile(item)}
            >
              {item.isAdmin ? (
                "Admin"
              ) : (
                <>{deletebool ? "Deleted Account" : commentName?.aliasName}</>
              )}{" "}
              {item.isAdmin == true && (
                <span className="moderator"> Moderator</span>
              )}{" "}
            </h6>

            <span className="text-xs">
              <TimeAgo date={times} />
            </span>
            <p className="text-justify text-sm">{item.content} </p>
            <p className="text-justify text-sm text-blue">{isTranslated ? qusTion : ''} </p>

            <SocialCommentThree
              admin={item.isAdmin}
              id={id}
              forumUser={forumUser}
              commentid={item._id}
              handlegetComment={handlegetComment}
              commentdata={item.content}
              userData={userData}
              like={item.likes}
              handleReply={handleReply}
              user={item.user}
              handleChangeButton={handleChangeButton}
            />
            {item && item.replies.length > 0 && (
              <>
                {viewReplies ? null : (
                  <div
                    className="pt-2 text-sm font-normal hover:underline "
                    role="button"
                    onClick={handleViewReply}
                  >
                    - View {item && item.replies.length} replies
                  </div>
                )}
              </>
            )}

            {viewReplies && (
              <>
                {item &&
                  item.replies.map((items, i) => {
                    const deleteuser = DeletedAccount(item.user, forumUser);
                    return (
                      <div className="mt-2 border-t pt-2">
                        <div class="cards flex w-100">
                          <div
                            className="w-14 mr-1 cursor-pointer"
                            onClick={() => handleuserProfile(items, deleteuser)}
                          >
                            <img
                              src={
                                items.isAdmin
                                  ? platfromadmin
                                  : `${BASE_URL_IMG}/user/${items.user}/${items.user}.jpeg` ||
                                    avtor
                              }
                              alt=""
                              className="w-9 h-9 rounded-full "
                            />
                          </div>
                          <div className="w-96  pb-2">
                            <h6
                              className="text-blue font-bold text-sm"
                              onClick={() =>
                                handleuserProfile(items, deleteuser)
                              }
                            >
                              {items.isAdmin
                                ? "Admin"
                                : deleteuser
                                ? "Deleted Account"
                                : handleCommentName(items)}{" "}
                              {items.isAdmin == true && (
                                <span className="moderator"> Moderator</span>
                              )}
                            </h6>
                            <span className="text-xs">
                              {" "}
                              {moment(items.date).fromNow()}
                            </span>

                            <p className="text-justify text-sm">
                              {items.content}
                            </p>
                            {Id == i && (
                              <p className="text-justify text-sm text-blue">
                                {subcomment}
                              </p>
                            )}

                            <SocialReplay
                              admin={items}
                              user={items.user}
                              index={i}
                              Likes={items.likes}
                              ReplyId={items._id}
                              id={id}
                              commentid={item._id}
                              handlegetComment={handlegetComment}
                              commentdata={items.content}
                              handleSubComment={handleSubComment}
                            />
                            {/* <SocialComment  /> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
            {reply && (
              <InputText
                handleAddcomment={handleAddcomment}
                setAddcomment={setAddcomment}
                addcomment={addcomment}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForumInnerComment;
