import React, { useEffect, useRef } from "react";
import ChatInput from "./ChatInput";
import { v4 as uuidv4 } from "uuid";
import { BASE_URL_IMG, getMsgApi, sendMsgApi } from "../../../Utils/services";
import { useState } from "react";
import moment from "moment/moment";
import platfromadmin from "../../../Assets/images/Social/plateformadmin.png";

import vmsg from "vmsg";

const recorder = new vmsg.Recorder({
  wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm",
});

const ChatSection = ({ socket, adminId }) => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isRecording, setisRecording] = useState(false);
  const [recordings, setrecordings] = useState();
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [loader, setLoader] = useState(false);
  const AdminChatId = localStorage.getItem("adminChatId");

  const scrollRef = useRef();

  const getMessage = async () => {
    const result = await getMsgApi();
    setMessages(result.res.projectedMessages);
  };

  const handleSendMsg = async (msg) => {
    if (msg !== "" || recordings !== undefined) {
      try {
        if (msg) {
          socket.emit("message", {
            message: msg,
            senderId: adminId,
            targetId: AdminChatId,
            isAudio: false,
            roomId: adminId,
            path: " ",
          });
        }

        const formDatamsg = new FormData();
        formDatamsg.append("senderId", adminId);
        formDatamsg.append("targetId", AdminChatId);
        formDatamsg.append("message", msg ? msg : "");
        formDatamsg.append("roomId", adminId);
        formDatamsg.append("media", null);

        const formDataaudio = new FormData();
        formDataaudio.append("senderId", adminId);
        formDataaudio.append("targetId", AdminChatId);
        formDataaudio.append("message", "");
        formDataaudio.append("roomId", adminId);
        formDataaudio.append("media", recordings && recordings);

        const result = await sendMsgApi(msg ? formDatamsg : formDataaudio);
        if (result.res.path) {
          socket.emit("message", {
            message: msg ? msg : "",
            senderId: adminId,
            targetId: AdminChatId,
            isAudio: true,
            path: result.res.path,
          });
          setrecordings();
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (AdminChatId) {
      getMessage();
    }
  }, [AdminChatId]);

  useEffect(() => {
    if (socket) {
      socket.on("message", (msg) => {
        setArrivalMessage({
          message: msg.message,
          fromSelf: msg.senderId,
          voice: msg.path,
          time: new Date(),
        });
      });
    }
  }, [socket]);
  useEffect(() => {
    if (socket) {
      socket.on("Admin", (msg) => {
        setLoader(true);
        localStorage.setItem("adminChatId", msg.username);
        setLoader(false);
      });
    }
  }, [socket]);

  useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSendMsgUi = (message) => {
    if (message.voice === null || message.message) {
      return (
        <>
          <div ref={scrollRef} key={uuidv4()}>
            <div
              className={`message ${
                message.fromSelf === adminId ? "sended" : "recieved"
              }`}
            >
              {message.fromSelf === adminId ? (
                <>
                  <div className="content mr-2 ">
                    <p>{message.message}</p>
                  </div>
                  <img
                    src={`${BASE_URL_IMG}/user/${adminId}/${adminId}.jpeg`}
                    alt=""
                    className="h-8"
                  />
                </>
              ) : (
                <>
                  <img src={platfromadmin} alt="" className="h-8" />
                  <div className="content ml-2">
                    <p>{message.message}</p>
                  </div>
                </>
              )}
            </div>
            {message.fromSelf === adminId ? (
              <div className="flex justify-end w-100 text-xs">
                {moment(message.time).format("LT")}
              </div>
            ) : (
              <div className="flex justify-start w-100 text-xs">
                Moderator {moment(message.time).format("LT")}
              </div>
            )}
          </div>
        </>
      );
    } else if (message.voice !== null || message.voice !== " ") {
      return (
        <>
          <div ref={scrollRef} key={uuidv4()}>
            <div
              className={`message ${
                message.fromSelf === adminId ? "sended" : "recieved"
              }`}
            >
              {message.fromSelf === adminId ? (
                <>
                  <div className="content mr-2 ">
                    <audio src={`${BASE_URL_IMG}${message?.voice}`} controls />
                  </div>
                  <img
                    src={`${BASE_URL_IMG}/user/${adminId}/${adminId}.jpeg`}
                    alt=""
                    className="h-8"
                  />
                </>
              ) : (
                <>
                  <img src={platfromadmin} alt="" className="h-8" />

                  <div className="content ml-2">
                    <audio src={`${BASE_URL_IMG}${message?.voice}`} controls />
                  </div>
                </>
              )}
            </div>
            {message.fromSelf === adminId ? (
              <div className="flex justify-end w-100 text-xs">
                {moment(message.time).format("LT")}
              </div>
            ) : (
              <div className="flex justify-start w-100 text-xs">
                Moderator {moment(message.time).format("LT")}
              </div>
            )}
          </div>
        </>
      );
    }
  };

  return (
    <div className="mt-8">
      <div>
        <h1>Get Help</h1>
      </div>

      <div className="bg-white mt-8">
        <div className="chat-messages">
          {loader ? (
            <>waiting for admin</>
          ) : (
            <>
              {messages &&
                messages?.map((message) => {
                  return <>{handleSendMsgUi(message)}</>;
                })}
            </>
          )}
        </div>
        <ChatInput
          handleSendMsg={handleSendMsg}
          getmessageDataApi={getMessage}
          isLoading={isLoading}
          isRecording={isRecording}
          recordings={recordings}
          setrecordings={setrecordings}
        />
      </div>
    </div>
  );
};

export default ChatSection;
