import React from 'react'
import { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import Filter from '../../Common/Filter'
import Forum from '../Forum/Forum'
import ForumRightSidebar from '../Forum/ForumRightSidebar'
import GallerySocial from './GallerySocial'

const Gallery = () => {
  const [galaryfilter, setGalleryFilter] = useState('')
  const [tafFilter, setFilterTag] = useState('')
  const [selectedcourse, setselectedCourse] = useState([])
  return (
    <>
      <Container>
        <Row>
          <Col md={7}>
            <GallerySocial
              galaryfilter={galaryfilter}
              tafFilter={tafFilter}
              setGalleryFilter={setGalleryFilter}
              setFilterTag={setFilterTag}
              selectedcourse={selectedcourse}
              setselectedCourse={setselectedCourse}
            />
          </Col>

          <Col md={5}>
            <Filter
              setGalleryFilter={setGalleryFilter}
              galaryfilter={galaryfilter}
              setFilterTag={setFilterTag}
              selectedcourse={selectedcourse}
              setselectedCourse={setselectedCourse}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Gallery
