import React, { useMemo } from "react";
import { useState } from "react";
import avtor from "../../../Assets/images/avtar.png";
import SocialComment from "../../../Common/SocialComment";
import { addCommentForum, AddCommentStory, BASE_URL_IMG, getComments, TRANSLATE_URL } from "../../../Utils/services";
import TimeAgo from "react-timeago";
import InputText from "../../../Common/InputText";
import SocialCommentThree from "../../../Common/SocialCommentThree";
import SocialReplay from "../../../Common/SocialReplay";
import SocialCommentStory from "../../../Common/SocialCommentStory";
import SocialStoryReplay from "../../../Common/SocialStoryReplay";
import { useContext } from "react";
import { ModalContext } from "../../../Context";
import VerifyNumber from "../../../ModalTemplate/VerifyNumber";
import platfromadmin from "../../../Assets/images/Social/plateformadmin.png"
import { Globalinformation } from "../../../Common/MainSectionProvider";
import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DeletedAccount } from "../../../Common/Deletedaccount";



const StoryInnerComment = ({ id, handlegetComment, item,userData,storyUsercomment,userReply ,setCountComment}) => {
  const [addcomment, setAddcomment] = useState();
  const [viewReplies, setViewReplies] = useState(false);
  const [reply, setRepley] = useState(false);
  const [content,setContent] =useState("")
  const [replytrans,setReplyTrans] =useState("");
  const  [userName,setUserName] =useState("")
  const [ID,setID] =useState("")
  const {translateLanguage} =useContext(Globalinformation)
  let navigate = useNavigate();


  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  
  const IsVerify = localStorage.getItem('phoneverification');
  
  const times = new Date(item.date);

  const handleReply = () => {
    setRepley(true);
    setViewReplies(true);
  };

  const handleViewReply = () => {
    setViewReplies(true);
    setRepley(true);
  };

  const handleVeriFication = () => {
    let VERIFYPHONE = <VerifyNumber />
    handleModalData(VERIFYPHONE,"sm")
   } 

  const handleAddcomment = async (event) => {
    event.preventDefault();
    if(IsVerify == 'true'){
      const result = await AddCommentStory({
        "commentId": item._id,
        "postId": id,
        "comment": addcomment,
      });
      handlegetComment(id)  
      setAddcomment("");
      setCountComment(prev=>prev+1)

    }
    else{
      handleVeriFication()
    }
 
  };


  const handleTranslate =() =>{

    let toLang = translateLanguage; // translate to norwegian
    let text =`${item.content}`; 
    let url = TRANSLATE_URL;
    url += '&q=' + encodeURI(text);
    url += `&target=${toLang}`;
    const token = localStorage.getItem('token')
    
    fetch(url, { 
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'Authorization': `Bearer ${token}`, // notice the Bearer before your token
      }
    })
    .then(res => res.json())
    .then((response) => {
      setContent(response.data.translations[0].translatedText)
    })
    .catch(error => {
    });


  }

  const handlereplytrans = ({commentdata,index}) =>{
    let toLang = translateLanguage; // translate to norwegian
    let text =`${commentdata}`; 
    let url = TRANSLATE_URL;
    url += '&q=' + encodeURI(text);
    url += `&target=${toLang}`;
    const token = localStorage.getItem('token')
    
    fetch(url, { 
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'Authorization': `Bearer ${token}`, // notice the Bearer before your token
      }
    })
    .then(res => res.json())
    .then((response) => {
      setReplyTrans(response.data.translations[0].translatedText)
      setID(index)
   

    })
    .catch(error => {
    });

  }

  useEffect(()=>{
  const user_Name =storyUsercomment.filter((items)=>items._id ===item.user)
  setUserName(user_Name[0])

  },[storyUsercomment])



  const handleUserName =(item) =>{
    const user_Name =userReply.filter((items)=>items._id ===item.user)
    const userName =user_Name[0]
    return  userName?.aliasName;

  }

  const handleuserProfile = (item,deletebool) =>{

    if(!item.isAdmin)
    navigate( `/userprofile`,{
      state: {
        Id:item.user,
        deletebool:deletebool
      }
    })
  
   }
   const deletebool= DeletedAccount(item.user,storyUsercomment);

  return (
    <>
      <div className="bg-grey mt-2 w-11/12  p-3 rounded-md ">
        <div class="cards flex w-100">
          <div className="w-14 cursor-pointer" onClick={()=>handleuserProfile(item,deletebool)}>
        
            <img src={item.isAdmin ? platfromadmin  :`${BASE_URL_IMG}/user/${item.user}/${item.user}.jpeg`||avtor} alt="" className="w-9 h-9 rounded-full" />
            </div>
            <div className="w-3/4  pb-2">
            <h6 className="text-blue font-bold text-sm cursor-pointer" onClick={()=>handleuserProfile(item,deletebool)}>{item.isAdmin ? "Admin" :deletebool?"Deleted Account": userName?.aliasName} {item.isAdmin ==true && <span className="moderator"> Moderator</span> }  </h6>
            <span className="text-xs"><TimeAgo date={times} /></span>
            <p className="text-justify text-sm">{item.content} </p>
            <p className="text-justify text-sm text-blue">{content} </p>
             <SocialCommentStory setCountComment={setCountComment} handleTranslate={handleTranslate} admin={item.isAdmin}  id={id} commentid={item._id} handlegetComment={handlegetComment} commentdata={item.content} userData={userData} like={item.likes} handleReply={handleReply} user={item.user} />
    
             {item && item.replies.length > 0 && (
              <>
                 {viewReplies ? null : (
                  <div
                    className="pt-2 text-sm font-normal hover:underline "
                    role="button"
                    onClick={handleViewReply}
                  >
                    - View {item && item.replies.length} replies
                  </div>
                )}
              </>
            )}

            {viewReplies && (
              <>
                {item &&
                  item.replies.map((items, i) => {
                    const deleteuser=DeletedAccount(item.user,storyUsercomment)
                    
                    return(
                    <div className="mt-2 border-t pt-2">
                      <div class="cards flex w-100">
                        <div className="w-14 mr-1 cursor-pointer" onClick={()=>handleuserProfile(items,deleteuser)}>
                          {/* <img src={avtor} alt="" className="w-9 h-9" /> */}
                          <img src={items.isAdmin ? platfromadmin  :`${BASE_URL_IMG}/user/${item.user}/${item.user}.jpeg`||avtor} alt="" className="w-9 h-9 rounded-full" />
                          
                        </div>
                        <div className="w-96  pb-2">
                          <h6 className="text-blue font-bold text-sm cursor-pointer" onClick={()=>handleuserProfile(items,deleteuser)}>
                          {items.isAdmin  ? "admin" : deleteuser? "Deleted Account": handleUserName(items)} {items.isAdmin ==true && <span className="moderator"> Moderator</span> }
                          </h6>
                          <span className="text-xs">{moment(items.date).fromNow()}</span>
                          <p className="text-justify text-sm">
                            {items.content}
                          </p>
                          {
                            ID ==i &&  <p className="text-justify text-sm text-blue">
                                      {replytrans}
                                      </p>
                          }
                         
                           <SocialStoryReplay setCountComment={setCountComment} admin={items.isAdmin} index={i} handlereplytrans={handlereplytrans} user={items.user} Likes={items.likes} ReplyId={items._id}  id={id} commentid={item._id} handlegetComment={handlegetComment} commentdata={items.content}/>
                           
                        </div>
                      </div>
                    </div>
                  )})}
              </>
            )}
            
             {reply && (
             <InputText
              handleAddcomment={handleAddcomment}
              setAddcomment={setAddcomment}
              addcomment={addcomment}
             />
          )}
          </div>
        </div>
      </div>
    </>
  );
};
export default StoryInnerComment