import React, { useContext, useEffect, useState } from "react";
import AvtarImg from "../Assets/images/avtar.png";
import { BASE_URL_IMG, getTagFormService } from "../Utils/services";
import { toast } from "react-toastify";
import { Globalinformation } from "./MainSectionProvider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


const Filter = ({setGalleryFilter,setFilterTag,selectedcourse,setselectedCourse}) => {
  const [tag, setTag] = useState([]);
  const {fileteValue,setFilterValue,tagsearch,settagsearch} =useContext(Globalinformation);
  const [active,setActive] =useState(null) 
  const userName = localStorage.getItem("adminName");
  const userNameID = localStorage.getItem("adminId");
  const { t } = useTranslation(["home"]);

  let navigate = useNavigate();



  const getTag = async () => {
    try {
      const result = await getTagFormService();

      setTag(result.res.data);
      //console.log((result.res.data, "response");
    } catch (error) {
      // toast.error("list not found");
    }
  };

  const handleActive = (i) =>{
    setActive(i)
    //console.log((i,"id check")
  }


  //update //
  useEffect(() => {
    getTag();
  }, []);

  const handlecheckbox = (e) => {
    var temp = selectedcourse;
    if (e.target.checked === false) {
        temp = temp.filter((a) => {
            return a != e.target.value;
        });
    }
    e.target.checked
        ? setselectedCourse([...selectedcourse, e.target.value])
        : setselectedCourse([...temp]);


};

  return (
    <>
      <div></div>
      <div className="rightSideBar">
        <div className="flex items-center mb-4 mt-1" onClick={()=>navigate('/profile')}>
          <div className="w-10 mr-2 cursor-pointer">
            <img src={`${BASE_URL_IMG}/user/${userNameID}/${userNameID}.jpeg?${Date.now()}`} className=""   alt="" />
          </div>
          <div>
            <h3 className="aliasname cursor-pointer" onClick={()=>navigate('/profile')}> { userName ||"Alias Name"}</h3>
            <p className="underline text-blue cursor-pointer" onClick={()=>navigate('/profile')}  >View Profile</p>
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg">
          <div className="bg-gray p-2 search-wrapper">
            <div className="inputsearch">
              <input type="text" placeholder={`${t("Search")}`} className="mx-3 px-3" onChange={(e)=>setGalleryFilter(e.target.value)} />
            </div>
          </div>
          <section className="filterSection mt-4">
            <h3>{`${t('Filter By')}`}</h3>
            <div className="h-40 overflow-y-scroll " id="filtersrollbar">
              {tag &&
                tag.map((item, i) => {
                  return (
                    <React.Fragment key={i}>

                       <ul class="ux-btn-group">

                          <>
                          <li key={i} className="py-2 " >
                                <input type="checkbox" value={item.name} id={`${item.name}`} class="ux-btn-group-item cursor-pointer" name="option" onClick={handlecheckbox} />
                                <label for={`${item.name}`} className="ml-3 cursor-pointer"  >{item.name}</label>
                          </li>
                          </>

                      </ul>
                      {/* <div onClick={()=>handleActive(i)}>                    
                         <p className= {`${active ===i ? "activefilter" :null} cursor-pointer`} onClick={()=>setFilterTag(item.name)} key={i}>{item.name}</p>
                      </div> */}
                    </React.Fragment>
                  );
                })}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Filter;
