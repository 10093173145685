import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext } from '../Context';
import AuthContext from '../Utils/auth-context';
import { LogoutProfile } from '../Utils/services';

const LogoutModal = () => {
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const phoneNumber = localStorage.getItem('phone');
  const fcmToken = localStorage.getItem('fcmToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const country = localStorage.getItem('country');
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation(['home']);

  const { logout } = authCtx || {}; // Ensure authCtx is defined before accessing logout function

  const logoutHandler = async () => {
    try {
      await LogoutProfile({
        refreshToken: refreshToken,
        fcmToken: fcmToken,
        Phone: phoneNumber,
        name: country,
      });
      logout(); // Call the logout function
    } catch (error) {
    }
  };

  return (
    <div>
      <h1 className="text-center text-2xl">{`${t('Confirm Log Out')}`}</h1>
      <p className="text-center mt-2">{`${t('Are you sure you want to sign out')}`}</p>
      <div className="flex justify-around mt-4">
        <button onClick={closeModal}>{`${t('Cancel')}`}</button>
        <button onClick={logoutHandler}>{`${t('Log Out')}`}</button>
      </div>
    </div>
  );
};

export default LogoutModal;
