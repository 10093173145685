import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import ChatSection from "./ChatSection";
import { io } from "socket.io-client";
import socketIo from "socket.io-client";
import { ENDPOINT } from "../../../Utils/services";

const Chat = () => {
  const [socketfunction, setsocketfunction] = useState(null);
  const admin_id = localStorage.getItem("adminId");
  const [currentChat, setCurrentChat] = useState(undefined);
  const [adminId] = useState(admin_id);

  const socket = useRef();

  useEffect(() => {
    if (adminId) {
      // socket.current = io(host)
      const JOIN = "join";
      const socket = socketIo(ENDPOINT, { transports: ["websocket"] });
      setsocketfunction(socket);
      socket.on("connect", () => {});
      socket.emit("joinUser", { username: adminId, roomname: admin_id });
      socket.emit("adminUserJoin", {
        username: adminId,
        roomname: admin_id,
      });
    }
  }, [adminId]);

  return (
    <div className="profile As_gh_profile">
      <Container>
        <Row className="As_gh_chat">
          <Col md={9}>
            <ChatSection
              socket={socketfunction}
              currentChat={currentChat}
              adminId={adminId}
            />
          </Col>
          {/* <Col></Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default Chat;
