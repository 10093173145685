import React from "react";
import { motion } from "framer-motion";
import TimeAgo from "react-timeago";
import diamond from "../Assets/images/Social/dimond2.svg";
import diamond2 from "../Assets/images/Social/diomand3.svg";
import hr from "../Assets/images/Social/hrtwo.svg";
import comment from "../Assets/images/Social/comments.svg";
import Shareicon from "../Components/ShareFunction";

const Social = ({
  handlelike,
  times,
  hasClicked,
  item,
  handlecomment,
  showcomment,
}) => {
  return (
    <>
      <div className="flex mt-3 socialbtn ">
        <p
          className="mr-2 flex items-center h-5 cursor-pointer likegallerytext"
          onClick={() => handlelike()}
        >
          {" "}
          <motion.div
            whileTap={{ scale: 4 }}
            transition={{ duration: 0.5 }}
            style={{ cursor: "pointer" }}
          >
            <img
              src={hasClicked ? diamond : diamond2}
              alt=""
              className="h-4 gallerylikeres"
            />
          </motion.div>
          <span className="px-2">
            {item.likes.length} {item.likes.length > 1 ? "Likes" : "Like"}
          </span>
        </p>
        <p
          className="mr-2 flex items-center h-5 commentgallerytextres"
          onClick={() => handlecomment(!showcomment)}
        >
          {" "}
          <img
            src={comment}
            alt=""
            className="h-4 cursor-pointer gallerycommentres"
          />{" "}
          <span className="px-2 cursor-pointer">{item.comments} Comments</span>
        </p>

        <Shareicon link={window.location.origin+`/gallery/${item._id}`} />





        <p className="mr-2 flex items-center h-5 timegallerytext ">
          {" "}
          <img
            src={hr}
            alt=""
            className="h-4 cursor-pointer gallerytimeres"
          />{" "}
          <span className="px-2 cursor-pointer">
            <TimeAgo date={times} />{" "}
          </span>{" "}
        </p>
      </div>
    </>
  );
};

export default Social;
