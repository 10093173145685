import React, { useState, useEffect } from "react";

import logo from "../../../Assets/images/glplogo.png";
import {
  ResetpasswordApi,
  forgotPasswordApi,
  loginService,
} from "../../../Utils/services";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import authBg from "../../../Assets/images/bg_banner.png";
import CornerImg from "../../../Assets/images/signincorner.png";
import Logo from "../../../Assets/images/glp-logo-white.png";
import mibilelogo from "../../../Assets/images/glplogotwo.png";

import { useLocation } from "react-router-dom";
import CountryModal from "../../../ModalTemplate/CountryModal";
import india from "../../../Assets/images/country/india.png";
import { useNavigate } from "react-router/dist";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    new: "",
    old: "",
    confirm: "",
  });

  const [passwordError, setPasswordError] = useState("");
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation(["home"]);

  const handleForgetPassword = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      // Perform password validation here
      if (userData.new !== userData.confirm) {
        setLoading(false);
        setPasswordError("Password Does not match");
        return;
      }
      if (userData.new === userData.old) {
        setLoading(false);
        toast.error("Old password and New password can't be same");
        setPasswordError("");
        return;
      }
      const isValid = validatePassword(userData.new);
      if (!isValid) {
        setLoading(false);
        return;
      }

      try {
        const result = await ResetpasswordApi({
          oldpassword: userData.old,
          password: userData.new,
          confirmPassword: userData.confirm,
        });
        if (result.res.success) {
          setLoading(false);
          toast.success(result.res.message);
          navigate("/profile");
        }
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (userData?.new === "") {
      setIsButtonActive(false);
    } else if (userData?.new !== "") {
      setIsButtonActive(true);
    }
  }, [userData]);

  function validatePassword(password) {
    const lengthRegex = /.{8,}/;
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const specialCharacterRegex = /[\$@#&]/;

    setPasswordError(""); // Reset password error message

    if (!lengthRegex.test(password)) {
      setPasswordError("Password must be at least 8 characters long.");
      return false;
    } else if (!lowercaseRegex.test(password)) {
      setPasswordError("Password must contain at least one lowercase letter.");
      return false;
    } else if (!uppercaseRegex.test(password)) {
      setPasswordError("Password must contain at least one uppercase letter.");
      return false;
    } else if (!numberRegex.test(password)) {
      setPasswordError("Password must contain at least one number.");
      return false;
    } else if (!specialCharacterRegex.test(password)) {
      setPasswordError(
        "Password must contain at least one special character ($ @ # &)."
      );
      return false;
    }

    // All conditions met
    // setPasswordError("DOne")
    return true;
  }

  return (
    <>
      <div className=" h-100 flex justify-center items-center  mobileLoginscreen">
        <div className="w-2/3 mx-auto auth-container">
          <img
            src={CornerImg}
            alt="corner img"
            className="h-24 w-24 absolute right-0 top-0 cornerImg"
          />
          <div className="w-1/2 mx-auto mobileformscreen">
            <div className="flex justify-center mobilelogo">
              <img src={mibilelogo} alt="" />
            </div>
            <h2 className="primary-color font-sans text-2xl font-bold mb-10 mobilescreentitle">
              {`${t("Reset Password")} ?`}
            </h2>
            <div className="auth-form authFormInputStyle ">
              <form onSubmit={handleForgetPassword}>
                {/* <div className="flex ">
                  <div className="flex items-center countrycode cursor-pointer">
                    <div className="country-img">
                      <img src={countryCode.Icon} alt="" />
                    </div>

                    <p>{countryCode.cntyCode}</p>
                  </div>

                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    name="phone"
                    value={userData.phone}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div> */}
                <input
                  type="password"
                  placeholder="Old Password *"
                  name="old"
                  value={userData.old}
                  onChange={(e) => handleChange(e)}
                  required
                />
                <input
                  className={`${passwordError ? "mb-0" : ""}`}
                  type="password"
                  placeholder="New Password *"
                  name="new"
                  value={userData.new}
                  onChange={(e) => handleChange(e)}
                  required
                />
                {passwordError && (
                  <p className="mb-2 text-red-600">{passwordError}</p>
                )}
                <input
                  type="password"
                  placeholder="Confirm Password *"
                  name="confirm"
                  value={userData.confirm}
                  onChange={(e) => handleChange(e)}
                  required
                />
                <button
                  className={`${
                    isButtonActive ? "ActiveGetOtpBtn" : "inactiveGetOtpBtn"
                  }`}
                >{`${t("Reset Password")}`}</button>
              </form>
            </div>
            <div>
              <h1>Password Requirements *</h1>
              <ul className="pl-12" style={{ listStyleType: "disc" }}>
                <li>At least eight characters.</li>
                <li>At least one lower-case letter.</li>
                <li>At least one upper-case letter.</li>
                <li>At least one number.</li>
                <li>At least one special character (\$ @ # &).</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
