import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Filter from "../../../Common/Filter";
import SearchFilter from "../../../Common/SearchFilter";
import NewsMain from "../../Newsfeed/NewsMain";
import Listdirectory from "./Listdirectory";

const Directory = () => {
  const [filter,setFilter] =useState("") ;
  return (
    <div>
      <Container>
        <Row>
          <Col lg={7}>
            <Listdirectory filter={filter} />
          </Col>
          <Col lg={5}>
            
            <SearchFilter setGalleryFilter={setFilter} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Directory;
