import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import AvtarImg from "../../Assets/images/avtar.png";
import { Globalinformation } from "../../Common/MainSectionProvider";
import {
  BASE_URL_IMG,
  getSaveFormService,
  getTagFormService,
} from "../../Utils/services";
import filter from "../../Assets/images/MobileIcon/filter.svg";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ForumData = [
  {
    title: "Government programme for training and development.",
    ForumImg: AvtarImg,
  },
  {
    title: "Government programme for training and development.",
    ForumImg: AvtarImg,
  },
  {
    title: "Government programme for training and development.",
    ForumImg: AvtarImg,
  },

  {
    title: "Government programme for training and development.",
    ForumImg: AvtarImg,
  },
];

const ForumRightSidebar = ({
  setFilterForum,
  setTagFilter,
  filterFourm,
  selectedcourse,
  setselectedCourse,
}) => {
  const { saveForm, setFilterValue } = useContext(Globalinformation);
  const [active, setActive] = useState(null);
  const [togale, settogle] = useState(false);

  const userNameID = localStorage.getItem("adminId");
  const userName = localStorage.getItem("adminName");
  const { t } = useTranslation(["home"]);
  let navigate = useNavigate();
  const [tag, setTag] = useState([]);

  const getTag = async () => {
    try {
      const result = await getTagFormService();

      setTag(result.res.data);
      //console.log((result.res.data, "response");
    } catch (error) {
      // toast.error("list not found");
    }
  };

  //update //
  useEffect(() => {
    getTag();
  }, []);

  const handleActive = (i) => {
    setActive(i);
    //console.log((i,"id check")
  };

  //console.log((saveForm,"save data")
  const handleRedirectpage = (data) => {
    navigate(`/forum/${data._id}`, {
      state: {
        img: data.media[0].link,
        qustion: data.qustion,
        commentcount: data.commentcount,
        tag: data.tag,
        item: data,
        likes: data.likes,
        contant: data.contant,
        _id: data._id,
      },
    });
  };

  const handlecheckbox = (e) => {
    var temp = selectedcourse;
    if (e.target.checked === false) {
      temp = temp.filter((a) => {
        return a != e.target.value;
      });
    }
    e.target.checked
      ? setselectedCourse([...selectedcourse, e.target.value])
      : setselectedCourse([...temp]);
  };

  //console.log((selectedcourse,"data")

  return (
    <>
      <div className="rightSideBar">
        <div className="flex items-center mb-4 mt-1">
          <div className="w-10 mr-2 cursor-pointer">
            <img
              src={`${BASE_URL_IMG}/user/${userNameID}/${userNameID}.jpeg?${Date.now()}`}
              className=""
              alt=""
            />
          </div>
          <div>
            <h3
              className="aliasname cursor-pointer"
              onClick={() => navigate("/profile")}
            >
              {" "}
              {userName || "Alias Name"}
            </h3>
            <p
              className="underline text-blue cursor-pointer"
              onClick={() => navigate("/profile")}
            >
              View Profile
            </p>
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg">
          <div className="bg-gray p-2 search-wrapper">
            <div className="inputsearch">
              <input
                type="text"
                placeholder={`${t("Search")}`}
                className="mx-3 px-3"
                onChange={(e) => setFilterForum(e.target.value)}
              />
            </div>
          </div>
          <section className="filterSection mt-4">
            <h3
              className="flex items-center cursor-pointer"
              onClick={() => settogle(!togale)}
            >
              {`${t("Filter By")}`}{" "}
              <span className="ml-2 ">
                <img src={filter} alt="" className="filterlogo" />
              </span>
            </h3>

            {togale && (
              <div className="h-48 overflow-y-scroll " id="filtersrollbar">
                {tag &&
                  tag.map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        <ul class="ux-btn-group">
                          <>
                            <li key={i} className="py-2 ">
                              <input
                                type="checkbox"
                                value={item.name}
                                id={`${item.name}`}
                                class="ux-btn-group-item cursor-pointer"
                                name="option"
                                onClick={handlecheckbox}
                              />
                              <label
                                for={`${item.name}`}
                                className="ml-3 cursor-pointer"
                              >
                                {item.name}
                              </label>
                            </li>
                          </>
                        </ul>

                        {/* <div onClick={()=>handleActive(i)} > <p key={i} onClick={()=>setTagFilter(item.name)} className= {`${active ===i ? "activefilter" :null} cursor-pointer`} >{item.name}</p>  </div> */}
                      </React.Fragment>
                    );
                  })}
              </div>
            )}
          </section>
        </div>

        <section className="bg-white rounded-lg p-4 savedForum mt-4">
          <h3>{`${t("Saved Forum")}`}</h3>

          <div className="h-40 overflow-y-scroll" id="filtersrollbar">
            {saveForm &&
              saveForm.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: 0.9 }}
                      transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20,
                      }}
                    >
                      <div
                        className="flex items-center my-4 cursor-pointer"
                        onClick={() => handleRedirectpage(data)}
                      >
                        <img
                          src={`${BASE_URL_IMG}${data.media[0].link}`}
                          alt="forum img"
                          className="w-12 h-12"
                        />
                        <h4 className="ml-2">{data.question}</h4>
                      </div>
                    </motion.div>
                  </React.Fragment>
                );
              })}
          </div>
        </section>
      </div>
    </>
  );
};

export default ForumRightSidebar;
