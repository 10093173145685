import { BASE_URL_IMG } from '../Utils/services'
import React from 'react'
import ReactPlayer from 'react-player'

const Player = ({ link }) => {

  //   const { url } = useContext(BaseUrl);
  //   //console.log((data);

  return (
    <div className="m-4">
      <ReactPlayer
        url={`${BASE_URL_IMG}${link}`}
        controls={true}
        width="100%"
        height="auto"
        loop={false}
      />
    </div>
  )
}

export default Player
