import moment from "moment";
import React from "react";
import { BASE_URL_IMG, GetMeetingCode } from "../Utils/services";
import time from "../Assets/images/EventIcon/time.png";
import orgainser from "../Assets/images/EventIcon/org.png";
import sector from "../Assets/images/EventIcon/sector.png";
import url from "../Assets/images/event/link.svg";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const EventModal = ({ showEvent }) => {
  const { t } = useTranslation(["home"]);
  const formatDate = (date) => {
    const _date = new Date(date);
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();
    const newdate = _date.getDate();
    return `${newdate}/${month}/${year}`;
  };
  const handleMeeting = async (currentURL, data) => {

    const endTimeStr = data.end;
    const endTime = new Date(endTimeStr);
    const currentTime = new Date();
    if (false) {
      toast.info("The Link has been expired");
      return null;
    }
    const parsedUrl = new URL(currentURL);
    const domain = parsedUrl.origin;

    if (domain === "https://admin.glp-connect.org" || domain === "https://glpadmin.netlify.app" ) {
      const RoomCode = await GetMeetingCode({
        roomId: data.roomDetails[0].id.replace(/\\/g, "").replace(/"/g, ""),
      });
      const jsonData = RoomCode.res.split("Room Detail: ")[1];

      // Parse JSON
      const parsedData = JSON.parse(jsonData);

      // Access the data
      const roomDetails = parsedData.data;
      console.log(roomDetails, "data");

      function openNewTab(slugCode) {
        const url = `${window.location.origin}/glpmeeting/${slugCode}`; // Replace with your actual URL
        window.open(url, "_blank");
      }

      // Check the role and open a new tab if it's "new-role-6288"
      roomDetails.forEach((room) => {
        if (room.role === "guest") {
          openNewTab(room.code);
        }
      });

      // const urlParts = currentURL.split("?");
      // const key = urlParts[1].split("/")[0].split("=")[1];
      // const channelid = urlParts[2].split("=")[1];
      // navigate("/glpmeeting", {
      //   state: {
      //     _token: localStorage.getItem("token"),
      //     token: channelid,
      //     channel_id: key,
      //     userid: localStorage.getItem("adminId"),
      //     roomid: data.eventId,
      //     name: localStorage.getItem("adminName"),
      //   },
      // });
    } else {
      window.open(currentURL, "_blank");
    }
  };
  return (
    <section className="mt-4">
      <div className="calender-dtails mb-2">
        <h3>{`${t("Details")}`}</h3>
      </div>
      <div className="calender-auto-overflow">
        {showEvent.length > 0 ? (
          showEvent.map((item) => (
            <div>
              <div className="event-details flex justify-between items-center p-4 AS_Event">
                <div className="event-detail">
                  <h3>{item.name}</h3>
                  <p>{item.description}</p>
                </div>
                {item.image === null ? (
                  ""
                ) : (
                  <div className=" EventImage ml-2">
                    <img src={`${BASE_URL_IMG}${item.image}`} alt="" />
                  </div>
                )}
              </div>

              <div className="event-time justify-between  flex  items-center mt-4 ">
                <div className="mr-8">
                  <h3 className="flex items-center ">
                    {" "}
                    <img src={time} alt="" className="w-6 mr-2" />
                    <span>{`${t("Time")}`} </span>{" "}
                  </h3>
                </div>

                <div className="events-detailslist">
                  <p>
                    {`${moment(item.startTime).format("LT")} to ${moment(
                      item.endTime
                    ).format("LT")}`}
                    , {moment(item.start).format("LL")}
                  </p>
                  <p>{item.timezone}</p>
                </div>
              </div>
              <div className="event-time justify-between flex  items-center mt-4 ">
                <div className="">
                  <h3 className="flex items-center">
                    {" "}
                    <img src={orgainser} alt="" className="w-6 mr-2" />{" "}
                    <span>{`${t("Organiser")}`}</span>
                  </h3>
                </div>

                <div className="events-detailslist">
                  <p>{item.organiser}</p>
                </div>
              </div>

              <div className="event-time  justify-between  flex  items-center mt-4 ">
                <div className="mr-8">
                  <h3 className="flex items-center">
                    <img src={sector} alt="" className="w-6 mr-2" />{" "}
                    <span>{`${t("Sector")}`} </span>
                  </h3>
                </div>

                <div className="events-detailslist">
                  <p>
                    {item.sector
                      .filter(
                        (item) => item.language === localStorage.getItem("lng")
                      )
                      .map((item) => item.name)
                      .join(", ")}
                  </p>
                </div>
              </div>
              {item?.location && (
                <div className="event-time  justify-between  flex  items-center mt-4 ">
                  <div className="mr-8">
                    <h3 className="flex items-center">
                      {" "}
                      <img src={url} alt="" className="w-6 mr-2" />{" "}
                      <span>{`${t("Location")}`} </span>{" "}
                    </h3>
                  </div>
                  <div className="events-detailslist">{item?.location}</div>
                </div>
              )}
              {item && item.links.length > 0 && (
                <div className="event-time  justify-between  flex  items-center mt-4 ">
                  <div className="mr-8">
                    <h3 className="flex items-center">
                      {" "}
                      <img src={url} alt="" className="w-6 mr-2" />{" "}
                      <span>{`${t(" Meeting link")}`}</span>{" "}
                    </h3>
                  </div>
                  <div className="events-detailslist">
                    {item && item.links.map((items) => <p onClick={() => handleMeeting(items, item)} style={{cursor:"pointer"}} >{items}</p>)}
                  </div>
                </div>
              )}

              {item && item.otherlink.length > 0 && (
                <div className="event-time  justify-between  flex  items-center mt-4 ">
                  <div className="mr-8">
                    <h3>Other link</h3>
                  </div>
                  <div className="events-detailslist">
                    {item && item.otherlink.map((items) => <p>{items}</p>)}
                  </div>
                </div>
              )}
            </div>
            // <div
            //   className={`p-5 mb-5 ${item.postTo == "li" ? "bg-sidenav" : "bg-blue"}`
            //   }
            //   key={item._id}
            // >
            //   <h3
            //     className={`text-lg ${item.postTo == "li" ? "text-white" : "text-white"} `}
            //   >
            //     {item.name}
            //   </h3>
            //   <p
            //     className={`text-sm ${item.postTo == "li" ? "text-white" : "text-white"}`}>
            //     {item.description}
            //   </p>
            //   <p
            //     className={`text-sm ${item.postTo == "li" ? "text-white" : "text-white"}`}
            //   >
            //     {`${formatDate(item.start)} to ${formatDate(item.end)}`}
            //   </p>
            //   <p className={`text-sm ${item.postTo == "li" ? "text-white" : "text-white"}`}>{`${moment(item.startTime).format('LT')} to ${moment(item.end).format('LT')}`}</p>
            //   <p className={`text-sm ${item.postTo == "li" ? "text-white" : "text-white"}`}>{item.timezone}</p>
            //   <p
            //     className={`text_tosmall_10 ${item.postTo == "li" ? "text-white" : "text-white"}`}
            //   >
            //     {item.links.map((item2, index) => {
            //       return (
            //         <><div key={index}>
            //           <p>{item2}</p>
            //         </div>
            //         </>
            //       )
            //     })}
            //   </p>
            // </div>
          ))
        ) : (
          <div className="ml-5">
            <p>No Events for today</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default EventModal;
