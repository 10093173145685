import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import doc from '../../Assets/images/ResourceImg/pdfimg.png'
import doctwo from '../../Assets/images/ResourceImg/videoimage.png'
import pdf from '../../Assets/images/ResourceImg/PDF_Icon.svg'
import download from '../../Assets/images/ResourceImg/download.png'
import videoicon from '../../Assets/images/ResourceImg/videoicon.png'
import playIcon from '../../Assets/images/ResourceImg/playicon.svg'
import { BASE_URL, BASE_URL_IMG, dowanloadpdfApi } from '../../Utils/services'
import { useContext } from 'react'
import { ModalContext } from '../../Context'
import VideoModal from '../../Common/VideoModal'
import { Link } from 'react-router-dom'
import { Globalinformation } from '../../Common/MainSectionProvider'
import PdfModal from '../../ModalTemplate/PdfModal'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import FileSaver from 'file-saver'

const Legalmain = ({
  videoData,
  pdfData,
  filter,
  HandaleViewCount,
  HandleDownloadCount,
}) => {
  const { fileteValue, setFilterValue, tagsearch, settagsearch } = useContext(
    Globalinformation,
  )

  const modalContext = useContext(ModalContext)
  const { closeModal, handleModalData } = modalContext
  const { t } = useTranslation(['home'])

  const handlePreviewVideo = (item) => {
    const videoModal = <VideoModal link={item.media[0].mp4link} />

    handleModalData(videoModal, 'xl')
  }

  const handlePreviewImage = (item) => {
    // const pdfModal = <PdfModal link={item.pdfimg} />
    window.open(`${BASE_URL}/testdata?link=${item.media[0].link}`, "_blank", "noreferrer");


    // handleModalData(pdfModal, 'lg')
  }


  function secondsToMinutes(time) {
    return Math.floor(time / 60) + ':' + Math.floor(time % 60)
  }

  const [showAll, setShowAll] = useState(false)

  const toggleShowAll = () => {
    setShowAll(!showAll)
  }

  const handleDowloadpdf = async (item, name, items) => {
    const result = await dowanloadpdfApi({
      link: item,
      filename: `${name} ` + `.pdf`,
    })
    await FileSaver.saveAs(result.res, `${name} ` + `.pdf`)
    HandleDownloadCount(items)
  }

  const handleVideoDownload = async (item, name, items) => {
    const result = await dowanloadpdfApi({
      link: item,
      filenames: `${name} ` + `.mp4`,
    })
    await FileSaver.saveAs(result.res, `${name} ` + `.mp4`)
    HandleDownloadCount(items)
  }
  return (
    <>
      <div className="pt-10 mb-4 flex justify-between legmainheader">
        <h1 className="">
          {pdfData?.length > 0 && <>{`${t('Documents')}`}</>}{' '}
        </h1>
        <span
          type="underline"
          className="cursor-pointer"
          onClick={toggleShowAll}
        >
          {pdfData?.length > 2 && `${showAll ? `${t('View Less')}` : `${t('View More')}`}`}{' '}
        </span>
      </div>
      {
        pdfData.length === 0 && videoData.length === 0 && (
          <div className='flex justify-content-center align-items-center'>
            <soan>
              No Data
            </soan>
          </div>
        )
      }
      {
        pdfData?.length > 0 && (
          <Row>
            {pdfData &&
              pdfData
                .slice(0, showAll ? pdfData.length : 3)
                .filter((val) => {
                  if (filter == '') {
                    return val
                  } else if (
                    val &&
                    val.title &&
                    val.title.toLowerCase().includes(filter.toLowerCase())
                  ) {
                    return val
                  }
                })
                ?.map((item, i) => (
                  <Col lg={4} md={6} sm={6} xs={6} key={i}>
                    <div className="my-2">
                      <div className="card w-100 h-100">
                        <div className="card-body px-2">
                          <div onClick={() => HandaleViewCount(item)}>
                            <img
                              className="card-img-bottom cursor-pointer h-36 object-cover"
                              alt="Card cap"
                              src={`${BASE_URL_IMG}${item.pdfimg}`}
                              loading="Lazy"
                              onClick={() => handlePreviewImage(item)}
                            />
                          </div>
                          <div className="flex mt-2 active_socialbtn justify-between flex-column ">
                            <div className="flex justify-between items-center">
                              <img
                                src={pdf}
                                alt=""
                                className="w-4 h-4"
                                onClick={() =>
                                  handleDowloadpdf(item.media[0].link, item.title)
                                }
                              />
                              <div
                                data="sample-report.pdf"
                                type="application/pdf"
                                download={true}
                                className="text-center resourcetext"
                              >
                                {item.title.slice(0, 42)}
                              </div>
                              <p
                                className="cursor-pointer"
                                onClick={() =>
                                  handleDowloadpdf(
                                    item.media[0].link,
                                    item.title,
                                    item,
                                  )
                                }
                              >
                                {' '}
                                <img
                                  src={download}
                                  alt=""
                                  className="w-4 h-4"
                                // onClick={HandleDownloadCount(item)}
                                />
                              </p>
                            </div>
                            <p className="text-center">
                              {`${t('Uploaded on')}`} {moment(item.date).format('ll')}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </Col>
                ))}

            {pdfData.length == 0 && (
              <>
                <h3 className="text-center noresource mt-8">{`${t('No Resources')}`}</h3>
              </>
            )}
          </Row>
        )
      }

      <div className="pt-5 mb-4 flex justify-between legmainheader">
        <h1 className="">
          {' '}
          {videoData?.length > 0 && <>{`${t('Videos')}`}</>}{' '}
        </h1>
        <span type="underline">{videoData?.length > 6 && 'View More'} </span>
      </div>
      {
        videoData?.length > 0 && (
          <Row>
            {videoData &&
              videoData
                .filter((val) => {
                  if (filter == '') {
                    return val
                  } else if (
                    val &&
                    val.title &&
                    val.title.toLowerCase().includes(filter.toLowerCase())
                  ) {
                    return val
                  }
                })
                .map((item) => {
                  return (
                    <Col md={4}>
                      <div className="my-2">
                        <div className="card">
                          <div className="card-body">
                            <div
                              className="video_thumb"
                              onClick={() => HandaleViewCount(item)}
                            >
                              <img
                                className="card-img-bottom cursor-pointer"
                                alt="Card cap"
                                src={`${BASE_URL_IMG}${item.videoimg}`}
                                onClick={() => handlePreviewVideo(item)}
                              />
                              <div className="playicon">
                                <img src={playIcon} style={{ cursor: "pointer" }} alt="" onClick={() => handlePreviewVideo(item)} />
                              </div>

                              <p>{secondsToMinutes(item.videoDuration)}</p>
                            </div>
                            <div className="flex mt-2 active_socialbtn justify-between flex-column ">
                              <div className="flex justify-between items-center">
                                <img src={videoicon} alt="" className="w-6 h-6" />
                                <h5 className="text-center resourcetext">
                                  {item.title.slice(0, 25)}...{' '}
                                </h5>
                                <p
                                  className="cursor-pointer"
                                  onClick={() =>
                                    handleVideoDownload(
                                      item.media[0].mp4link,
                                      item.title,
                                      item,
                                    )
                                  }
                                >
                                  <img src={download} alt="" className="w-5 h-5" />
                                </p>
                              </div>
                              <p>{`${t('Uploaded on')}`} {moment(item.date).format('ll')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                })}

            {/* <Col md={4}>
            <div className="my-2">
              <div className="card">
                <div className="card-body">
                  <img
                    className="card-img-bottom cursor-pointer"
                   
                    alt="Card image cap"
                    src={doc}
      
                  />
                  <div className="flex mt-2 active_socialbtn justify-between flex-column ">
                    <p>Uploaded on 22, Jun'22 | 1:33 PM</p>
                    <div className="flex justify-between items-center">
                      <img src={videoicon} alt="" className="w-6 h-6" /> 
                      <h5 className="text-center resourcetext">Lorem, ipsum dolor sit amet consectetur </h5>
                      <img src={download} alt="" className="w-5 h-5" /> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="my-2">
              <div className="card">
                <div className="card-body">
                  <img
                    className="card-img-bottom cursor-pointer"
                   
                    alt="Card image cap"
                    src={doc}
      
                  />
                  <div className="flex mt-2 active_socialbtn justify-between flex-column ">
                    <p>Uploaded on 22, Jun'22 | 1:33 PM</p>
                    <div className="flex justify-between items-center">
                      <img src={videoicon} alt="" className="w-6 h-6" /> 
                      <h5 className="text-center resourcetext">Lorem, ipsum dolor sit amet consectetur </h5>
                      <img src={download} alt="" className="w-5 h-5" /> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="my-2">
              <div className="card">
                <div className="card-body">
                  <img
                    className="card-img-bottom cursor-pointer"
                   
                    alt="Card image cap"
                    src={doc}
      
                  />
                  <div className="flex mt-2 active_socialbtn justify-between flex-column ">
                    <p>Uploaded on 22, Jun'22 | 1:33 PM</p>
                    <div className="flex justify-between items-center">
                      <img src={videoicon} alt="" className="w-6 h-6" /> 
                      <h5 className="text-center resourcetext">Lorem, ipsum dolor sit amet consectetur </h5>
                      <img src={download} alt="" className="w-5 h-5" /> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="my-2">
              <div className="card">
                <div className="card-body">
                  <img
                    className="card-img-bottom cursor-pointer"
                   
                    alt="Card image cap"
                    src={doc}
      
                  />
                  <div className="flex mt-2 active_socialbtn justify-between flex-column ">
                    <p>Uploaded on 22, Jun'22 | 1:33 PM</p>
                    <div className="flex justify-between items-center">
                      <img src={videoicon} alt="" className="w-6 h-6" /> 
                      <h5 className="text-center resourcetext">Lorem, ipsum dolor sit amet consectetur </h5>
                      <img src={download} alt="" className="w-5 h-5" /> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="my-2">
              <div className="card">
                <div className="card-body">
                  <img
                    className="card-img-bottom cursor-pointer"
                   
                    alt="Card image cap"
                    src={doc}
      
                  />
                  <div className="flex mt-2 active_socialbtn justify-between flex-column ">
                    <p>Uploaded on 22, Jun'22 | 1:33 PM</p>
                    <div className="flex justify-between items-center">
                      <img src={videoicon} alt="" className="w-6 h-6" /> 
                      <h5 className="text-center resourcetext">Lorem, ipsum dolor sit amet consectetur </h5>
                      <img src={download} alt="" className="w-5 h-5" /> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col> */}
          </Row>
        )
      }

    </>
  )
}

export default Legalmain
