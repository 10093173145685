import moment from "moment";
import React, { useState } from "react";
import { BASE_URL_IMG, GetMeetingCode, TRANSLATE_URL } from "../Utils/services";
import time from "../Assets/images/EventIcon/time.png";
import orgainser from "../Assets/images/EventIcon/org.png";
import sector from "../Assets/images/EventIcon/sector.png";
import url from "../Assets/images/event/link.svg";
import location from "../Assets/images/event/location.svg";
import { useTranslation } from "react-i18next";
import { Globalinformation } from "../Common/MainSectionProvider";
import { useContext } from "react";
import error from "../Assets/error.png";
import { toast } from "react-toastify";
import TexttoSpeach from "../Components/TexttoSpeach";

const Event = ({ item }) => {
  const { t } = useTranslation(["home"]);

  // for translate the title

  const { translateLanguage } = useContext(Globalinformation);
  const [content, setContent] = useState(item?.name);
  const [description, setdescription] = useState(item?.description);

  function getText(html) {
    var tmp = document.createElement("div");
    tmp.innerHTML = html;

    return tmp.textContent || tmp.innerText;
  }

  const handleChangeButton = () => {
    let toLang = translateLanguage; // translate to norwegian
    let text = `${content}`;

    let textContent = `${description}`;
    let url = TRANSLATE_URL;
    url += "&q=" + encodeURI(text);
    url += "&q=" + encodeURI(textContent);
    url += `&target=${toLang}`;
    const token = localStorage.getItem("token");

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`, // notice the Bearer before your token
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setContent(response.data.translations[0].translatedText);
        setdescription(response.data.translations[1].translatedText);
      })
      .catch((error) => {
        //console.error(("There was an error with the translation request: ", error);
      });
  };
  const handleMeeting = async (currentURL, data) => {

    const endTimeStr = data.end;
    const endTime = new Date(endTimeStr);
    const currentTime = new Date();
    if (false) {
      toast.info("The Link has been expired");
      return null;
    }
    const parsedUrl = new URL(currentURL);
    const domain = parsedUrl.origin;

    if (domain === "https://admin.glp-connect.org" || domain === "https://glpadmin.netlify.app") {
      const RoomCode = await GetMeetingCode({
        roomId: data.roomDetails[0].id.replace(/\\/g, "").replace(/"/g, ""),
      });
      const jsonData = RoomCode.res.split("Room Detail: ")[1];

      // Parse JSON
      const parsedData = JSON.parse(jsonData);

      // Access the data
      const roomDetails = parsedData.data;
      console.log(roomDetails, "data");

      function openNewTab(slugCode) {
        const url = `${window.location.origin}/glpmeeting/${slugCode}`; // Replace with your actual URL
        window.open(url, "_blank");
      }

      // Check the role and open a new tab if it's "new-role-6288"
      roomDetails.forEach((room) => {
        if (room.role === "guest") {
          openNewTab(room.code);
        }
      });

      // const urlParts = currentURL.split("?");
      // const key = urlParts[1].split("/")[0].split("=")[1];
      // const channelid = urlParts[2].split("=")[1];
      // navigate("/glpmeeting", {
      //   state: {
      //     _token: localStorage.getItem("token"),
      //     token: channelid,
      //     channel_id: key,
      //     userid: localStorage.getItem("adminId"),
      //     roomid: data.eventId,
      //     name: localStorage.getItem("adminName"),
      //   },
      // });
    } else {
      window.open(currentURL, "_blank");
    }
  };
  return (
    <div>
      <div className="event-details flex justify-between items-center p-4 AS_Event">
        <div className="event-detail">
          <div className="flex items-center gap-3" style={{ gap: "1rem" }}>
            <div>
              <h3>{content}</h3>
              <p>{description}</p>
            </div>
            <TexttoSpeach text={content} discription={description} />
          </div>

        </div>
        <div className="form-image EventImage ml-2">
          <button onClick={handleChangeButton}>{`${t("Translate")}`}</button>
          {item.image === "" || item.image === null ? (
            ""
          ) : (
            <img
              src={`${BASE_URL_IMG}${item.image}`}
              alt=""
              onError={(e) => {
                e.target.style.display = "none"; // Hide the image on error
              }}
            />
          )}
        </div>
      </div>

      <div className="event-time justify-between  flex  items-center mt-4 ">
        <div className="mr-8">
          <h3 className="flex items-center ">
            {" "}
            <img src={time} alt="" className="event-detail-img   mr-2" />
            <span>{`${t("Time")}`}</span>{" "}
          </h3>
        </div>

        <div className="events-detailslist">
          <p>
            {`${moment(item.startTime).format("LT")} to ${moment(
              item.endTime
            ).format("LT")}`}
            , {moment(item.start).format("LL")}
          </p>
          <p>{item.timezone}</p>
        </div>
      </div>

      {item.organiser !== "" && (
        <div className="event-time justify-between flex  items-center mt-4 ">
          <div className="">
            <h3 className="flex items-center">
              {" "}
              <img src={orgainser} alt="" className="w-6 mr-2" />{" "}
              <span>{`${t("Organiser")}`}</span>
            </h3>
          </div>

          <div className="events-detailslist">
            <p>{item.organiser}</p>
          </div>
        </div>
      )}

      {item.sector.length !== 0 && (
        <div className="event-time  justify-between  flex  items-center mt-4 ">
          <div className="mr-8">
            <h3 className="flex items-center">
              <img src={sector} alt="" className="w-6 mr-2" />{" "}
              <span>{`${t("Sector")}`}</span>
            </h3>
          </div>

          <div className="events-detailslist">
            <p>
              {item.sector
                .filter((item) => item.language === localStorage.getItem("lng"))
                .map((item) => item.name)
                .join(", ")}
            </p>
          </div>
        </div>
      )}

      {item?.location && (
        <div className="event-time  justify-between  flex  items-center mt-4 ">
          <div className="mr-8">
            <h3 className="flex items-center">
              {" "}
              <img src={location} alt="" className="w-6 mr-2" />{" "}
              <span> {`${t("Location")}`}</span>{" "}
            </h3>
          </div>
          <div className="events-detailslist">{item?.location}</div>
        </div>
      )}
      {item && item.links.length > 0 && (
        <div className="event-time  justify-between  flex  items-center mt-4 ">
          <div className="mr-8">
            <h3 className="flex items-center">
              {" "}
              <img src={url} alt="" className="w-6 mr-2" />{" "}
              <span> {`${t("Meeting link")}`}</span>{" "}
            </h3>
          </div>
          <div className="events-detailslist">
            {item &&
              item.links.map((items, index) => (
                <p>
                  <div key={index} className="as_web cursorpointer" style={{ cursor: "pointer" }}>
                    <div
                      href={items}
                      onClick={() => handleMeeting(items, item)}
                    >
                      {items}
                    </div>
                  </div>
                  <div key={index} className="as_mobile cursorpointer" style={{ cursor: "pointer" }}>
                    <div
                      href={items}
                      onClick={() => handleMeeting(items, item)}
                    >
                      {items.slice(0, 20)}.....
                    </div>
                  </div>
                </p>
              ))}
          </div>
        </div>
      )}

      {item && item.otherlink.length > 0 && (
        <div className="event-time  justify-between  flex  items-center mt-4 ">
          <div className="mr-8">
            <h3>{`${t("Other link")}`}</h3>
          </div>
          <div className="events-detailslist">
            {item &&
              item.otherlink.map((items, index) => (
                <p>
                  <>
                    <div key={index} className="as_web">
                      <a href={items} target="_blank" rel="noreferrer">
                        {items}
                      </a>
                    </div>
                    <div key={index} className="as_mobile">
                      <a href={items} target="_blank" rel="noreferrer">
                        {items.slice(0, 20)}.....
                      </a>
                    </div>
                  </>
                </p>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Event;
