import React from 'react'
import { useState } from 'react';
import filter from '../../Assets/images/MobileIcon/calender.png'
import socialmark from "../../Assets/images/Social/bookmark2.svg"
import FiterBy from './FiterBy';
import SaveForum from './SaveForum';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { useEffect } from 'react';

import { BASE_URL_IMG } from '../../Utils/services';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SearchEvent = ({setFilterForum,setTagFilter,eventAllData,getEventApiData}) => {
    const userNameID = localStorage.getItem("adminId");
    const [filters,setFilter] =useState(false);
    const [savedShow,setSavedShow] =useState(false);
    let navigate = useNavigate();
    const { t } = useTranslation(['home'])
    const handleFilter = () =>{
      setFilter(!filters)
      setSavedShow(false)
  
    }

    const userName = localStorage.getItem("adminName");
  

    const [toggle, settoggle] = useState(false);

    const [currentDate, setCurrentDate] = useState(new Date());
    const [showEvent, setShowEvent] = useState([]);
    const [year, setYear] = useState(new Date().getUTCFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);
  
    const handleToggleButton = (e) => {
      e.preventDefault();
      settoggle(!toggle)
    }
  
    const formatDate = date => {
      const _date = new Date(date)
      const month = _date.getMonth() + 1
      const year = _date.getFullYear()
      const newdate = _date.getDate()
      return `${newdate}/${month}/${year}`
    }
  
    const getAllApiDataOnLoad = () => {
      setShowEvent([])
      let arry = []
      eventAllData && eventAllData.map((item) => {
        const _date = new Date(item.current)
        const _d1 = formatDate(_date)
        const _d2 = formatDate(currentDate)
        if (_d1 === _d2) {
          arry.push(item)
        }
        else {
          //console.log((false);
        }
      })
      setShowEvent(arry)
    }
  
    useEffect(() => {
      getAllApiDataOnLoad();
    }, [eventAllData])
  
    const dateHandler = (date) => {
      setCurrentDate(date)
      setShowEvent([])
      let arry = []
      eventAllData.map((item) => {
        const _date = new Date(item.current)
        const _d1 = formatDate(_date)
        const _d2 = formatDate(date)
        if (_d1 === _d2) {
          arry.push(item)
        }
        else {
          //console.log((false);
        }
        setShowEvent(arry)
      })
    }
  
    const handleActiveStartDate = (value) => {
      //console.log((value.activeStartDate, "Change Month")
      const _yearMonth = value.activeStartDate;
      const year = _yearMonth.getFullYear();
      const month = _yearMonth.getMonth() + 1;
      setCurrentDate(value.activeStartDate)
      setYear(year)
      setMonth(month)
    }
    useEffect(() => {
      getEventApiData(year, month);
    }, [year, month]);

    const handleTileClassName = ({ date }) => {
        const _newData = [];
        eventAllData && eventAllData.map((item) => {
            _newData.push(item.current)
        })
       const _res = _newData.filter((item) => {
            return formatDate(item) === formatDate(date)
        })
    
        for (let x in _newData) {
            if (formatDate(_res[0]).includes(formatDate(_newData[x]))) {
                return "cutome_calander_class"
            }
        }
    }


  return (
      <div  className='bg-white mb-4 '>
    <div className="flex  mb-2 justify-start flex-wrap items-center p-4 rounded  " >
        <img src={`${BASE_URL_IMG}/user/${userNameID}/${userNameID}.jpeg`} className="h-8  mr-2 cursor-pointer " onClick={()=>navigate('/profile')}   alt="" />               
        <div className="bg-gray p-2 search-wrapper mr-2">
        <div className="inputsearch">
          <input type="text" placeholder={`${t("Search")}`} className="mx-3 px-3" />
        </div>
        </div>

         <div className='flex items-center  cursor-pointer mt-2' onClick={handleFilter}>
          <img src={filter} alt="" className='mr-2 cursor-pointer' />
          <span className='As_mobile_calender'>Calendar</span>         
        </div>                   
    </div>
    {filters &&  <section className="filterSection  flex justify-center ">
            {/* <h3>Filter By</h3> */}
            <Calendar
              onChange={dateHandler}
              value={currentDate}
              onActiveStartDateChange={handleActiveStartDate}
              next2Label={null}
              prev2Label={null}
              tileClassName={handleTileClassName}
          />

           
          </section> }
   

</div>
  )
}

export default SearchEvent