import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import RealatedThread from '../../Forum/commentsection/RealatedThread'
import Recomanded from './Recomanded'
import StoryInnerMain from './StoryInnerMain'

const StoryInnerPage = () => {

  return (
    <Container>
        <Row>
            <Col md={7} className="mt-3">
                <StoryInnerMain />
            </Col>
            <Col md={5}>
                <Recomanded />
            </Col>
        </Row>

    </Container>
  )
}

export default StoryInnerPage