import { AppBar, Box, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Filter from '../../Common/Filter'
import { Globalinformation } from '../../Common/MainSectionProvider'
import NewsFilter from '../../Common/NewsFilter'
import NewsTabs from '../../Common/NewsTabs'
import {
  getNewsFeed,
  getNewsFeedFilter,
  getSearchNewsFeed,
} from '../../Utils/services'
import ForumRightSidebar from '../Forum/ForumRightSidebar'
import NewsMain from './NewsMain'

const NewsFeed = () => {
  const [newsFeed, setNewsFeed] = useState()
  const [filterVal, setFilterVal] = useState('')
  const { fileteValue, setFilterValue, tagsearch, settagsearch } = useContext(
    Globalinformation,
  )
  const [searchfilter, setSearchfilter] = useState('')

  const [filter, setFilter] = useState('')
  const [filterTag, setFilterTag] = useState('')
  const [selectedcourse, setselectedCourse] = useState([])

  return (
    <Container>
      <Row>
        <Col md={7}>
          <NewsTabs searchfilter={filter} selectedfilter={selectedcourse} />
        </Col>
        <Col md={5}>
          <Filter
            setGalleryFilter={setFilter}
            setFilterTag={setFilterTag}
            selectedcourse={selectedcourse}
            setselectedCourse={setselectedCourse}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default NewsFeed
