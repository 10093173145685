import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
import Filter from "../../Common/Filter";
import SearchFilter from "../../Common/SearchFilter";
import SearchTool from "../../Common/SearchHeader/SearchTool";
import { DownloadResourceCount, getCategary, getResource, ViewResource } from "../../Utils/services";
import Legalmain from "./Legalmain";

const LegalProvisons = () => {
  const [getCategaryId, setCategoryId] = useState("");
  const [filter,setFilter] =useState("")
  const [loader, setloader] = useState(false);

  const [pdfData, setPdfData] = useState([]);
  const [videoData, setvideoData] = useState([]);

  const getCetegaryeData = async () => {
    setloader(true);
    const result = await getCategary();
    const _getcategaryFilter =await  result.res.filter(
      (item) => item.name === "Legal provisions"
      // (item) => item.name === "Legal Provisions"

    );
    
   
    setloader(false);
    setCategoryId(_getcategaryFilter[0]._id);


  };

  useEffect(() => {
    getCetegaryeData();
  }, []);

  const getResourceData = async (id) => {
    
    const result = await getResource({
      "id": id,
    });

    setPdfData(result.res.pdfdata);
    setvideoData(result.res.videodata);

  };


  const HandaleViewCount =async(item) =>{
    const result = await ViewResource({
      "id":item._id
    }
  )  
  }

  const HandleDownloadCount =async(item) =>{
    const result =await DownloadResourceCount( {   
       "id":item._id
      }
    )

  }
  

  useEffect(() => {
    if (getCategaryId) {
      getResourceData(getCategaryId);
    }
  }, [getCategaryId]);

  return (
    <Container>
      <Row>
        
       <div className="mobilesearch mt-20">
        <SearchTool setFilterForum={setFilter} />
       </div>

        <Col lg={7} className="legalmain">
          {loader ? (
            <div className="mt-16 flex justify-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Legalmain HandleDownloadCount={HandleDownloadCount} HandaleViewCount={HandaleViewCount} pdfData={pdfData} videoData={videoData} filter={filter}  />
          )}
        </Col>
        <Col lg={5} className="legalsidebar">
          <SearchFilter setGalleryFilter={setFilter}  />
        </Col>
      </Row>
    </Container>
  );
};

export default LegalProvisons;
