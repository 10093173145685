import moment from 'moment';
import React, { useContext } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { ModalContext } from '../../../Context';
import EventHelp from '../../../ModalTemplate/EventHelp';
import { getEvent } from '../../../Utils/services';

const GethelpEvent = () => {
    const [eventList,setEventList] =useState([]);
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;



    


    const getEventApiData = async (year, month) => {
        try {
          const result = await getEvent(
            {
              "month":month,
              "year": year,
              'isGethelp' :true,

            }
          );
          setEventList(result.res.data)
        
        } catch (error) {
        }
      };


    const getEventDetails =(item) =>{

      const EVENTDETAILS =<EventHelp item={item} />
      handleModalData(EVENTDETAILS,"lg")

    }





      useEffect(()=>{
        getEventApiData("2022","12")
      },[])

    

   return (
    <div>
      <Container>
        <Row>
          <Col lg={7}>
          <div className="mt-4">
          <div className="pt-4 pt-b directorytitle">

            <h1>Event</h1>
          </div>
          
           {eventList && eventList.map((item)=>{
            return(
                <>
                 {
                    item.isGethelp &&

                    <div className='help-event p-4 my-4 cursor-pointer' onClick={()=>getEventDetails(item)} >
                    <h4>{item.name}</h4>
                    <p>{`${moment(item.startTime).format('LT')} to ${moment(item.endTime).format('LT')}`}, {moment(item.startTime).format('LL')}</p>
                    <p>{item.timezone}</p>
                    {
                        item && item?.links.map((items)=>{
                          return(
                            <>
                              <a href={items} className="text-blue" target="_blank">{items}</a>
                            </>
                          )
                        })
                    }
                    <p>{item?.location}</p>
                    
                   </div>
                    
                 }
                

                </>
            )
             
           })}
                   
              
          </div>

          </Col>
        </Row>
      </Container>
    </div>
   )
}

export default GethelpEvent ;