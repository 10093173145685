import React, { useContext } from 'react'
import Logo from '../../Assets/images/glplogotwo.png'

import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'
import AuthContext from '../../Utils/auth-context'
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import mobilelogo from '../../Assets/images/connectmobile.png'
import { useTranslation } from 'react-i18next'

const NavbarResponsive = () => {
  const authCtx = useContext(AuthContext)
  const { logout } = authCtx
  const { t } = useTranslation(["home"]); 

  const handleLogout = () => {
    logout()
  }

  return (
    <div className="navbarres">
      {/* <nav className="nav flex flex-wrap items-center justify-between px-4 NavBarResponsive_bg border-none">
        <div className="flex flex-no-shrink items-center mr-6 py-3 text-white ">
          <img src={Logo} alt="logo" className="w-20 mr-3" />
        </div>

        <input className="menu-btn hidden" type="checkbox" id="menu-btn" />
        <label
          className="menu-icon block cursor-pointer md:hidden px-2 py-4 relative select-none"
          htmlFor="menu-btn"
        >
          <span className="navicon  flex items-center relative"></span>
        </label>

        <ul className="menu  md:border-none flex justify-end list-reset m-0 w-full md:w-auto">
          <li className="">
            <Link
              to="/"
              className="block md:inline-block px-4 py-3 no-underline text-white hover:text-grey-darker font-bold"
            >
              Forum
            </Link>
          </li>

          <li className="">
            <Link
              to="/gallery"
              className="block md:inline-block px-4 py-3 no-underline text-white hover:text-grey-darker"
            >
              Gallery
            </Link>
          </li>
          <li className="">
            <Link
              to="#"
              className="block md:inline-block px-4 py-3 no-underline text-white hover:text-grey-darker"
            >
              Resources
            </Link>


          </li>
          
                

          <li className="">
            <Link
              to="/toolkits"
              className="block md:inline-block px-4 py-3 no-underline text-white hover:text-grey-darker"
            >
              Toolkits
            </Link>
          </li>

          <li className="">
            <Link
              to="/events "
              className="block md:inline-block px-4 py-3 no-underline text-white hover:text-grey-darker"
            >
              Events
            </Link>
          </li>


          <li className="">
            <Link
              to="/notification"
              className="block md:inline-block px-4 py-3 no-underline text-white hover:text-grey-darker"
            >
              Notifications
            </Link>
          </li>

          <li className="">
            <Link
              to="/stories"
              className="block md:inline-block px-4 py-3 no-underline text-white hover:text-grey-darker"
            >
              Stories
            </Link>
          </li>

          <li className="">
            <Link
              to="/newsfeed"
              className="block md:inline-block px-4 py-3 no-underline text-white hover:text-grey-darker"
            >
              Newsfeed
            </Link>
          </li>

          <li className="">
            <p
              className="block md:inline-block px-4 py-3 no-underline text-white hover:text-grey-darker"
              onClick={logout}
            >
              Log Out
            </p>
          </li>
        </ul>
      </nav> */}
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        className="p-4 navbarBg"
      >
        <Navbar.Brand href="#home">
          <img src={mobilelogo} alt="" className="w-32" />
        </Navbar.Brand>
        <Navbar.Toggle className="TagleBar" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/"> {`${t(`${"Forum"}`)}`}</Nav.Link>

            <NavDropdown
              title= {`${t(`${"Resources"}`)}`} 
              id="collasible-nav-dropdown"
              className="drodownnav"
            >
              <NavDropdown.Item href="/legal-provisions">
              {`${t(`${"Legal provisions"}`)}`}
              </NavDropdown.Item>
              <NavDropdown.Item href="/local-support-structures">
              {`${t(`${"Local support structures"}`)}`}
              </NavDropdown.Item>
              <NavDropdown.Item href="/occupational-health-safety">
              {`${t(`${"Occupational health and safety"}`)}`}
              </NavDropdown.Item>
              {/* <NavDropdown.Divider /> */}
              <NavDropdown.Item href="/social-protection-programs-benefits">
              {`${t(`${"Social protection programs and benefits"}`)}`}  
              </NavDropdown.Item>
              <NavDropdown.Item href="/financial-literacy">
              {`${t(`${"Financial literacy"}`)}`}
              </NavDropdown.Item>
              <NavDropdown.Item href="/skills-training">
              {`${t(`${"Skills Training"}`)}`}
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link eventKey={2} href="/gethelp">
            {`${t(`${"Get Help"}`)}`}
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link eventKey={2} href="/events">
            {`${t(`${"Events"}`)}`}
            </Nav.Link>
            <Nav.Link eventKey={2} href="/notification">
            {`${t(`${"Action Alerts"}`)}`}
            </Nav.Link>
            <Nav.Link eventKey={2} href="/newsfeed">
            {`${t(`${"Newsfeed"}`)}`}
            </Nav.Link>
            <Nav.Link eventKey={2} href="/stories">
            {`${t(`${"Stories"}`)}`}
            </Nav.Link>
            <Nav.Link href="/gallery"> {`${t(`${"Gallery"}`)}`}</Nav.Link>
            <Nav.Link href="/toolkits"> {`${t(`${"Toolkits"}`)}`}</Nav.Link>
            <Nav.Link href="/profile"> {`${t(`${"Profile"}`)}`}</Nav.Link>
            {/* <Nav.Link onClick={handleLogout}> {`${t(`${"Logout"}`)}`}</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default NavbarResponsive
