import React from "react";
import banner from "../../../Assets/images/Social/story.jpeg";
import InputText from "../../../Common/InputText";
import hr from "../../../Assets/images/Social/hrtwo.svg";
import comment from "../../../Assets/images/Social/comments.svg";
import diamond from "../../../Assets/images/Social/dimond2.svg";
import { AnimatePresence, motion } from "framer-motion";
import diamond2 from "../../../Assets/images/Social/diomand3.svg";
import { useMemo } from "react";
import {
  AddCommentStory,
  BASE_URL_IMG,
  TRANSLATE_URL,
  getBlockUserData,
  getStories,
  getStoryComments,
  likeStory,
} from "../../../Utils/services";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import TimeAgo from "react-timeago";
import { toast } from "react-toastify";
import StoryInnerComment from "./StoryInnerComment";
import { useContext } from "react";
import { Globalinformation } from "../../../Common/MainSectionProvider";
import { ModalContext } from "../../../Context";
import VerifyNumber from "../../../ModalTemplate/VerifyNumber";
import { useTranslation } from "react-i18next";
import TexttoSpeach from "../../TexttoSpeach";
import { Spinner } from "reactstrap";
import Shareicon from "../../ShareFunction";

const StoryInnerMain = () => {
  const [userData, setUserData] = useState([]);
  const [storyDetails, setStoryDetails] = useState({});
  // const [content,setContent] =useState("")
  const [addcomment, setAddcomment] = useState();
  const [hasClicked, setHasClicked] = React.useState(false);
  const [storycomment, setStorycomment] = useState([]);
  const [storyUsercomment, setStoryUsercomment] = useState([]);
  const [userReply, setUserReply] = useState([]);
  const [countComment, setCountComment] = useState();
  const [qusTion, setQustion] = useState("");
  const [content, setContent] = useState();
  const { translateLanguage } = useContext(Globalinformation);
  const [TranslatedqusTion, setTranslatedQustion] = useState("");
  const [Translatedcontent, setTranslatedContent] = useState("");
  const [isTranslated, setisTranslated] = useState(false);

  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const [loading, setloading] = useState(true)
  const IsVerify = localStorage.getItem("phoneverification");

  const admin_id = localStorage.getItem("adminId");
  const [adminId] = useState(admin_id);
  const location = useLocation();
  const { id } = useParams();
  // const item = location && location.state && location.state.item;
  const [blockData, setBlockData] = useState([]);
  const { t } = useTranslation(["home"]);
  const [item, setitem] = useState({})
  const times = new Date(item && item.date);
  const storyservicedata = async () => {
    try {
      setloading(true)
      const result = await getStories();

      const Story = result.res.filter((item) => item._id == id);
      setitem(Story[0])
      setStoryDetails(Story[0]);
      // setContent(Story[0].content)
      setCountComment(Story[0]?.comments);
      setloading(false)
    } catch (error) {
      setloading(false)

    }
  };

  const handlelike = async () => {
    try {
      const result = await likeStory({
        postId: id,
      });
      if (result.res.success) {
        // toast.success(result.res.message)
        setHasClicked(!hasClicked);
        storyservicedata();
      }
    } catch (error) {
    }
  };

  const handleVeriFication = () => {
    let VERIFYPHONE = <VerifyNumber />;
    handleModalData(VERIFYPHONE, "sm");
  };

  const handleAddcomment = async (event) => {
    event.preventDefault();

    if (addcomment && addcomment.length > 0) {
      if (IsVerify == "true") {
        const result = await AddCommentStory({
          postId: id,
          comment: addcomment,
        });

        if (result.res.success) {
          setAddcomment("");
          handlegetComment(id);
          setCountComment((prev) => prev + 1);
        }
      } else {
        handleVeriFication();
      }
    }
  };

  const handlegetComment = async (posstId) => {
    try {
      const result = await getStoryComments(posstId);
      setStorycomment(result.res.comments);
      setStoryUsercomment(result.res.users);
      setUserReply(result.res.user_reply);
    } catch (error) {
    }
  };

  useEffect(() => {
    handlegetComment(id);
  }, [id]);

  useMemo(() => {
    if (item.likes) {
      item.likes.filter((item) => {
        if (item.user == adminId) {
          setHasClicked(true);
        }
      });
    }
  }, [item]);

  useEffect(() => {
    if (id) {
      storyservicedata();
    }
  }, [id]);

  function getText(html) {
    var tmp = document.createElement("div");
    tmp.innerHTML = html;

    return tmp.textContent || tmp.innerText;
  }

  const handleChangeButton = () => {
    setisTranslated(!isTranslated);
    if (TranslatedqusTion === "" || Translatedcontent === "") {
      let toLang = translateLanguage; // translate to norwegian
      let text = `${item?.title}}`;
      let textContent = `${getText(item.caption)}`;
      let url = TRANSLATE_URL;
      url += "&q=" + encodeURI(text);
      url += "&q=" + encodeURI(textContent);
      url += `&target=${toLang}`;
      const token = localStorage.getItem("token");

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`, // notice the Bearer before your token
        },
      })
        .then((res) => res.json())
        .then((response) => {
          setTranslatedQustion(
            response.data.translations[0].translatedText.replaceAll(
              "&quot;",
              ""
            )
          );
          setTranslatedContent(
            response.data.translations[1].translatedText.replaceAll(
              "&quot;",
              ""
            )
          );
        })
        .catch((error) => {
        });
    }
  };

  const handleBLockData = async () => {
    const result = await getBlockUserData(admin_id);
    setBlockData(result?.res?.data);
  };

  useEffect(() => {
    handleBLockData();
  }, []);


  if (loading) {
    return (
      <div className="my-8">
        <div class="card">
          <div className="inner-story" style={{ height: "50rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Spinner />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="my-8">
        <div class="card">
          <div className="inner-story">
            <img
              src={`${BASE_URL_IMG}${item?.media?.[0]?.link}`} alt="Description"
              className="socialimagedetails "
            />
            <div className="storytranslatebtn-v2" >
              <button
                onClick={handleChangeButton}
              >{`${t("Translate")}`}</button>
              <TexttoSpeach
                text={isTranslated
                  ? TranslatedqusTion === ""
                    ? item?.title
                    : TranslatedqusTion
                  : item?.title}

                discription={
                  isTranslated
                    ? Translatedcontent === ""
                      ? item?.caption
                      : Translatedcontent
                    : item?.caption
                }
              />
            </div>
          </div>
          <div className="card-body">
            <h5 class="card-title mt-2  text-justify formdetailstile  ">
              {isTranslated
                ? TranslatedqusTion === ""
                  ? item?.title
                  : TranslatedqusTion
                : item?.title}
            </h5>
            <p class="card-text formdetailstags mb-2 mt-2 text-xsm ">
              {`${t("Tags")}`}:{" "}
              {item?.tags && item.tags.map((u) => u.name).join(", ")}{" "}
            </p>

            <div>
              {content ? (
                <>
                  {isTranslated
                    ? Translatedcontent === ""
                      ? content
                      : Translatedcontent
                    : content}
                </>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: isTranslated
                      ? Translatedcontent === ""
                        ? item?.caption
                        : Translatedcontent
                      : item?.caption,
                  }}
                />
              )}
            </div>

            <div className="flex mt-4 socialbtn ">
              <p className="mr-2 flex items-center h-5 cursor-pointer">
                <motion.div
                  whileTap={{ scale: 4 }}
                  transition={{ duration: 0.5 }}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={hasClicked ? diamond : diamond2}
                    alt=""
                    className="h-4"
                    onClick={() => handlelike()}
                  />
                </motion.div>
                <span className="px-2">
                  {storyDetails?.likes?.length} like{" "}
                </span>
              </p>
              <p className="mr-2 flex items-center h-5">
                {" "}
                <img src={comment} alt="" className="h-4 cursor-pointer" />{" "}
                <span className="px-2 cursor-pointer">
                  {countComment} Comments
                </span>{" "}
              </p>
              <Shareicon link={window.location.origin +`/stories/${item._id}`} />
              <p className="mr-2 flex items-center h-5">
                {" "}
                <img src={hr} alt="" className="h-4 cursor-pointer" />{" "}
                <span className="px-2 cursor-pointer">
                  <TimeAgo date={times} />
                </span>{" "}
              </p>
            </div>
            <InputText
              handleAddcomment={handleAddcomment}
              setAddcomment={setAddcomment}
              addcomment={addcomment}
            />

            {storycomment &&
              storycomment.map((item, i) => (
                <React.Fragment key={i}>
                  <div className={blockData.includes(item?.user) && `hidden`}>
                    <StoryInnerComment
                      setCountComment={setCountComment}
                      userReply={userReply}
                      storyUsercomment={storyUsercomment}
                      id={id}
                      handlegetComment={handlegetComment}
                      forumComment={storycomment}
                      item={item}
                    />
                  </div>
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default StoryInnerMain;
