import React, { useContext, useEffect, useState } from "react";
import socialimage from "../../../Assets/images/Social/image.png";
import socialmark from "../../../Assets/images/Social/bookmark3.svg";
import socialmark2 from "../../../Assets/images/Social/bookmark2.svg";
import diamond from "../../../Assets/images/Social/dimond2.svg";
import hr from "../../../Assets/images/Social/hrtwo.svg";
import comment from "../../../Assets/images/Social/comments.svg";
import {
  BASE_URL_IMG,
  TRANSLATE_URL,
  bookmarkservice,
  likeandunlikeservice,
} from "../../../Utils/services";
import { toast } from "react-toastify";
import TimeAgo from "react-timeago";
import diamond2 from "../../../Assets/images/Social/diomand3.svg";
import moment from "moment/moment";
import { AnimatePresence, motion } from "framer-motion";
import { Globalinformation } from "../../../Common/MainSectionProvider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Shareicon from "../../ShareFunction";

const ActiveForm = ({
  qustion,
  image,
  commentcount,
  tag,
  index,
  likes,
  item,
  contant,
  _id,
  formservicedata,
  userDetails,
  saveForm,
  time,
}) => {
  const [hasClicked, setHasClicked] = React.useState(false);
  const [Totallikes, settotallikes] = useState(likes.length);
  const [bookmarkClicked, setbookmarkClicked] = React.useState(false);
  const { getSavedForumAPiData, translateLanguage } =
    useContext(Globalinformation);
  const { t } = useTranslation(["home"]);
  const [qustiontitle, setqustiontitle] = useState(qustion);
  const [translatestitle, settranslatestitle] = useState("");
  const [isTranslated, setisTranslated] = useState(false);
  let navigate = useNavigate();

  const times = new Date(time);

  //commeent details sections //
  //console.log((_id,"_id");

  const handleCommentSection = ({
    qustion,
    image,
    commentcount,
    tag,
    index,
    likes,
    contant,
    _id,
  }) => {
    navigate(`/forum/${_id}`, {
      state: {
        hasClicked: hasClicked,
        Totallikes: Totallikes,
        img: image,
        qustion: qustion,
        commentcount: commentcount,
        item: item,
        tag: tag,
        likes: likes,
        contant: contant,
        _id: _id,
      },
    });
  };

  // handlefunction like and unlike api //
  const handlelike = async () => {
    try {
      const result = await likeandunlikeservice({
        threadId: _id,
        commentId: null,
      });

      if (result.res.success) {
        // toast.success(result.res.message)
        if (!hasClicked) {
          settotallikes(Totallikes + 1);
        } else {
          settotallikes(Totallikes - 1);
        }
        setHasClicked(!hasClicked);
        // formservicedata()
      }
    } catch (error) {
      //console.log((error)
    }
  };

  //update//
  useEffect(() => {
    if (userDetails.includes(_id)) {
      setHasClicked(true);
    } else {
      setHasClicked(false);
    }
    saveForm &&
      saveForm.filter((id) => {
        if (id === _id) {
          setbookmarkClicked(true);
        }
      });
  }, [userDetails, saveForm]);

  const handlebookmark = async () => {
    try {
      const result = await bookmarkservice({
        threadId: _id,
      });
      getSavedForumAPiData();
      if (result.res.success) {
        setbookmarkClicked(!bookmarkClicked);
        toast.success(result.res.message);
      }
    } catch (error) { }
  };

  // for translate

  const handleChangeButton = () => {
    setisTranslated(!isTranslated);

    if (translatestitle === "") {
      let toLang = translateLanguage; // translate to norwegian
      let text = `${qustiontitle}`;
      let url = TRANSLATE_URL;
      url += "&q=" + encodeURI(text);
      url += `&target=${toLang}`;
      const token = localStorage.getItem("token");

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`, // notice the Bearer before your token
        },
      })
        .then((res) => res.json())
        .then((response) => {
          settranslatestitle(response.data.translations[0].translatedText);
        })
        .catch((error) => {
          //console.log(('There was an error with the translation request: ', error)
        });
    }
  };

  //update//
  useEffect(() => {
    saveForm &&
      saveForm.filter((id) => {
        if (id === _id) {
          setbookmarkClicked(true);
        }
      });
    //console.log((userDetails)
  }, [userDetails, saveForm]);

  return (
    <>
      <div className="my-2">
        <div className="card">
          <div className="card-body">
            <div className="flex justify-between items-center ">
              <div
                className="cursor-pointer"
                onClick={() =>
                  handleCommentSection({
                    qustion,
                    image,
                    commentcount,
                    tag,
                    index,
                    likes,
                    contant,
                    _id,
                  })
                }
              >
                <h5 className="card-title activecardhead ">
                  {isTranslated
                    ? translatestitle === ""
                      ? qustiontitle
                      : translatestitle
                    : qustiontitle}
                </h5>
                <p class="card-text formdetailstags mb-2 mt-2 ">
                  {`${t("Tags")}`} : {tag && tag.map((u) => u.name).join(", ")}{" "}
                </p>
              </div>
              <div>
                <div className="flex justify-end items-center">
                  <span
                    className="card-text formdetailstags mb-2 mt-2 underline text-blue cursor-pointer translateres"
                    onClick={handleChangeButton}
                  >{`${t("Translate")}`}</span>
                </div>
                <img
                  className="card-img-bottoms cursor-pointer"
                  src={`${BASE_URL_IMG}${image}` || socialimage}
                  alt="Card cap"
                  onClick={() =>
                    handleCommentSection({
                      qustion,
                      image,
                      commentcount,
                      tag,
                      index,
                      likes,
                      contant,
                      _id,
                    })
                  }
                />
              </div>
            </div>

            <div className="flex mt-2 active_socialbtn justify-between ">
              <p className="mr-2 flex items-center h-5 cursor-pointer  formlikeres">
                {" "}
                <motion.div
                  whileTap={{ scale: 4 }}
                  transition={{ duration: 0.5 }}
                  onClick={() => handlelike()}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={hasClicked ? diamond : diamond2}
                    alt=""
                    className="h-4"
                  />
                </motion.div>
                <span className="px-2">
                  {Totallikes} {Totallikes > 1 ? "Likes" : "Like"}
                </span>
              </p>

              <p class="card-text ">{moment(times).format("ll")}</p>
              <p className=" flex items-center h-5">
                <img src={hr} alt="" className="h-4" />{" "}
                <span className="px-2">
                  <TimeAgo date={times} />
                </span>{" "}
              </p>
              <Shareicon link={window.location.origin + `/forum/${item?._id}`} />
              
              <p className=" flex items-center h-5">
                <motion.div
                  whileTap={{ scale: 2 }}
                  transition={{
                    duration: 0.5,
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                  }}
                  onClick={() => handlebookmark()}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={bookmarkClicked ? socialmark2 : socialmark}
                    alt=""
                    className="h-4"
                  />{" "}
                </motion.div>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveForm;
