import React, { useContext, useEffect } from "react";

import { BASE_URL_IMG, galleryServiceapi, SearchGalleryapi } from "../../Utils/services";
import { useState } from "react";
import Gallerycard from "./Gallerycard";
import GalleryHead from "./GalleryHead";
import { Globalinformation } from "../../Common/MainSectionProvider";
import SearchGalary from "../../Common/SearchHeader/SearchGalary";
import { Spinner } from "reactstrap";
import { useParams } from "react-router";

const GallerySocial = ({ galaryfilter, tafFilter, setGalleryFilter, setFilterTag, selectedcourse, setselectedCourse }) => {
  const [galleryData, setGalleryData] = useState([]);

  const country = localStorage.getItem("country");
  const countryPost = localStorage.getItem("countrypost");
  const [userData, setUserData] = useState([]);
  const { fileteValue, tagsearch } = useContext(Globalinformation);
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)

  const { id } = useParams()

  // useEffect(() => {
  //   setCountrypostallow(localStorage.getItem("countrypostallow"))
  //   //console.log((localStorage.getItem("countrypostallow"), "localStorage")
  // }, [])

  const times = new Date();
  const galleryservicedata = async () => {
    try {
      if (selectedcourse.length > 0) {
        const result = await galleryServiceapi({
          country: country,
          name: selectedcourse,
        });
        if (id) {
          setGalleryData(result.res.data.posts.filter(item => item._id === id));
        } else {
          setGalleryData(result.res.data.posts);
        }
        if (result.res.data.userDetails.likedPosts.length > 0) {
          setUserData(result.res.data.userDetails.likedPosts);
        }

      } else if (galaryfilter) {
        const result = await SearchGalleryapi({
          query: galaryfilter,
          country: "India",
        });
        if (id) {
          setGalleryData(result.res.data.posts.filter(item => item._id === id));
        } else {
          setGalleryData(result.res.data.posts);
        }

        if (result.res.data.userDetails.likedPosts.length > 0) {
          setUserData(result.res.data.userDetails.likedPosts);
        }

      } else {
        const result = await galleryServiceapi({
          country: country,
          name: tagsearch,

        });
        if (id) {
          var posts = result.res.data.posts.filter(item => item._id === id)
          setGalleryData(posts);
        } else {
          setGalleryData(result.res.data.posts);
        }


        setGalleryData(result.res.data.posts);
        setLoading(false)
        //console.log((result.res.data, "data");
        if (result.res.data.userDetails.likedPosts.length > 0) {
          setUserData(result.res.data.userDetails.likedPosts);
        }
        //console.log((result.res.data, "res data");
      }
    } catch (error) { }
  };

  //update//

  // useEffect(() => {
  //   galleryservicedata();
  // }, []);
  useEffect(() => {
    galleryservicedata();
  }, []);

  useEffect(() => {
    galleryservicedata();
  }, [galaryfilter, page]);

  useEffect(() => {
    galleryservicedata();
  }, [selectedcourse]);


  //console.log((countryPost, "postallow");

  const HeadDetails = (countryPost) => {
    const data = JSON.parse(countryPost);
    if (data == true) {
      return <GalleryHead galleryservicedata={galleryservicedata} />;
    } else {
      return null;
    }
  };

  const handlescroll = () => {
    // //console.log(("Height :", document.documentElement.scrollHeight)
    // //console.log(("top :", document.documentElement.scrollTop)
    // //console.log(("height:", window.innerHeight)
    if (window.innerHeight + document.documentElement.scrollTop + 1 > document.documentElement.scrollHeight) {
      setPage(prev => prev + 1)
      if (galleryData[galleryData.length - 1]) {
        setLoading(true)
      }
      else {
        setLoading(false)
      }


    }

  }

  // useEffect(()=>{
  //   window.addEventListener("scroll",handlescroll)

  //   return () => window.removeEventListener("scroll",handlescroll)

  // },[])




  //console.log((galleryData,"check gallary data")

  return (
    <div className="pt-10">
      {HeadDetails(countryPost)}

      <div className="mobilesearch">
        <SearchGalary setFilterForum={setGalleryFilter} setTagFilter={setFilterTag} />
      </div>


      {galleryData &&
        galleryData.map((item, index) => (
          <Gallerycard
            item={item}
            index={index}
            galleryservicedata={galleryservicedata}
            userDetails={userData}
          />
        ))}

      <div>
        {loading && <div className="loaderfixed">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>}
      </div>


    </div>
  );
};

export default GallerySocial;
