import React, { useContext, useEffect, useState } from 'react'
import diamond from "../Assets/images/Social/dimond2.svg"
import diamond2 from "../Assets/images/Social/diomand3.svg";

import deleteIcon from "../Assets/images/Social/delete.svg"
import { ModalContext } from '../Context';
import DeleteFormComment from '../ModalTemplate/DeleteFormComment';
import FroumEditComment from '../ModalTemplate/FroumEditComment';
import { FormReplyLikes, likeandunlikeservice } from '../Utils/services';
import ForumReplyEdit from '../ModalTemplate/ForumReplyEdit';
import DeleteForumReply from '../ModalTemplate/DeleteForumReply';
import ForumReplyReport from '../ModalTemplate/ForumReplyReport';
import { useTranslation } from 'react-i18next';

const SocialReplay = ({id,commentid,handlegetComment,commentdata,userData,like,handleReply,ReplyId,Likes,user,handleChangeButton,handleSubComment,subcomment,index,admin}) => {
    // //console.log(("reply Id",ReplyId)
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [hasClicked, setHasClicked] = React.useState(false);
    const admin_id = localStorage.getItem('adminId');
    const [adminId] =useState(admin_id)
    const { t } = useTranslation(["home"]);
    
  

    const handleModal=(indexvalue)=>{ 
        let tabsValue = indexvalue ;
        const DELETE_USER_COMMENT = <DeleteForumReply id={id} commentid={commentid} handlegetComment={handlegetComment} ReplyId={ReplyId} />;
        const REPORT_USER_COMMENT = <ForumReplyReport /* id={id} commentid={commentid} handlegetComment={handlegetComment} commentdata={commentdata} */ />;
        const EDIT_REPLY =<ForumReplyEdit id={id} commentid={commentid} handlegetComment={handlegetComment} commentdata={commentdata}  ReplyId={ReplyId}   />
        switch (tabsValue) {
            case 0:
               handleModalData(EDIT_REPLY,"sm");
              break;

            case 1:
               handleModalData(DELETE_USER_COMMENT,"sm");
              break;

            case 3:
              handleModalData(REPORT_USER_COMMENT,"sm");
              break;
            case 2:
            handleModalData(REPORT_USER_COMMENT,"sm");
              break;
      
        
      
           default:
            handleModalData("delete","lg");
            break;
        }
      }

      const handlelike = async () => {
        try {
          const result = await FormReplyLikes({
            "threadId":id,
            "commentId":commentid,
            "replyId" :ReplyId      
          })

          if (result.res.success) {
            // toast.success(result.res.message)
            setHasClicked(!hasClicked);
            handlegetComment(id)
          }
        } catch (error) {
          //console.log((error)
        }
    }
  
    useEffect(() => {
     
      Likes && Likes.filter((item) => {    
          if (item.user === adminId) {
            setHasClicked(true);
          }
       })
    
      },[Likes])
  

 //console.log((admin,"cehck id")


  return (
    <React.Fragment>
       <div className="flex mt-2">
              { 
               adminId  !=user ?  
               <>
                {
                 !admin.isAdmin ?<span className="mr-2 text-sm font-normal text-redies cursor-pointer pr-2 " onClick={()=>handleModal(3)} >{`${t('Report')}`}</span> :null
                }
               </>
           
              :
              <span className="mr-2 text-sm font-normal text-blue cursor-pointer pr-2" onClick={()=>handleModal(0)} >{`${t('Edit')}`}</span> 
              }
             
             
             <span className="mr-2 text-sm font-normal  text-blue flex items-center cursor-pointer " onClick={handlelike} ><img src={hasClicked ? diamond : diamond2} alt="" className="h-3 " /> <p className="px-2">{Likes.length}</p> </span>
              
             { 
              adminId  !=user ? 
             <span  className="mr-2  border-b border-blue  text-sm font-normal  text-blue cursor-pointer"  onClick={()=>handleSubComment({commentdata,index})}  > {`${t('Translate')}`}</span>
             :
             <span className="mr-2 text-sm font-normal  text-blue flex items-center cursor-pointer " onClick={()=>handleModal(1)} ><img src={deleteIcon} alt="" className="h-3 " />  </span> 
             }
       </div>
    </React.Fragment>
  )
}

export default SocialReplay