import React, { useContext, useEffect, useState } from "react";
import moment from "moment/moment";
import { AnimatePresence, motion } from "framer-motion";
import socialmark from "../../Assets/images/Social/bookmark3.svg";
import socialmark2 from "../../Assets/images/Social/bookmark2.svg";
import { TRANSLATE_URL, newsFeedBookMark } from "../../Utils/services";
import { toast } from "react-toastify";
import { Globalinformation } from "../../Common/MainSectionProvider";

const NewsCom = ({
  item,
  index,
  timeIcon,
  saveNews,
  searchfilter,
  newsfeed,
}) => {
  const [bookmarkClicked, setbookmarkClicked] = React.useState(false);
  const [news, setNews] = useState("");
  const [isTranslated, setisTranslated] = useState(false);
  const { translateLanguage } = useContext(Globalinformation);
  const handleTranslate = ({ item, index }) => {
    setisTranslated(!isTranslated);
    if (news === "") {
      let toLang = translateLanguage; // translate to norwegian
      let text = `${item.title}`;
      let url = TRANSLATE_URL;
      url += "&q=" + encodeURI(text);
      url += `&target=${toLang}`;
      const token = localStorage.getItem("token");

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`, // notice the Bearer before your token
        },
      })
        .then((res) => res.json())
        .then((response) => {
          setNews(response.data.translations[0].translatedText);
        })
        .catch((error) => {
        });
    }
  };

  const handlebookmark = async (_id) => {
    try {
      const result = await newsFeedBookMark({
        id: _id,
      });
      //   getSavedForumAPiData()

      if (result.res.success) {
        setbookmarkClicked(!bookmarkClicked);
        toast.success(result.res.message);
        newsfeed();
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (saveNews.includes(item?._id)) {
      setbookmarkClicked(true);
    } else {
      setbookmarkClicked(false);
    }
  }, [searchfilter, saveNews]);

  return (
    <div>
      <div className="mt-4" /* onClick={()=>handleNewsPage()} */>
        <div className="bg-white flex justify-between items-center p-3">
          <div className="w-75">
            <span className="text-sm news">{item.publisher}</span>
            <a href={item?.links[0]} target="_blank" rel="noopener noreferrer">
              <h4 className="pr-5 py-2 discriptionnews">
                {isTranslated ? (news === "" ? item.title : news) : item.title}
              </h4>
              {/* {isTranslated && <p>{news}</p>} */}
            </a>
            <div className="flex">
              <span className="text-sm mr-4 text-blue">
                Tags: {item.tags && item.tags.map((u) => u.name).join(", ")}
              </span>
            </div>
            <div className="flex pt-2">
              <img src={timeIcon} alt="" />
              <span className="text-sm ml-2 mr-2 text-gray ">
                {moment(item.date).fromNow()}
              </span>
              <span
                className="text-sm mr-2 ml-3 text-blue underline cursor-pointer"
                onClick={() => handleTranslate({ item, index })}
              >
                Translate
              </span>
            </div>
          </div>
          <div>
            <div className="Newsimage">
              <img src={item.image} alt="img" className="w-100" />
            </div>
            <p className=" flex justify-end h-5 mt-2">
              <motion.div
                whileTap={{ scale: 2 }}
                transition={{
                  duration: 0.5,
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                }}
                onClick={() => handlebookmark(item?._id)}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={bookmarkClicked ? socialmark2 : socialmark}
                  alt=""
                  className="h-4"
                />
              </motion.div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsCom;
