import Events from "./Components/Event";
import ForumPageMain from "./Components/Forum";
import CommentPagemain from "./Components/Forum/commentsection";
import Gallery from "./Components/Gallary";
import GalleryInnerPage from "./Components/Gallary/GallaryInnerPage";
import Gethelp from "./Components/Gethelp";
import Chat from "./Components/Gethelp/Chat";
import Directory from "./Components/Gethelp/Directory";
import GethelpEvent from "./Components/Gethelp/Event";
import NewsFeed from "./Components/Newsfeed";
import Notification from "./Components/Notifications";
import UserProfile from "./Components/Profile";
import ResetPassword from "./Components/Profile/Resetpassword";
import Story from "./Components/Story";
import StoryInnerPage from "./Components/Story/StoryInnnerPage";
import ToolKit from "./Components/Toolkit";
import UserProfiles from "./Components/UserProfile";
import Finacial from "./Resources/Finacial_Litercay";

import LegalProvisons from "./Resources/Legal_Provisions";
import LocalSupport from "./Resources/Local_Support_Structures";
import OccupationalHealth from "./Resources/Occupational_Health_Safety";
import SkillTraing from "./Resources/Skill_Training";
import SkillDetails from "./Resources/Skill_Training/Skill_Inner_page";
import Task from "./Resources/Skill_Training/Skill_Inner_page/Task_page";
import SocialProtection from "./Resources/Social_Protection";



export const routes = [
   {path: "/", component: ForumPageMain},
   {path:"forum/:slug",component:CommentPagemain},
   {path:"gallery",component:Gallery},
   {path:"/gallery/:id",component:GalleryInnerPage},
   {path:"/profile",component:UserProfile},
   {path:"legal-provisions",component:LegalProvisons},
   {path:"local-support-structures",component:LocalSupport},
   {path:"occupational-health-safety",component:OccupationalHealth},
   {path:"social-protection-programs-benefits",component:SocialProtection},
   {path:"financial-literacy",component:Finacial},
   {path:"skills-training",component:SkillTraing},
   {path:"skills-training/details",component:SkillDetails},
   {path:"skills-training/task",component:Task},
   {path:"toolkits",component:ToolKit},
   {path:"notification",component:Notification},
   {path:"newsfeed",component:NewsFeed}, 
   {path:"events",component:Events},
   {path:"stories",component:Story},
   {path:"stories/:id",component:StoryInnerPage},
   {path:"stories/:id",component:StoryInnerPage},
   {path:"gethelp",component:Gethelp},
   {path:"directory",component:Directory},
   {path:"get-help-event",component:GethelpEvent},
   {path:"chat",component:Chat},
   {path:"userprofile",component:UserProfiles},
   {path:"resetpassword",component:ResetPassword},
   // {path:"/glpmeeting/:name",component:AgoraUI}

];