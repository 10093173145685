import React, { useEffect, useRef } from "react";
import Select from 'react-select'



import { useState } from 'react';



import TextField from '@material-ui/core/TextField';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button } from "@material-ui/core";
import { Col, Container, Row } from "reactstrap";
import ImageCropper from "../Common/ImageCrop";
import Crop from "../Common/ImageCrop";
import { addPostGallery, getTagFormService } from "../Utils/services";
import { ModalContext } from "../Context";
import { useContext } from "react";

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]
const GalleryPostModal = ({galleryservicedata}) => {
  const [sendImageFile, setSendImageFile] = useState("");
  const [option,setOption] =useState([])
  const [youtubelink, setYoutubeLink] = useState([]);
  const [postTitle,setPostTilte] =useState("")
  const [selected, setSelected] = useState([]);
  const [textArea, setTextarea] = useState("");
  // Image Crop Is Here
  const [crop, setCrop] = useState({ aspect: 4 / 3 });
  const [src, setSrc] = useState(null);
  const [image, setImage] = useState(null)
  const [result, setResult] = useState(null)
  const [videoValidation, setVideoValidation] = useState("");
  const [mediaType, setMediaType] = useState("visual")
  const [contentType, setContentType] = useState("application/json")
  const profileImage = localStorage.getItem('userImage')
  const [disableButton, setDisableButton] = useState(false);
  const [imgFile, setImgFile] = useState('');
  const modalContext = useContext(ModalContext);
  const { closeModal } = modalContext;



  // const getListofCategory = async () => {
  //   try {
  //     const result = await getListOfTagsAddColletral();

  //     let data = result.data.map(function (item) {
  //       return { label: item.tagsName, value: item.tagsID };
  //     });
  //     setoptions(data);
  //   } catch (error) {
  //   }
  // };


  
  const getTag = async () => {
    try {
      const result = await getTagFormService();
      let optionData = result.res.data.map(function (item) {
              return { label: item.name, value: item.name };
            });
      setOption(optionData)
    
    } catch (error) {
      
    }
  };


  //update //
  useEffect(() => {
    getTag();
  }, []);

   

  const handleImgChangeFile = (e) => {
    setContentType("application/json")
    setMediaType("visual")
    setImgFile(e.target.files[0])
    setSrc(URL?.createObjectURL(e.target.files[0]))
  }

  function getCroppedImg() {
    const canvas = document.createElement("canvas");
    const image = document.getElementById("result");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // canvas.width = crop.width;
    // canvas.height = crop.height;

    var originWidth = crop.width * scaleX;
    var originHeight = crop.height * scaleY;


    // maximum width/height
    var maxWidth = 1200, maxHeight = 1200 / (4 / 3);
    var targetWidth = originWidth,
      targetHeight = originHeight;
    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        targetWidth = maxWidth;
        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      } else {
        targetHeight = maxHeight;
        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      }
    }

    // set canvas size
    canvas.width = targetWidth;
    canvas.height = targetHeight;

    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      targetWidth,
      targetHeight
    );
    const base64Image = canvas.toDataURL("image/jpeg");
    var profile = new Image();
    profile.src = base64Image;
    setResult(profile.src)

    fetch(profile.src)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], profile.src, { type: "image/jpeg" })
        setSendImageFile(file)
      })
  }

 const handleSumbitModal=async(e) =>{
  e.preventDefault()
  const selectArray = selected.map((item) => item.value);

  const postFormData = new FormData();
  postFormData.append("title",postTitle);
  postFormData.append("caption",textArea);
  postFormData.append("media", sendImageFile && sendImageFile);
  postFormData.append("mediaType","photo")  
  postFormData.append("tags",selectArray)
  try {
    const result = await addPostGallery(postFormData);
    if(result.res.success){
      galleryservicedata()
      closeModal()
    }
  } catch (error) {
     //console.error((error)
  }  

 }
 
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h2 className="addgalleryheading">Add Post</h2>
          </Col>
        </Row>
        <form action="" onSubmit={handleSumbitModal} >
          
          <Row className="mt-4">
            <Col md={6}>
              <div className="flex bg-grey p-2" >
                   {/* <img src={avatar} alt="" className="mr-2"/> */}
                   <input type="text" value={postTitle} placeholder="Post Title" className="bg-transparent outline-none"     onChange={(e) => setPostTilte(e.target.value)}   required />
  
              </div>

              <div className="bg-grey p-2 mt-4" >
                   <Select 
                    options={option}
                    closeMenuOnSelect={false}
                    isMulti
                    onChange={(e)=>setSelected(e)}
                    value={selected}
                    className="postselect"
                    placeholder="Tags"           
                    
                    />
  
              </div>

              <div className="flex bg-grey p-2 mt-4" >              
                   {/* <input type="text-area"   /> */}
                   <textarea value={textArea} id="w3review" placeholder="Share your views" name="w3review" rows="8" cols="50" className="bg-transparent textareabtn outline-none" required  onChange={(e)=>setTextarea(e.target.value)} />
              </div>


              <button className="postbtn" type="submit">POST</button>
              
            </Col>
            <Col md={6}>
               <h2 className="addgalleryheading pb-2">Add media</h2>           
                 <div className="relative w-auto overflow-hidden">
                      <TextField
                            id="profilephoto"
                            label="Add Photo"
                            value={result || ""}
                            className="w-full text-xs"
                          />
                          <input
                            disabled={disableButton}
                            type="file"
                            accept="image/*"
                            className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                            name="image-file"
                            onChange={handleImgChangeFile}
                          />
                          <div className="floating-box" >+</div>
                        </div>
                        {
                         src &&
                         <div className="col-6">
                         <ReactCrop crop={crop} onChange={setCrop}>
                         <img id="result" src={src} alt="description" onLoad={setImage} />
                         </ReactCrop>
                     
                         <Button
                          variant="contained"
                          onClick={getCroppedImg}
                         >
                          Crop Image
                        </Button>
                      </div>
                    }
                    
               
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
};

export default GalleryPostModal;
