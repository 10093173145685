import React, { useContext, useEffect, useRef } from "react";
import avatar from "../../Assets/images/gallery/avtar.png";
import manu from "../../Assets/images/gallery/Menu.svg";
import Comment from "../Forum/commentsection/Comment";
import {
  addCommentGallery,
  BASE_URL_IMG,
  galleryDeletePost,
  galleryLikeapi,
  galleryServiceapi,
  getBlockUserData,
  getGalleryComments,
  TRANSLATE_URL,
} from "../../Utils/services";
import { useState } from "react";

import { toast } from "react-toastify";
import InputText from "../../Common/InputText";
import Social from "../../Common/Social";

import deleteIcon from "../../Assets/images/Social/delete.svg";
import editIcon from "../../Assets/images/Social/editicon.svg";
import report from "../../Assets/images/Social/report.svg";
import DeleteModal from "../../ModalTemplate/DeleteModal";
import { ModalContext } from "../../Context";
import ReportModal from "../../ModalTemplate/ReportModal";
import GalleryEditPost from "../../ModalTemplate/GalleryEditPost";
import platfromadmin from "../../Assets/images/Social/plateformadmin.png";
import { Spinner } from "reactstrap";
import VerifyNumber from "../../ModalTemplate/VerifyNumber";
import { useTranslation } from "react-i18next";
import { Globalinformation } from "../../Common/MainSectionProvider";
import { useHistory, useNavigate } from "react-router-dom";
import Swiper from "swiper";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import TexttoSpeach from "../TexttoSpeach";
const Gallerycard = ({ item, index, galleryservicedata, userDetails }) => {
  const [qusTion, setQustion] = useState(item.title);
  const [content, setContent] = useState(item.caption);
  const [TranslatedqusTion, setTranslatedQustion] = useState("");
  const [Translatedcontent, setTranslatedContent] = useState("");
  const [isTranslated, setisTranslated] = useState(false);
  const [showcomment, setshowcomment] = useState(false);
  const [hasClicked, setHasClicked] = React.useState(false);
  const [readMore, setReadMore] = React.useState(true);
  const [commentdata, setCommentdata] = useState([]);
  const [moreComment, setMoreComment] = useState(true);
  const [addcomment, setAddcomment] = useState();
  const admin_id = localStorage.getItem("adminId");
  const IsVerify = localStorage.getItem("phoneverification");
  const [adminId] = useState(admin_id);
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const { translateLanguage } = useContext(Globalinformation);
  const [usersDataName, setUserData] = useState([]);
  const [userReply, setUserReply] = useState([]);
  const [blockData, setBlockData] = useState([]);
  const { t } = useTranslation(["home"]);
  const [loader, setLoader] = useState(false);

  const times = new Date(item.date);
  //console.log((item.title, 'post')

  //like and unlike gallery function and api //

  const handleGetComment = async (posstId) => {
    try {
      const result = await getGalleryComments({
        id: item._id,
      });
      //console.log((result.res.data, 'coomment')

      setCommentdata(result.res.data.comments);
      setUserData(result.res.data.users);
      setUserReply(result.res.data.user_reply);
    } catch (error) {
      //console.log((error)
    }
  };

  const handleMoreComment = () => {
    setMoreComment(false);
  };

  const handleMore = () => {
    setReadMore(false);
  };

  const handlelike = async () => {
    try {
      const result = await galleryLikeapi({
        postId: item._id,
        commentId: null,
      });

      if (result.res.success) {
        // toast.success(result.res.message)
        setHasClicked(!hasClicked);
        galleryservicedata();
        // formservicedata()
      }
    } catch (error) {
      //console.log((error)
    }
  };

  const handleVeriFication = () => {
    let VERIFYPHONE = <VerifyNumber />;
    handleModalData(VERIFYPHONE, "sm");
  };

  const handleAddcomment = async (event) => {
    event.preventDefault();
    if (addcomment && addcomment.length > 0) {
      if (IsVerify == "true") {
        setLoader(true);
        try {
          const result = await addCommentGallery({
            postId: item._id,
            comment: addcomment,
          });
          galleryservicedata();
          handleGetComment();
          setAddcomment("");
          setLoader(false);
        } catch (error) {
          setLoader(false);
          //console.log((error)
        }
      } else {
        handleVeriFication();
      }
    }
  };

  const handlepostModal = (indexvalue) => {
    let tabsValue = indexvalue;
    let POST_DELETE_MODAL = (
      <DeleteModal
        galleryservicedata={galleryservicedata}
        postId={item._id}
        handleGetComment={handleGetComment}
      />
    );
    let POST_REPORT = (
      <ReportModal galleryservicedata={galleryservicedata} postId={item._id} />
    );
    let POST_EDIT = (
      <GalleryEditPost
        postId={item._id}
        captions={item.caption}
        galleryservicedata={galleryservicedata}
        postTitle={item.title}
      />
    );

    switch (tabsValue) {
      case 0:
        handleModalData(POST_REPORT, "sm");
        break;

      case 1:
        handleModalData(POST_DELETE_MODAL, "sm");
        break;
      case 2:
        handleModalData(POST_EDIT, "sm");
        break;

      default:
        handleModalData("Modal not found", "sm");
        break;
    }
  };

  useEffect(() => {
    // userDetails && userDetails.filter((id) => {
    //   if (id ===item._id) {
    //     setHasClicked(true);
    //   }
    // })

    if (userDetails.includes(item._id)) {
      setHasClicked(true);
    } else {
      setHasClicked(false);
    }
  }, [userDetails]);

  useEffect(() => {
    handleGetComment();
  }, []);

  function getText(html) {
    var tmp = document.createElement("div");
    tmp.innerHTML = html;

    return tmp.textContent || tmp.innerText;
  }
  const slides = item?.media?.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} />
      </CarouselItem>
    );
  });
  const handleChangeButton = () => {
    setisTranslated(!isTranslated);
    if (TranslatedqusTion === "" || Translatedcontent === "") {
      let toLang = translateLanguage; // translate to norwegian
      let text = `${qusTion}`;
      let textContent = `${getText(content)}`;
      let url = TRANSLATE_URL;
      url += "&q=" + text;
      url += "&q=" + textContent;
      url += `&target=${toLang}`;
      const token = localStorage.getItem("token");

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`, // notice the Bearer before your token
        },
      })
        .then((res) => res.json())
        .then((response) => {
          setTranslatedQustion(response.data.translations[0].translatedText);
          setTranslatedContent(
            response.data.translations[1].translatedText,
            "data"
          );
        })
        .catch((error) => {
          //console.log(('There was an error with the translation request: ', error)
        });
    }
  };

  //  //console.log(("avatar check",item )

  //  //console.log((item,"dataname")

  const handleBLockData = async () => {
    //console.log(('check log data')
    const result = await getBlockUserData(admin_id);
    setBlockData(result?.res?.data);
    // //console.log((result, 'block')
  };

  useEffect(() => {
    handleBLockData();
  }, []);

  //console.log((blockData, 'user block')
  //console.log((commentdata, 'comentdata')

  const handlecomment = () => {
    setshowcomment(!showcomment);
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === item?.media?.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? item?.media?.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  const navigate = useNavigate()
  return (
    <section className="mt-4" key={index} style={{cursor:"pointer"}} onClick={()=>navigate(`/gallery/${item._id}`)}>
      <div class="card bg-white rounded-xl  ">
        <div class="card-body">
          <div className="flex justify-between items-center avtar">
            <div className="flex items-center">
              <img
                src={
                  item.postedByAdmin
                    ? platfromadmin
                    : `${BASE_URL_IMG}/user/${item.postedBy.id}/${item.postedBy.id}.jpeg` ||
                    avatar
                }
                alt=""
                className="h-12 rounded-full"
              />

              <h3 className="ps-2 ">
                {item.postedByAdmin ? "Admin" : item.postedBy.name}
              </h3>
            </div>

            <div className="dropdown">
              {!item.postedByAdmin && (
                <button className="dropbtn d-flex align-items-center ">
                  <img src={manu} alt="" />
                </button>
              )}

              {admin_id == item.postedBy.id ? (
                <div className="dropdown-content  pl-3 pr-2 py-2 ">
                  <div
                    href="#"
                    className="text-center cursor-pointer  mb-1 mt-2 pb-1 flex items-center "
                  >
                    <img src={editIcon} alt="" />
                    <span className="ml-2" onClick={() => handlepostModal(2)}>
                      Edit Post
                    </span>
                  </div>
                  <p className="text-center mb-2 mt-2 cursor-pointer flex items-center ">
                    <img src={deleteIcon} alt="" />
                    <span className="ml-2" onClick={() => handlepostModal(1)}>
                      Delete post
                    </span>
                  </p>
                </div>
              ) : (
                <div className="dropdown-content  pl-3 pr-2 py-2 ">
                  <div className="text-center cursor-pointer  mb-1 mt-2 pb-1 flex items-center ">
                    <img src={report} alt="" />
                    <span
                      className="ml-2"
                      onClick={() => handlepostModal(0)}
                    >{`${t("Report")}`}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mt-3">
            {/* <img
              src={`${BASE_URL_IMG}${item?.media[0]?.link}`}
              className="rounded-lg w-100"
              alt=""
              loading="lazy"
            /> */}
            {/* <div
              id="carouselExampleControls"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="1"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="2"
                ></li>
              </ol>
              <div class="carousel-inner">
                {item?.media?.map((item, i) => {
                  return (
                    <div className="carousel-item" key={i}>
                      <img
                        src={`${BASE_URL_IMG}${item?.link}`}
                        className="rounded-lg w-100"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  );
                })}
                {item?.media?.map((item, i) => {
                  return (
                    <div className="carousel-item" key={i}>
                      <img
                        src={`${BASE_URL_IMG}${item?.link}`}
                        className="rounded-lg w-100"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  );
                })}
                {item?.media?.map((item, i) => {
                  return (
                    <div className="carousel-item" key={i}>
                      <img
                        src={`${BASE_URL_IMG}${item?.link}`}
                        className="rounded-lg w-100"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  );
                })}{" "}
              </div>
              <a
                class="carousel-control-prev"
                href="#carouselExampleControls"
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                href="#carouselExampleControls"
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div> */}
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                {item?.media?.map((item, i) => {
                  return (
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to={i + 1 === item.length ? 0 : i}
                      class={`${i + 1 === 1 && "active"}`}
                      aria-current="true"
                      aria-label={`"Slide ${i + 1}`}
                    ></button>
                  );
                })}
              </div>
              <div class="carousel-inner">
                {item?.media?.map((item, i) => {
                  return (
                    <div class={`carousel-item ${i + 1 === 1 && "active"}`}>
                      <img
                        src={`${BASE_URL_IMG}${item?.link}`}
                        class="d-block w-100"
                        alt="..."
                      />
                    </div>
                  );
                })}
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <Social
            handlelike={handlelike}
            item={item}
            times={times}
            hasClicked={hasClicked}
            handlecomment={setshowcomment}
            showcomment={showcomment}
          />

          <div className="flex justify-flex-end items-center" style={{}}>

            <div>
              <h5 className="cardhead mt-2">
                {isTranslated
                  ? TranslatedqusTion === ""
                    ? qusTion
                    : TranslatedqusTion
                  : qusTion}{" "}
                <button
                  className="text-blue text-sm cursor-pointer"
                  onClick={handleMore}
                ></button>
              </h5>

              <div
                dangerouslySetInnerHTML={{
                  __html: isTranslated
                    ? Translatedcontent === ""
                      ? content
                      : Translatedcontent
                    : content,
                }}
              />
            </div>


            {/* <div className="storytranslatebtn-v2"> */}
            {/* <button onClick={handleChangeButton}>{`${t("Translate")}`}</button> */}
            <TexttoSpeach text={isTranslated
              ? TranslatedqusTion === ""
                ? qusTion
                : TranslatedqusTion
              : qusTion}
              discription={
                isTranslated
                  ? Translatedcontent === ""
                    ? content
                    : Translatedcontent
                  : content
              } />
            {/* </div> */}
          </div>






          <div className="flex justify-between items-center">
            <p class="card-text formdetailstags mb-2 mt-2 ">
              {`${t("Tags")}`}:{" "}
              {item.tag && item.tag.map((u) => u.name).join(", ")}{" "}
            </p>
            <span
              className="underline text-blue cursor-pointer"
              onClick={handleChangeButton}
            >{`${t("Translate")}`}</span>
          </div>
          {showcomment && (
            <>
              <InputText
                handleAddcomment={handleAddcomment}
                setAddcomment={setAddcomment}
                addcomment={addcomment}
              />
              <h6 className="mt-2  text-blue font-bold text-sm">
                View {item && item.comments > 2 && "all"} {item.comments}{" "}
                {item && item.comments > 2 ? "comments" : "comment"}
              </h6>
              {loader ? (
                <Spinner type="grow" color="warning" children={false} />
              ) : (
                <>
                  {moreComment ? (
                    <>
                      {commentdata &&
                        commentdata.slice(0, 2).map((items, i) => (
                          <>
                            <div
                              className={
                                blockData.includes(items?.user) && `hidden`
                              }
                            >
                              <div
                                className="bg-grey mt-2 w-11/12  p-3 rounded-md commentsectionresponive "
                                key={i}
                              >
                                <Comment
                                  userReply={userReply}
                                  usersDataName={usersDataName}
                                  item={items}
                                  postId={item._id}
                                  handleGetComment={handleGetComment}
                                  galleryservicedata={galleryservicedata}
                                />
                              </div>
                            </div>
                          </>
                        ))}
                    </>
                  ) : (
                    <>
                      {commentdata &&
                        commentdata.map((items, i) => (
                          <div
                            className={
                              blockData.includes(items?.user) && `hidden`
                            }
                          >
                            <div
                              className="bg-grey mt-2 w-11/12  p-3 rounded-md commentsectionresponive  "
                              key={i}
                            >
                              <Comment
                                userReply={userReply}
                                usersDataName={usersDataName}
                                item={items}
                                postId={item._id}
                                handleGetComment={handleGetComment}
                                galleryservicedata={galleryservicedata}
                              />
                            </div>
                          </div>
                        ))}
                    </>
                  )}

                  {moreComment ? (
                    <>
                      {commentdata.length > 2 ? (
                        <p
                          className="mt-2  text-blue font-bold text-sm cursor-pointer  hover:underline"
                          onClick={handleMoreComment}
                        >
                          View {commentdata.length - 2} more comments
                        </p>
                      ) : null}
                    </>
                  ) : null}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Gallerycard;
