import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router/dist';


const PrivacyPolicy = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(['home'])
    return (
        <div className='container py-5'>
            <div className="row">
                <div className="col-md-11 mx-auto">
                    <div className="privacyPolicyMain">
                    <div className="backToLogin">
                        <Link to="/login" onClick={() => navigate(-1)}>Back</Link>
                    </div>
                        <div className="privacyPolicyContent">
                            <h4>{`${t('Privacy policy')}`}</h4>
                            <p>We respect the privacy of the users of Connect mobile app and website. This Privacy Statement is intended to lay down the practices we follow for respecting the privacy preferences of the people visiting Connect App and website https://glp-connect.org. </p>
                            <p>The consortium comprising Solidar Suisse, Pragya and the British Institute of International and Comparative Law (BIICL) strive to adhere to and comply with the principles of (GDPR) (EU) 2016/679 (General Data Protection Regulation), The Data Protection Act, 1998 and The Privacy and Electronic Communications Regulations, 2003. The consortium makes sure that the platform holding personal data has the required processes in place to protect it. </p>
                        </div>
                        <div className="privacyPolicyContent">
                            <h4>Public Interest</h4>
                            <p>The Connect mobile app and website collect limited data during the registration process in the public interest. This data is collected to ensure that the consortium is fair in its practices by carrying out equality and diversity monitoring or safeguarding the well-being of people with whom the consortium works. The consortium reserves the right to monitor the content interactions between and among users of the platform and to remove any content they deem objectionable.</p>
                        </div>
                        <div className="privacyPolicyContent">
                            <h4>Information Security</h4>
                            <p>The individual can choose whether she/he/they want to use the app and share the basic information for registration. These are only visible to and used for verification by the Platform Coordinators. User mobile numbers would be encrypted using 2-way encryption. All users are required to create an Alias. After user verification by the Platform Coordinators, the user data would be only linked to the Alias, country, and livelihood sector. No identifiable details will be displayed on the platform or anywhere in the public domain.  All Admin profiles and Coordinator profiles would have two-factor authentication. The users can delete her/his/their account at any point of time and we will honour the same. </p>
                            <p>The consortium members have in place the necessary security mechanisms and only designated employees have access to user information. The information users provide on the registration page is encrypted. Anti-virus for user and Admin side file upload is integrated. The web platform and database use SSL (TLS 1.2; Transport Layer Security). Volume encryption on the data server is implemented. Server patching (adding fixes and updates) is done by the IT support team twice a month to identify and address any vulnerability.</p>
                        </div>
                        <div className="privacyPolicyContent">
                            <h4>Service Providers</h4>
                            <p>We may employ third-party companies and individuals due to the following reasons:</p>
                            <p>To facilitate our Service;</p>
                            <p>To provide the Service on our behalf;</p>
                            <p>To perform Service-related services; or</p>
                            <p>To assist us in analysing how our Service is used.</p>
                            <p>We want to inform our Service users that these third parties may have access to personal information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose. Some of the third-party service providers are:</p>
                            <ol>
                                <li>Google Analytics</li>
                                <li>Google Firebase & Crashalytics</li>
                                <li>SMS Gateway</li>
                            </ol>
                        </div>
                        <div className="privacyPolicyContent">
                            <h4>Links to Other Sites</h4>
                            <p>Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                        </div>
                        <div className="privacyPolicyContent">
                            <h4>Sharing personal information</h4>
                            <p>Except for statutory requirements defined by the respective Country laws, the platform will never share user information.</p>
                        </div>
                        <div className="privacyPolicyContent">
                            <h4>Cookie Policy</h4>
                            <p>Cookies are pieces of electronic information that will be sent when users log onto Connect mobile app and website. These enable the platform to recognize a user when she/he/they visits next. The users can configure their browser so that it responds to cookies the way they deem fit. For example, the users may want to accept all cookies, reject them all or get notified when a cookie is sent. </p>
                        </div>
                        <div className="privacyPolicyContent">
                            <h4>Information shared on the mobile app</h4>
                            <p>Users are informed during the registration or onboarding process of the exact information that the platform collects and prompted to provide that information or grant access to it for specified purposes such as push notifications. </p>
                            <p>A registered user may be able to post or submit photos, information, comments, and other content through the Connect app and website. The user accepts responsibility for its accuracy, appropriateness and legality. It may be viewed, read, or used by others, whereas, only the Alias name, country and livelihood sector of the users would be visible.</p>
                            <p>The content on this platform is for educative and informative purposes only. While the consortium endeavours to keep the information up to date and correct, they make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability with respect to the platform content or the information and related graphics on the platform for any purpose.
                                Through this platform the users will be able to access links to other websites owned and operated by third parties (newsfeed, events). The consortium has no control over the information provided by these websites and bear no responsibility for the accuracy of the content and the views expressed therein.
                            </p>
                            <p>Use of Connect app and website may result in the assignment of unique identifiers (IDs). Some of these IDs may be generated by the user device and some of these are assigned to the user device by the platform. These may be in the form of numbers or combinations of letters, numbers, characters, or codes.
                                The user device may send the platform some or all of the following:
                            </p>
                            <ul>
                                <li> <span>DEVICE ID </span>(All users). A Device ID is a combination of letters and numbers that is unique to the device the user is using. The Device ID identifies the device, regardless of who is using the device. On many devices, the Device ID is provided by the device manufacturer and stored permanently in the memory of the device. Depending on how the manufacturer configured the device, the user may or may not be able to change it. </li>
                                <li><span>CAMERA </span>(Only Southeast Asia users). A camera is a unit in the mobile device which helps the device take pictures of persons, objects and places. The user might require taking pictures from their device and sending the information to the platform. The pictures are clicked only and only when the users decide to take it. </li>
                                <li><span>GALLERY FILES </span>(Only Southeast Asia users). Gallery is a folder which stores all the pictures and videos present in a device, at one place. The device will send the platform pictures taken from the device. This only works when the user chooses to share the information with the platform. The user has complete control over what information will be shared with the platform from their gallery. </li>
                            </ul>
                        </div>
                        <div className="privacyPolicyContent">
                            <h4>Changes to this policy</h4>
                            <p>This policy was last updated in December 2022. Solidar Suisse, Pragya and British Institute of International and Comparative Law (BIICL) may amend or update this policy at any time to take account of any changes to data protection law or other legislation. When further updates to the policy are made they will be posted on this page. The consortium would not make any changes to the way they use user data without asking the users first. The subsequent submission of personal information by the users will be deemed to signify their acceptance of the updates. </p>
                            <p>The users may reach out to the team if they have any queries about any changes made to the policy. Please send an email to <a href="mailto:info@glp-connect.org">info@glp-connect.org</a> </p>
                        </div>
                        <div className="privacyPolicyContent">
                            <h4>Indemnity</h4>
                            <p>The user agrees to indemnify, defend and hold harmless Solidar Suisse, Pragya and the British Institute of International and Comparative Law, their directors and trustees, officers, employees, consultants, agents, and affiliates, from any and all third-party claims, liability, damages and/or costs (including, but not limited to, legal fees) arising from their use of this app and website. Every effort is made to keep the platform up and running smoothly. The consortium takes no responsibility for, and will not be liable for, the app/website being temporarily unavailable due to technical issues beyond their control.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy