import { HMSPrebuilt } from "@100mslive/roomkit-react";
import logo from "../../Assets/images/mobileicon.png"
import { useParams } from "react-router";
const UIkit = () => {
  const userName = localStorage.getItem("aliasName");
  const User_id = localStorage.getItem("adminId");
  const { slug } = useParams();
  return (
    <div style={{ height: "100vh" }}>
      <HMSPrebuilt
        roomCode={slug}
        options={{ userName: userName, userId: User_id }}
      />
    </div>
  );
};

export default UIkit;
