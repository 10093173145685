import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

// localStorage.setItem('lng', 'en');
const language = localStorage.getItem('lng')

i18n.use(Backend)
	.use(initReactI18next)
	.init({
		backend: {
			loadPath: "/translation/{{lng}}.json",
		},
		fallbackLng: `${language ? language : 'en'}`,
		debug: false,
		ns: ["common", "home", "profile"],
		interpolation: {
			espaceValue: false,
			formatSeparator: ",",
		},
		// react: {
		// 	wait: true,
		// },
	});

export default i18n;

  