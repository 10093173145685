import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Container, Row, Col, Spinner } from "reactstrap";
import SearchFilter from '../../Common/SearchFilter';
import SearchTool from '../../Common/SearchHeader/SearchTool';
import { DownloadResourceCount, getCategary, getResource, ViewResource } from '../../Utils/services';
import Legalmain from '../Legal_Provisions/Legalmain';

const SocialProtection = () => {
  const [getCategaryId,setCategoryId] =useState("")
  const [loader, setloader] = useState(false);
  const [filter,setFilter] =useState("")


  const [pdfData,setPdfData] =useState([])
  const [videoData,setvideoData] =useState([])

  const getCetegaryeData= async ()=>{
    setloader(true);
    const result = await getCategary()
    const _getcategaryFilter =result.res.filter((item)=>item.name==="Social protection programs and benefits")
    setloader(false);
    setCategoryId(_getcategaryFilter[0]._id)
  }
 

  useEffect(()=>{
    getCetegaryeData()
  },[])



  const getResourceData=async(id)=>{
    const result = await getResource({
      "id":id
    });
    setPdfData(result.res.pdfdata)
    setvideoData(result.res.videodata)

   }

   const HandaleViewCount =async(item) =>{
     await ViewResource({
      "id":item._id
    }
   )  
   }

   const HandleDownloadCount =async(item) =>{
    await DownloadResourceCount( {   
       "id":item._id
      }
    )

  }
 
  useEffect(()=>{
    if(getCategaryId){
      getResourceData(getCategaryId)
    }
  },[getCategaryId])
  return (
    <Container>
    <Row>
       <div className="mobilesearch mt-20">
        <SearchTool setFilterForum={setFilter} />
       </div>
      <Col lg={7} className="skillmain"  >
      {loader ? (
            <div className="mt-20 flex justify-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Legalmain HandleDownloadCount={HandleDownloadCount} HandaleViewCount={HandaleViewCount} pdfData={pdfData} videoData={videoData} filter={filter}  />
          )}   
      </Col>
      <Col lg={5} className="skillsidebar">
       {/* <Filter setGalleryFilter={setFilter} /> */}
       <SearchFilter setGalleryFilter={setFilter}  />

      </Col>
    </Row>
  </Container>
  )
}

export default SocialProtection ;