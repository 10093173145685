import React, { useContext } from 'react'
import searchimage from "../../Assets/images/gallery/searchimage.svg";
import { ModalContext } from '../../Context';
import GalleryPostModal from '../../ModalTemplate/GalleryPostModal';




const GalleryHead = ({galleryservicedata}) => {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const userNameID = localStorage.getItem("adminId");


 const handlePostGallery = ( )=>{
  const POST_MODAL= <GalleryPostModal galleryservicedata={galleryservicedata}   />
  handleModalData(POST_MODAL,"lg");
    
 }


  return ( 
     <div class="card bg-white rounded-xl ">
        <div class="card-body">
          <div className="flex justify-between items-center ">
            <div>
              <img src={`https://d1v4waqh4od8qe.cloudfront.net/user/${userNameID}/${userNameID}.jpeg`} className="h-9" alt="" />
            </div>
            <div className="border p-2 my-3 rounded-xl search_input cursor-pointer " onClick={handlePostGallery}>
              <span>write Post</span>
               {/* <input type="text" placeholder="write text" onClick={handlePostGallery}/> */}
            </div>
          </div>
        </div>
      </div>
  )
}

export default GalleryHead