import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../Context";
import Event from "../../ModalTemplate/Event";
import EventHelp from "../../ModalTemplate/EventHelp";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { GetMeetingCode } from "../../Utils/services";

const EventMain = ({ showEvent, titleMonth }) => {
  const [ON, setON] = React.useState(false);
  const [showEventDetailsData, setShowEventDetailsData] = useState([]);
  const [sendDate, setSendDate] = useState("");
  const [startDate, setStartDate] = useState([]);
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const navigate = useNavigate();
  const formatDate1 = (date) => {
    const _date = new Date(date);
    const newdate = _date.getDate();
    return newdate;
  };

  const dayDate = (date) => {
    let startdate = date.slice(0, 10);
    let dates = new Date(startdate);
    let day = dates.toLocaleString("en-us", { weekday: "long" });
    return day;
  };
  const formatDate = (date) => {
    const _date = new Date(date);
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();
    const newdate = _date.getDate();
    return `${newdate}/${month}/${year}`;
  };

  const formateTime = (time) => {
    const _Time = new Date(time);
    const hours = _Time.getHours();
    const minutes = _Time.getMinutes();
    const FullTime = `${hours}:${minutes}`;
    return FullTime;
  };

  useEffect(() => {
    var resArr = [];
    showEvent.filter(function (item) {
      var i = resArr.findIndex(
        (x) => formatDate1(x.start) == formatDate1(item.start)
      );

      if (i <= -1) {
        resArr.push(item);
      }
      return null;
    });
    setStartDate(resArr);
    // //console.log((resArr,"update event")
  }, [showEvent]);

  const handleOpen = (start) => {
    setSendDate(start);
    const _eventData = [];
    showEvent.filter((data) => {
      if (data.start == start) {
        _eventData.push(data);
      }
    });
    setShowEventDetailsData(_eventData);
    setON(true);
  };
  const handleClose = () => {
    setON(false);
  };

  const handleOpenEvent = (item) => {
    const EVENTDETAILS = <Event item={item} />;
    handleModalData(EVENTDETAILS, "lg");
  };

  // //console.log((showEvent,"event ui ")
  const handleMeeting = async (currentURL, data) => {

    const endTimeStr = data.end;
    const endTime = new Date(endTimeStr);
    const currentTime = new Date();
    if (false) {
      toast.info("The Link has been expired");
      return null;
    }
    const parsedUrl = new URL(currentURL);
    const domain = parsedUrl.origin;

    if (domain === "https://admin.glp-connect.org" || domain === "https://glpadmin.netlify.app" ) {
      const RoomCode = await GetMeetingCode({
        roomId: data.roomDetails[0].id.replace(/\\/g, "").replace(/"/g, ""),
      });
      const jsonData = RoomCode.res.split("Room Detail: ")[1];

      // Parse JSON
      const parsedData = JSON.parse(jsonData);

      // Access the data
      const roomDetails = parsedData.data;
      console.log(roomDetails, "data");

      function openNewTab(slugCode) {
        const url = `${window.location.origin}/glpmeeting/${slugCode}`; // Replace with your actual URL
        window.open(url, "_blank");
      }

      // Check the role and open a new tab if it's "new-role-6288"
      roomDetails.forEach((room) => {
        if (room.role === "guest") {
          openNewTab(room.code);
        }
      });

      // const urlParts = currentURL.split("?");
      // const key = urlParts[1].split("/")[0].split("=")[1];
      // const channelid = urlParts[2].split("=")[1];
      // navigate("/glpmeeting", {
      //   state: {
      //     _token: localStorage.getItem("token"),
      //     token: channelid,
      //     channel_id: key,
      //     userid: localStorage.getItem("adminId"),
      //     roomid: data.eventId,
      //     name: localStorage.getItem("adminName"),
      //   },
      // });
    } else {
      window.open(currentURL, "_blank");
    }
  };
  return (
    <div className="mt-10 mb-4  ">
      <div>
        <h3 className="titlemonth">{moment(titleMonth).format("MMMM")}</h3>
      </div>

      {startDate &&
        startDate.map((data) => (
          <>
            <div className="flex mt-2 justify-between eventsection">
              <div className="eventdate justify-between legmainheader p-4 bg-white ">
                <h3 className="cursor-pointer">{formatDate1(data.start)}</h3>
                <h6 className="text-center">{dayDate(data.start)}</h6>
              </div>
              <div className="bg-white event-details w-full ms-2 pt-2">
                {showEvent.map((item) => {
                  return (
                    <>
                      {formatDate1(data.start) === formatDate1(item.start) && (
                        <div className="eventdetails  px-4 py-2 ">
                          <h3
                            onClick={() => handleOpenEvent(item)}
                            className="cursor-pointer"
                          >
                            {item.name}
                          </h3>
                          <p>
                            {" "}
                            {moment(item.startTime).format("LT")} to{" "}
                            {moment(item.endTime).format("LT")}
                          </p>

                          <span className="">
                            {item.links.map((item2, index) => {
                              return (
                                <>
                                  <div
                                    key={index}
                                    style={{cursor:"pointer"}}
                                    className="as_web"
                                    onClick={() => handleMeeting(item2, item)}
                                  >
                                    {item2.slice(0, 70)}.....
                                    {/* </a> */}
                                  </div>
                                  <div
                                    key={index}
                                    style={{cursor:"pointer"}}
                                    className="as_mobile"
                                    onClick={() => handleMeeting(item2, item)}
                                  >
                                    {item2.slice(0, 20)}.....
                                  </div>
                                </>
                              );
                            })}
                          </span>
                          <hr className="mt-4 mr-16" />
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </>
        ))}

      {showEvent.length === 0 && (
        <h2 className="text-center pt-8 noevent">No event</h2>
      )}
    </div>
  );
};

export default EventMain;
