import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Container, Row, Col } from "reactstrap";
import Filter from '../../Common/Filter';

import SearchTool from '../../Common/SearchHeader/SearchTool';
import { CourseFilter, CourseSearch , getSkillTrainning } from '../../Utils/services';
import SkillTraning from './SkillTraning';

const SkillTraing = () => {
  const [filter,setFilter] =useState("");
  const [skill,setSkill] =useState();
  const [skillprogress,setSkillprogress] =useState([]);
  const [skillcommpleted,setSkillCompleted] =useState([]);
  const [selectedcourse, setselectedCourse] = useState([]);
  const [filterTag, setFilterTag] = useState("");
  const [loading ,setLoading] =useState(false)
  const country = localStorage.getItem("country")

  const SkillTrainingApi =async() =>{
    if (selectedcourse.length > 0) {
      setLoading(true)
      const result = await CourseFilter({
      name: selectedcourse,
    });
 
    setLoading(false)
    setSkill(result.res.course);
    setSkillprogress(result.res.userDetails.inprogressCourse);
    setSkillCompleted(result.res.userDetails.completedCourses)

    // setFeatureStory(result.res);
  }
  else if (filter){
    setLoading(true)
    const result = await CourseSearch({
      "country":country,"query": filter
  });

  setLoading(false)
  setSkill(result.res.course);
  setSkillprogress(result.res.userDetails.inprogressCourse);
  setSkillCompleted(result.res.userDetails.completedCourses)
  }
  else{
    const result = await getSkillTrainning()
    setSkill(result.res.course);
    setSkillprogress(result.res.userDetails.inprogressCourse);
    setSkillCompleted(result.res.userDetails.completedCourses)

  }
     
  }



 
 
  useEffect(()=>{
    SkillTrainingApi()
  },[filterTag, filter,selectedcourse])
  return (
    <Container>
    <Row>
       <div className="mobilesearch mt-20">
        <SearchTool setFilterForum={setFilter} />
       </div>
       <Col lg={7} className="skillmain mt-20">
        <SkillTraning skill={skill} skillprogress={skillprogress} skillcommpleted={skillcommpleted} />
        
      </Col>
      <Col lg={5} className="skillsidebar">
       <Filter setGalleryFilter={setFilter} setFilterTag={setFilterTag} selectedcourse ={selectedcourse} setselectedCourse ={setselectedCourse}  />


      </Col>
    </Row>
  </Container>
  )
}

export default SkillTraing;