import React from 'react'
import Modal from 'react-bootstrap/Modal'
import india from "../Assets/images/country/india.png"
import combodia from "../Assets/images/country/cambodia.png"
import nepal from "../Assets/images/country/nepal.png"
import bangladesh from "../Assets/images/country/bangladesh.png"
import indonesia from "../Assets/images/country/indonesia.png"
import mayanmar from "../Assets/images/country/mayanmar.png"
import thailand from "../Assets/images/country/thailand.png"
import malaysia from "../Assets/images/country/malaysia.png"
import { useTranslation } from 'react-i18next'

const CountryModal = (props) => {
    const {setCountryCode,setModalShow} =props;
    const { t } = useTranslation(["home"]);

const Data = [{
    Icon:india,
    Code:"91",
    cntyCode:"+91",
    name:"India"
    
},
{
    Icon:combodia,
    Code:"855",
    cntyCode:"+855",
    name:"Cambodia"
    
},
{
    Icon:nepal,
    Code:"977",
    cntyCode:"+977",
    name:"Nepal"
    
},
{
    Icon:bangladesh,
    Code:"880",
    cntyCode:"+880",
    name:"Bangladesh"
    
},
{
    Icon:indonesia,
    Code:"62",
    cntyCode:"+62",
    name:"Indonesia"

    
},
{
    Icon:mayanmar,
    Code:"95",
    cntyCode:"+95",
    name:"Myanmar"

    
},
{
    Icon:thailand,
    Code:"66",
    cntyCode:"+66",
    name:"Thailand"  
},
{
    Icon:malaysia,
    Code:"60",
    cntyCode:"+60",
    name:"Malaysia"  
},

]

const handleSetData =(e) =>{
    setCountryCode(e)
    setModalShow(false)
}

  return (
    <div>
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="term-conditons p-4">
        <h3>SELECT YOUR COUNTRY CODE </h3>

        <div className='mt-2'>
            <div className="row">
             {
                Data?.map((item)=>(
                    <div className="col-6" key={item.Code}>
                        <div className='flex items-center my-10 countries h-auto'  onClick={()=>handleSetData(item)}>
                            <div className="country-icons cursor-pointer mr-4">
                               <img src={item.Icon} alt="" />
                            </div>
                         
                            <div>
                                <p className='cursor-pointer' >{item?.cntyCode}</p>
                                <p className='cursor-pointer'>{`${t(`${item?.name}`)}`}</p>
                            </div> 
                        </div>
                    </div>
                ))
             }
        
            </div>
           

        </div>

      </div>
    </Modal>
  </div>
  )
}

export default CountryModal