import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import cell from '../../../Assets/images/gethelp/cell.svg'
import { directorySearchApi, getDirectoryApi } from '../../../Utils/services'
import { useTranslation } from 'react-i18next'

const Listdirectory = ({ filter }) => {
  const [category, setCategory] = useState([])
  const getCountry = localStorage.getItem('country')
  const { t } = useTranslation(['home'])
  const GetDirectory = async () => {
    if (filter) {
      const results = await directorySearchApi({
        query: filter,
        country: getCountry,
      })
      setCategory(results.res.data)
    } else {
      const result = await getDirectoryApi()

      setCategory(result.res.data)
    }
  }
  useEffect(() => {
    GetDirectory()
  }, [filter])

  return (
    <div className="mt-4">
      <div className="pt-4 pt-b directorytitle">
        <h1>{`${t('Directory')}`}</h1>
      </div>
      {category &&
        category.map((item, i) => {
          return (
            <>
              <div className="pt-4 pt-b directorysubtitle">
                <h2>{item.name}</h2>
              </div>
              {item &&
                item.directoryDetails &&
                item.directoryDetails.map((list, index) => (
                  <div className="bg-white p-4 flex justify-between items-center mt-4 rounded-lg">
                    <div className="organisation">
                      <h3>{list.OrganisationName}</h3>
                      <span>{list.description}</span>
                    </div>
                    <div className="flex item-center">
                      <img src={cell} alt="" />
                      <a href={`tel:+${list.Phone_No}`}>
                        <span className="ml-2">{list.Phone_No}</span>
                      </a>
                    </div>
                  </div>
                ))}
            </>
          )
        })}
    </div>
  )
}

export default Listdirectory
