import moment from "moment";
import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row, Spinner } from "reactstrap";
import icon from "../../Assets/images/notify/green.svg";
import iconvisted from "../../Assets/images/notify/visited.png";
import platfromadmin from "../../Assets/images/Social/plateformadmin.png";
import comment from "../../Assets/images/Social/comments.svg";
import diamond from "../../Assets/images/Social/dimond2.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { BASE_URL_IMG, DownloadResourceCount, getNotifaction, getResource, NotificationRead, Single_Resourse, SingleNotificationRead, ViewResource } from "../../Utils/services";
import Legalmain from "../../Resources/Legal_Provisions/Legalmain";

const Notify = ({ filter }) => {
  const [notifyData, setNotifyData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation(["home"]);
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Resource, setResource] = useState({
    loading: true,
    data: [],
    error: false
  })
  const getNotifactionData = async (page) => {
    setLoading(true);
    let result = await getNotifaction(page);
    setPagination(result.res.pagination);
    setNotifyData((prevData) => [...prevData, ...result.res.data]);
    setLoading(false);
  };

  useEffect(() => {
    getNotifactionData(currentPage);
  }, [currentPage]);


  const ResourceData = async (item) => {
    try {
      const data = await getResource({ "id": item.resourcetId })
      setResource({
        loading: false,
        data: data.res,
        error: false
      })
    } catch (Error) {
      setResource({
        loading: false,
        error: false,
        data: []
      })
    }

  }


  const handleRedirect = async (item) => {
    // await NotificationRead();
    await SingleNotificationRead({
      "id": item._id
    })
    if (item.postDetails !== null) {
      navigate(`/gallery/${item.postDetails.postId}`);
      return;
    }

    if (item.StoryDetails !== null) {
      navigate(`/stories/${item.StoryDetails.postId}`);
      return;
    }
    if (item.threadDetails !== null) {
      navigate(`/forum/${item.threadDetails.threadId}`);
      return;
    }
    if (item.forumId && item.forumId !== null) {
      navigate(`/forum/${item.forumId}`);
      return;
    }
    if (item.resourcetId && item.resourcetId !== null) {
      toggle();
      ResourceData(item)
      return;
    }
    if (item.eventId && item.eventId !== null) {
      navigate(`/events`);
      return;
    }
  };

  const iconMap = {
    comment: comment,
    "comment-reply": comment,
    reply: comment,
    default: diamond,
  };

  const handleTypeNotification = (item) => {
    const fromName = item.adminToUser ? `${t("Admin")}` : item.fromName;
    const descriptionMap = {
      likeComment: `${t("liked your comment")}`,
      event: `${t("Admin added new event")}`,
      comment: `${t("commented on your post")}`,
      reply: `${t("replied to your comment")}`,
      "comment-reply": `${t("replied to your comment")}`,
      resource: `${t(`added a new resource in ${item.resourcesData} section on ${item.title}`)}`,
      likeReply: `${t("Liked your reply")}`,
      general: item.description,
      "User-Approved": `${t("Your Account has been approved")}`,
      forum: "added new forum",
    };

    return (
      <div>
        <p className="ml-4">
          <span className="text-blue">{fromName}</span> {descriptionMap[item.notifType]}
        </p>
        <p className="ml-4">{item.title}
          {item.notifType === "event" && (
            <>
              {` From ${moment(item.eventDate).format('DD/MM/YYYY')} To ${moment(item.eventendDate).format('DD/MM/YYYY')}`}
            </>
          )}
        </p>
        {item.notifType === "general" && (
          <img src={`${BASE_URL_IMG}${item.image}`} style={{ height: "auto", width: "15rem" }} alt="" />
        )}
      </div>
    );
  };

  const handleSocialIcon = (item) => {
    return (
      <span className="notifysocial">
        <img src={iconMap[item.notifType] || iconMap.default} alt="" />
      </span>
    );
  };

  const fetchMoreData = () => {
    if (!loading && currentPage <= pagination?.totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };
  const HandaleViewCount = async (item) => {
    const result = await ViewResource({
      "id": item._id
    }
    )
  }

  const HandleDownloadCount = async (item) => {
    const result = await DownloadResourceCount({
      "id": item._id
    }
    )

  }
  console.log(Resource, "Resource");
  return (
    <>
      <div>
        <div className="pt-10 mb-4 flex justify-between legmainheader">
          <h1>{`${t("Action Alerts")}`}</h1>
        </div>

        <InfiniteScroll
          style={{ overflow: "hidden" }}
          dataLength={notifyData.length}
          next={fetchMoreData}
          hasMore={currentPage < pagination?.totalPages}
          loader={
            <div className="loaderfixed">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          }
          endMessage={<p className="text-center">No more notifications</p>}
        >
          <Row>
            <Col>
              <div className="bg-white py-8 pl-4 pr-4 boder-bottom">
                {notifyData
                  .filter((val) => {
                    if (filter === "") {
                      return val;
                    } else if (
                      val?.description?.toLowerCase().includes(filter.toLowerCase()) ||
                      val?.title?.toLowerCase().includes(filter.toLowerCase())
                    ) {
                      return val;
                    }
                    return null;
                  })
                  .map((item, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center border-b py-4 cursor-pointer flex-wrap"
                      onClick={() => handleRedirect(item)}
                    >
                      <div className="flex items-center">
                        <div>
                          <img src={item.read ? iconvisted : icon} alt="" />
                        </div>
                        <div className="w-12 ml-4 notifyimage">
                          <img
                            src={
                              item.adminToUser
                                ? platfromadmin
                                : `${BASE_URL_IMG}/user/${item.from}/${item.from}.jpeg`
                            }
                            alt=""
                            className="h-8 w-8 rounded-full"
                          />
                          {!item.adminToUser && handleSocialIcon(item)}
                        </div>
                        {handleTypeNotification(item)}
                      </div>
                      <div className="flex items-center">
                        <p>{moment(item.notifDate).fromNow()}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
        </InfiniteScroll>
      </div>

      <Modal
        size="xl"
        isOpen={isModelOpen}
      >
        <div className="d-flex justify-content-between p-4" style={{ height: "90dvh", flexDirection: "column" }}>
          <div>
            <h3>Resources</h3>
            <div className="mt-2">
              <div className="row">
                {
                  Resource.loading ?
                    <div className="d-flex justify-content-center align-items-center h-100">

                      <Spinner />

                    </div>
                    :
                    <Legalmain HandleDownloadCount={HandleDownloadCount} HandaleViewCount={HandaleViewCount} pdfData={Resource.data.pdfdata} videoData={Resource.data.videodata} filter={filter} />

                }
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <Button style={{ color: "rgb(0 64 161 / var(--tw-text-opacity))" }} onClick={toggle}>Close</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Notify;
