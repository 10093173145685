import React, { useContext } from 'react'
import diamond from "../Assets/images/Social/dimond2.svg"
import diamond2 from "../Assets/images/Social/diomand3.svg";

import deleteIcon from "../Assets/images/Social/delete.svg"
import { ModalContext } from '../Context';
import DeleteFormComment from '../ModalTemplate/DeleteFormComment';
import FroumEditComment from '../ModalTemplate/FroumEditComment';
import { likeandunlikeservice } from '../Utils/services';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import ForumReplyReport from '../ModalTemplate/ForumReplyReport';
import { useTranslation } from 'react-i18next';

const SocialCommentThree = ({forumUser,id,commentid,handlegetComment,commentdata,userData,like,handleReply,user ,handleChangeButton,admin}) => {
   const [hasClicked, setHasClicked] = React.useState(false);
   const admin_id = localStorage.getItem('adminId');
   const [adminId] =useState(admin_id)
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const { t } = useTranslation(["home"]);
    // //console.log((commentdata,"data")

    const handlelike = async () => {
      try {
        const result = await likeandunlikeservice({
          "threadId":id,
          "commentId":commentid      
        })  
        if (result.res.success) {
          // toast.success(result.res.message)
          setHasClicked(!hasClicked);
          handlegetComment(id)
        }
      } catch (error) {
        //console.log((error)
      }
  }


  // //console.log((like,"test like")
   


    const handleModal=(indexvalue)=>{ 
        let tabsValue = indexvalue ;
        const DELETE_USER_COMMENT = <DeleteFormComment id={id} commentid={commentid} handlegetComment={handlegetComment} />;
        const EDIT_USER_COMMENT = <FroumEditComment id={id} commentid={commentid} handlegetComment={handlegetComment} commentdata={commentdata} />;
        const REPORT_USER_COMMENT = <ForumReplyReport threadId={id} handlegetComment={handlegetComment} />;
        switch (tabsValue) {
          case 0:
            handleModalData(DELETE_USER_COMMENT,"sm");
            break;
      
          case 1:
            handleModalData(EDIT_USER_COMMENT,"sm");
            break;
          case 2:
              handleModalData(REPORT_USER_COMMENT,"sm");
           break;
      
           default:
            handleModalData(DELETE_USER_COMMENT,"lg");
            break;
        }
      }


      useEffect(() => {
      //  //console.log((like,"like data")
        like && like.filter((item) => {    
          if (item.user === adminId) {
            setHasClicked(true);
          }
       })
 
      },[like])

    

  return (
    <>
         <div className="flex mt-2">
             <span className="mr-2 text-sm font-normal  text-blue cursor-pointer" onClick={handleReply}>{`${t('Reply')}`}</span>     

             { 
              adminId !=user ? 
               <>
                {
                !admin ?  <span className="mr-2 text-sm font-normal text-redies cursor-pointer px-2" onClick={()=>handleModal(2)} >{`${t('Report')}`}</span> :null
                }
               </>
             
              :
              <span className="mr-2 text-sm font-normal text-blue cursor-pointer px-2 " onClick={()=>handleModal(1)} >{`${t('Edit')}`}</span>
              }
              
             <span className="mr-2 text-sm font-normal  text-blue flex items-center cursor-pointer pl-2" onClick={() => handlelike()} ><img src={hasClicked ? diamond : diamond2} alt="" className="h-3 " /> <p className="px-2">{like.length}</p> </span>
             { 
             adminId  !=user ? 
             <span  className="mr-2  border-b border-blue  text-sm font-normal  text-blue cursor-pointer"  onClick={handleChangeButton}  > {`${t('Translate')}`}</span>
             :
             <span className="mr-2 text-sm font-normal  text-blue flex items-center cursor-pointer " onClick={()=>handleModal(0)} ><img src={deleteIcon} alt="" className="h-3 " />  </span>         
             }          
         </div>

    </>
  )
}

export default SocialCommentThree