import React, { useContext, useState } from 'react'
import diamond from "../Assets/images/Social/dimond2.svg"
import diamond2 from "../Assets/images/Social/diomand3.svg";
import deleteIcon from "../Assets/images/Social/delete.svg"
import { galleryLikeapi, Translatelanguage } from '../Utils/services';
import { ModalContext } from '../Context';
import UserReport from '../ModalTemplate/UserReport';
import DeleteUserComment from '../ModalTemplate/DeleteUserComment';
import { useEffect } from 'react';
import EditReply from '../ModalTemplate/EditReply';
import { useTranslation } from 'react-i18next';


const SocialComment = ({handleReply,user,handleChangeButton,PostId,CommentId,handleGetComment,like,commentdata,admin}) => {
const [hasClicked, setHasClicked] = React.useState(false);
const admin_id = localStorage.getItem('adminId');
const [adminId] =useState(admin_id)
const modalContext = useContext(ModalContext);
const { closeModal, handleModalData } = modalContext;
const { t } = useTranslation(["home"]);

// //console.log((user,PostId,CommentId,"Data")



const handleModal=(indexvalue)=>{ 
  let tabsValue = indexvalue ;
  const EDIT_COMMENT =<EditReply commentdata={commentdata} PostId={PostId} CommentId={CommentId} handlegetComment={handleGetComment}   />
  const REPORT_USER = <UserReport PostId={PostId} CommentId={CommentId} handleGetComment={handleGetComment} />
  const DELETE_USER_COMMENT =<DeleteUserComment handleGetComment={handleGetComment} CommentId={CommentId} PostId={PostId}    />
  switch (tabsValue) {
    case 0:
      handleModalData(REPORT_USER,"sm");
      break;

    case 1:
      handleModalData(DELETE_USER_COMMENT,"sm");
      break;

    case 2:
        handleModalData(EDIT_COMMENT,"sm");
        break;

     default:
      handleModalData(REPORT_USER,"lg");
      break;
  }
}


const handlelike = async () => {
  try {
    const result = await galleryLikeapi({
      "postId":PostId,
      "commentId":CommentId    
    })
  
    if (result.res.success) {
      // toast.success(result.res.message)
      setHasClicked(!hasClicked);
      handleGetComment(PostId)
      // formservicedata()
    
    }
  } catch (error) {
    //console.log((error)
  }
}



useEffect(() => {
  //  //console.log((like,"like data")
    like && like.filter((item) => {    
      if (item.user === adminId) {
        setHasClicked(true);
      }
   })

  },[like])

 

  return (
    <>
      <div className="flex mt-2">
             <span className="mr-2 text-sm font-normal  text-blue cursor-pointer" onClick={handleReply} >{`${t('Reply')}`}</span>
             { 
             adminId  !=user ?
              <>
              {!admin ?  <span className="mr-2 text-sm font-normal text-redies cursor-pointer px-2" onClick={()=>handleModal(0)} >{`${t('Report')}`}</span> :null}
              </>
            
              :
              <span className="mr-2 text-sm font-normal text-blue cursor-pointer px-2" onClick={()=>handleModal(2)}  >{`${t('Edit')}`}</span>
              }
             
            
             <span className="mr-2 text-sm font-normal  text-blue flex items-center cursor-pointer pl-2 " onClick={handlelike} ><img src={hasClicked?diamond :diamond2} alt="" className="h-3 " /> <p className="px-2">{like?.length}</p> </span>
             { 
             adminId  !=user ? 
             <span  className="mr-2  border-b border-blue  text-sm font-normal  text-blue cursor-pointer" onClick={handleChangeButton} >{`${t('Translate')}`}</span>
             :
             <span className="mr-2 text-sm font-normal  text-blue flex items-center cursor-pointer " onClick={()=>handleModal(1)} ><img src={deleteIcon} alt="" className="h-3 " />  </span>
             }
             
        </div>
    </>
  )
}

export default SocialComment;