import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useTranslation } from 'react-i18next'

const TermConditionModal = (props) => {
  const { t } = useTranslation(['home'])
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="term-conditons p-4">
          <h3>{`${t('TERMS AND CONDITIONS')}`} </h3>

          <div>
            <h4>{`${t('Eligibility')}`}:</h4>
            <p>{`${t('EligibilityDesc')}`}</p>
            <h4>{`${t('User Account')}`}:</h4>
            <p>{`${t('User Account Desc')}`}</p>
            <h4>{`${t('Code of conduct')}`}:</h4>
            <p>
              {`${t('coc1')}`}
              <ul>
                <li> • {`${t('coc2')}`}</li>
                <li>• {`${t('coc3')}`}</li>
                <li> • {`${t('coc4')}`}</li>
                <li> • {`${t('coc5')}`}</li>

                <li> •{`${t('coc6')}`}</li>
                <li>• {`${t('coc7')}`}</li>
                <li>• {`${t('coc8')}`}</li>
              </ul>
              Users who violate these rules and contribution guidelines will be
              contacted by the CONNECT Platform Coordinators specifying the
              issue. Users who repeat offences after receiving warnings will be
              banned from the platform.
            </p>
            <h4>{`${t('Privacy policy')}`}: </h4>
            <p>{`${t('Privacy policy Desc')}`}</p>
            <h4>{`${t('Disclaimer2')}`}</h4>
            <p>{`${t('DisclaimerDesc')}`}</p>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default TermConditionModal
