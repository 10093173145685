import React from "react";
import avtor from "../../../Assets/images/avtar.png";
import diamond from "../../../Assets/images/Social/dimond2.svg";
import TimeAgo from "react-timeago";
import InputText from "../../../Common/InputText";
import { useState } from "react";
import { addCommentGallery, BASE_URL_IMG, getLanguageData, TRANSLATE_URL, Translatelanguage } from "../../../Utils/services";
import SocialComment from "../../../Common/SocialComment";
import platfromadmin from "../../../Assets/images/Social/plateformadmin.png"
import { useEffect } from "react";
import { ModalContext } from "../../../Context";
import { useContext } from "react";
import VerifyNumber from "../../../ModalTemplate/VerifyNumber";
import moment from "moment/moment";
import Replay from "../../../Common/Replay";
import { Globalinformation } from "../../../Common/MainSectionProvider";
import { useNavigate } from "react-router-dom";
import { DeletedAccount } from "../../../Common/Deletedaccount";




const Comment = ({ item, handleGetComment,galleryservicedata,postId,handleSubcomment,usersDataName,userReply }) => {
  const [content,setContent] =useState(item.content)
  const [reply, setRepley] = useState(false);
  const [viewReplies, setViewReplies] = useState(false);
  const [addcomment, setAddcomment] = useState();
  const [aliasName,setAliasName] =useState("")
  const [Id,setID] =useState('')
  const [subcommenttrans,setSubCommentTrans] =useState("")
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const {translateLanguage} =useContext(Globalinformation);
  const admin_id = localStorage.getItem('adminId');

  let navigate = useNavigate();
  

  const IsVerify = localStorage.getItem('phoneverification');

  const times = new Date(item.date);

  const handleReply = () => {
    setRepley(true);
    setViewReplies(true);
  };
  const handleViewReply = () => {
    setViewReplies(true);
    setRepley(true);
  };

  const handleVeriFication = () => {
    let VERIFYPHONE = <VerifyNumber />
    handleModalData(VERIFYPHONE,"sm")
   } 

  const handleAddcomment = async (event) => {
    event.preventDefault();

    if(IsVerify =='true'){
      const result = await addCommentGallery({
        commentId: item._id,
        postId: item.postId,
        comment: addcomment,
      });
      handleGetComment();
      galleryservicedata();
      setAddcomment("");

    }
    else{
      handleVeriFication()
    }
    
  };



  const handleChangeButton =()=>{
    let toLang = translateLanguage; // translate to norwegian
    let text =`${item.content}`; 
    let url = TRANSLATE_URL;
    url += '&q=' + encodeURI(text);
    url += `&target=${toLang}`;
    const token = localStorage.getItem('token')
    
    fetch(url, { 
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'Authorization': `Bearer ${token}`, // notice the Bearer before your token

      }
    })
    .then(res => res.json())
    .then((response) => {
      setContent(response.data.translations[0].translatedText)
    })
    .catch(error => {
      //console.log(("There was an error with the translation request: ", error)
    });
  
  }


  const handlesubcomment =({index,commentdata}) =>{
    let toLang = translateLanguage; // translate to norwegian
    let text =`${commentdata}`; 
    let url =TRANSLATE_URL;
    url += '&q=' + encodeURI(text);
    url += `&target=${toLang}`;
    const token = localStorage.getItem('token')
    
    fetch(url, { 
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'Authorization': `Bearer ${token}`, // notice the Bearer before your token

      }
    })
    .then(res => res.json())
    .then((response) => {
      setSubCommentTrans(response.data.translations[0].translatedText)
      setID(index)
    })
    .catch(error => {
      //console.log(("There was an error with the translation request: ", error)
    });

  }

  useEffect(()=>{
    setContent(item.content)
  },[handleGetComment])

  //console.log((item,"like check")

  useEffect(()=>{
    // const dataname =usersDataName;
    //console.log((usersDataName,item,"manish data")
    const data_Name =usersDataName.filter((users)=>users._id===item.user)
    //console.log((data_Name,"manish")
    setAliasName(data_Name[0])
    
 
  },[usersDataName])

  //console.log((aliasName,"logadata")


const replyHandleName =(item)=>{
  const data_Name =userReply.filter((users)=>users._id===item)
  const data =data_Name[0]
  return data?.aliasName;

}

const handleuserProfile = (item,deletebool) =>{
  // //console.log((item,"profile")

  if(item.user === admin_id){
    navigate({
      pathname: `/profile`,
      
    })
  }
  else if(!item.isAdmin)
  navigate(`/userprofile`,{
    state: {
      Id:item.user,
      deletebool:deletebool
    }
  })

 }

 const deletebool= DeletedAccount(item.user,usersDataName);

  return (
    <>
      <div class="cards flex w-100">
        <div className="w-14 cursor-pointer" onClick={()=> handleuserProfile(item,deletebool)}>
          <img src={item.isAdmin ? platfromadmin  :`${BASE_URL_IMG}/user/${item.user}/${item.user}.jpeg`||avtor}  alt="" className="w-9 h-9 rounded-full" />
        </div>
        <div className="w-3/4  pb-2">
          <p className="text-blue font-bold text-sm" onClick={()=>handleuserProfile(item,deletebool)}> {item.isAdmin? <>Admin</> : <> {deletebool ? "Deleted Account" : aliasName && aliasName.aliasName } </>  } {item.isAdmin ==true && <span className="moderator"> Moderator</span> } </p>
          <p className="text-xs"> <TimeAgo date={times} /> </p>
           
        
          <span className="text-justify text-sm"> {content}</span>
           <SocialComment 
             handleReply={handleReply} 
             user={item.user}
             handleChangeButton={handleChangeButton} 
             PostId={item.postId}
             CommentId={item._id} 
             handleGetComment={handleGetComment}
             like={item.likes}
             postId={postId}
             commentdata={content}
             admin={item.isAdmin}

            />
           {item && item.replies.length > 0 && (
            <>
              {viewReplies ? null : (
                <div
                  className="pt-2 text-sm font-normal hover:underline "
                  role="button"
                  onClick={handleViewReply}
                >
                  - View {item && item.replies.length} replies
                </div>
              )}
            </>
          )}

          {viewReplies && (
            <>
              {item &&
                item?.replies.map((items, i) => {
                  const deleteuser=DeletedAccount(item.user,usersDataName)
                  // console.log(deleteuser);
                  return(
                  <div className="mt-2 border-t pt-2">
                    <div class="cards flex w-100">
                      <div className="w-14 mr-1 cursor-pointer" onClick={()=>handleuserProfile(items,deleteuser)}>
                        <img src={items.isAdmin ? platfromadmin  :`${BASE_URL_IMG}/user/${items.user}/${items.user}.jpeg`||avtor} alt="" className="w-9 h-9 rounded-full" />
                      </div>
                      <div className="w-96  pb-2">
                        <h6 className="text-blue font-bold text-sm" onClick={()=>handleuserProfile(items,deleteuser)}>
                          {items.isAdmin ? <> Admin  <span className="moderator"> Moderator</span>   </> :  deleteuser ? "Deleted Account" : replyHandleName(items.user) } 
                        </h6>
                        <p className="text-xs">{moment(items.date).fromNow()}</p>
                        <p className="text-justify text-sm">{items.content}</p>
                        {
                          Id ==i && <p className="text-justify text-sm">{subcommenttrans}</p>
                        }

                         <Replay
                           ReplyId={items._id}
                           user={items.user} 
                           commentdata={items.content}
                           handlegetComment={handleGetComment}
                           PostId={item.postId}
                           CommentId={item._id}
                           likes={items.likes}
                           handlesubcomment={handlesubcomment}
                           index={i}

                           
                         
                         
                         />
                      </div>
                    </div>
                  </div>
                )})}
            </>
          )}
             
          {reply && (
            <InputText
              handleAddcomment={handleAddcomment}
              setAddcomment={setAddcomment}
              addcomment={addcomment}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Comment;
