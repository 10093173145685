import React, { useContext, useState } from 'react'
import { ModalContext } from '../Context';
import { FormSuggest } from '../Utils/services';

const SuggestModal = () => {
    const [suggest,setSuggest] =useState("");
    const [discussed,setDiscuss] =useState("")
    const [errors, setErrors] = useState({});
 
    const modalContext = useContext(ModalContext);
    const { closeModal} = modalContext;

    
    const handleSubmit = async(e) =>{
        e.preventDefault() 
        if(handleValidation()){
        const result =await FormSuggest(
            {
                "suggested_topic": suggest,
                "description":discussed
            }
        )
        if(result.res.success){
            setSuggest("")
            setDiscuss("")
            closeModal()
          
        }
    }



    }

       const handleValidation=()=>{
        let _errors = {};
        let formIsValid = true;
        if (!suggest) {
          formIsValid = false;
          _errors["suggest"] = "Please enter the suggestion";
        }
        
      
      
    
    
        setErrors(_errors)
        return formIsValid;
    
      }



  return (
    <form action="" onSubmit={handleSubmit}>
        <div className='suggestform'>
            <input value={suggest} type="text" placeholder='Suggested Topic' onChange={(e)=> setSuggest(e.target.value)} />
             {
              errors.suggest && <p className="text-sm text-left text-danger pt-1">{errors.suggest}</p>
              }
            <textarea rows={5} cols={5} className='mt-4 suggestarea'  placeholder='Description(optional)'   value={discussed}   onChange={(e)=>setDiscuss(e.target.value)} />
            <div className='flex justify-center mt-4 btnsuggest '>
              <button type='submit'>SUBMIT</button>
            </div>
           
        </div>
    </form>
  )
}

export default SuggestModal