import React from 'react'
import { useState } from 'react';
import filter from '../../Assets/images/MobileIcon/filter.svg'
import socialmark from "../../Assets/images/Social/bookmark2.svg"
import { BASE_URL_IMG } from '../../Utils/services';
import FiterBy from './FiterBy';
import SaveForum from './SaveForum';
import { useNavigate } from 'react-router-dom';

const SearchGalary = ({setFilterForum,setTagFilter,selectedcourse,setselectedCourse}) => {
  const userNameID = localStorage.getItem("adminId");
  const [filters,setFilter] =useState(false);
  const [savedShow,setSavedShow] =useState(false);
  let navigate = useNavigate();
   
  const handleFilter = () =>{
    setFilter(!filters)
    setSavedShow(false)

  }


  return (
    <div  className='bg-white mb-4 '>
    <div className="flex  mb-2 justify-start flex-wrap items-center p-4 rounded  " >
        <img src={`${BASE_URL_IMG}/user/${userNameID}/${userNameID}.jpeg`} className="h-8  mr-2 cursor-pointer" onClick={()=>navigate('/profile')}   alt="" />               
        <div className="bg-gray p-2 search-wrapper mr-2">
        <div className="inputsearch">
          <input type="text" placeholder="Search" className="mx-3 px-3" onChange={(e)=>setFilterForum(e.target.value)} />
        </div>
        </div>

         <div className='flex items-center  cursor-pointer mt-2' onClick={handleFilter}>
          <img src={filter} alt="" className='mr-2 cursor-pointer' />
          <span>Filter By</span>         
        </div>                   
    </div>
    {filters && <FiterBy setTagFilter={setTagFilter} selectedcourse={selectedcourse} setselectedCourse ={setselectedCourse} /> }
   

</div>
  )
}

export default SearchGalary