import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'reactstrap'
import ProfileContainer from './ProfileContainer'

const UserProfile = () => {
  const { t } = useTranslation(['home'])
  return (
    <Container>
      <div className="profile AS_Profile" >
        <Row>
          <Col>
            <div>
              <h1 className="pt-2">{`${t('Profile')}`} </h1>
            </div>
          </Col>
        </Row>

        <ProfileContainer />
      </div>
    </Container>
  )
}

export default UserProfile
