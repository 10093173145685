import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TRANSLATE_URL, userPoll, userPollV2 } from "../../Utils/services";
import { useTranslation } from "react-i18next";
import { Globalinformation } from "../../Common/MainSectionProvider";

const PollingCard = ({
  choices,
  _id,
  formservicedata,
  length,
  item,
  click,
}) => {
  var PollDetails = item
  const [getPercen, setPercen] = useState({});
  const [item1, setitem] = useState(item);
  const [showPercent, setShowPercent] = useState(false);
  const [postId, setPostId] = useState(null);
  const [question, setQustion] = useState(item?.question);
  const [Translatedquestion, setTranslatedquestion] = useState("");
  const [isTranslated, setisTranslated] = useState(false);
  const [showDeleteReport, setShowDeleteReport] = useState(false);
  const [choicesData, setChoicesData] = useState([]);
  const [TranslatedchoicesData, setTranslatedchoicesData] = useState([]);
  const [AnswerID,setAnswerID] = useState(null)
  const { translateLanguage } = useContext(Globalinformation);
  const userid = localStorage.getItem("adminId");
  const { t } = useTranslation(["home"]);

  const handleVoteGive = async (choiceId) => {

    if (click) {
      setPostId(choiceId);
      handleShowPercentage(choiceId);
      try {
        var result

        if (PollDetails.version && PollDetails.version === 2) {
          result = await userPollV2({
            post: _id,
            choice: choiceId,
          });
        } else {
          result = await userPoll({
            post: _id,
            choice: choiceId,
          });
        }
        //console.log(result.res.data.choices);
        // setChoicesData(result.res.data?.choices);
        if (result.res.success) {
          handlepercentage();
          toast.success(result.res.message);
          formservicedata();
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const handlepercentage = () => {
    let totalVoted = 0;
    let obj = {};
    choices &&
      choices.map((item) => {
        return (totalVoted = item.score + totalVoted);
      });
    choices &&
      choices.map((item) => {
        if (totalVoted > 0) {
          obj = {
            ...obj,
            [item._id]: (item.score / totalVoted) * 100,
          };
        } else {
          obj = {
            ...obj,
            [item._id]: 0,
          };
        }
      });
    setPercen(obj);
  };

  useEffect(() => {
    checkuser(); // Call checkuser() function

    handlepercentage(); // Call handlepercentage() function

    choices.map((item) => {
      //console.log(item); // Log each item to the console

      // Update choicesData state by appending item.title
      setChoicesData((prevChoicesData) => [...prevChoicesData, item.title]);
    });
  }, [choices]);

  const handleShowPercentage = (id) => {
    const _showPer =
      choices &&
      choices.filter((item) => {
        return id === item._id;
      });
    const _DataId =
      _showPer &&
      _showPer.map((val) => {
        return val._id;
      });

    if (_DataId[0] === id) {
      setShowPercent(true);
    } else {
      setShowPercent(false);
    }
  };

  function getRemainingHours(date, length) {
    var now = new Date();
    var postedTime = Math.floor((now - new Date(date)) / 60000); // Difference in minutes
    var remainingMinutes = length - postedTime;
    var remainingHours = Math.floor(remainingMinutes / 60);
    return remainingHours;
  }

  const checkuser = () => {
    if (PollDetails.version && PollDetails.version === 2) {

      if(PollDetails.isAnswer){
        setAnswerID()
        setShowPercent(true);
        setPostId(PollDetails.isAnswer);
      }
    } else {
      choices &&
        choices.filter((item) => {
          return item.users.map((vote) => {
            if (vote === userid) {
              setShowPercent(true);
              setPostId(item._id);
            }
          });
        });
    }

    const time = getRemainingHours(item?.date, item?.length);
    //console.log(time, "item.length");

    if (time < 0) {
      setShowPercent(true);
    }
  };

  // Handle Translation

  const handleChangeButton = () => {
    setisTranslated(!isTranslated);

    if (Translatedquestion === "") {
      let toLang = translateLanguage; // translate to norwegian
      let text = `${question}`;

      let choice = [];
      choices.map((item) => choice.push(item.title));
      let url = TRANSLATE_URL;
      url += "&q=" + encodeURI(text);
      url += "&q=" + encodeURI(choice);
      url += `&target=${toLang}`;
      const token = localStorage.getItem("token");

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`, // notice the Bearer before your token
        },
      })
        .then((res) => res.json())
        .then((response) => {
          setTranslatedquestion(response.data.translations[0].translatedText);
          if (response.data.translations[1].translatedText.includes(",")) {
            setTranslatedchoicesData(
              response.data.translations[1].translatedText.split(",")
            );
          } else {
            setTranslatedchoicesData(
              response.data.translations[1].translatedText.split(" ")
            );
          }
        })
        .catch((error) => {
          console.log(
            "There was an error with the translation request: ",
            error
          );
        });
    }
  };

  return (
    <>
      <div className="card my-6">
        <div className="card-body">
          <div className="polling ">
            <div className="cursor-pointer flex justify-between">
              <h5 className="card-title activecardhead ">
                {isTranslated ? Translatedquestion === '' ? question : Translatedquestion : question}
              </h5>
              <span
                className="underline text-blue cursor-pointer translateres"
                onClick={handleChangeButton}
              >{`${t("Translate")}`}</span>
            </div>
            <div>
              <ul className="">
                {choices &&
                  choices.map((item, index) => (
                    <li
                      className="polling"
                      key={index}
                      onClick={() => handleVoteGive(item._id)}
                    >
                      {showPercent && (
                        <>
                          <span
                            className="progress-bar"
                            style={{ width: `${getPercen[item._id]}%` }}
                          />
                          <span className="progress-text">
                            {" "}
                            {`${Math.trunc(getPercen[item._id])}%`}{" "}
                          </span>
                        </>
                      )}
                      <span>
                        {isTranslated
                          ? TranslatedchoicesData[index]
                          : choicesData[index]}{" "}
                        {postId === item._id ? "" : ""}
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="paths flex justify-between">
              <div className="flex"></div>
              <div className="postbutton flex items-center">
                <p>
                  {getRemainingHours(item?.date, item?.length) < 0
                    ? "poll Close"
                    : `Ends in ${getRemainingHours(
                      item?.date,
                      item?.length
                    )} hours`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PollingCard;
