import React from "react";
import { Col, Container, Row } from "reactstrap";
import UserProfileContainer from "./UserProfileContainer";
import { BASE_URL_IMG } from "../../Utils/services";
import { useLocation } from "react-router";

const UserProfiles = () => {
  const location = useLocation();
  let deleted = true;
  if (location.state.deleted) {
    deleted = location.state.deletebool;
  }
  return (
    <>
      <Container>
        <div className="profile AS_UserProfile">
          <Row>
            <Col>
              <div>
                <h1 className="pt-2">User Profile </h1>
              </div>
            </Col>
          </Row>
          {!location.state.deletebool ? (
            <UserProfileContainer />
          ) : (
            <Row>
              <Col>
                <div className="profilecontainer mt-4 flex">
                  <div className="w-1/4 profilesidebar">
                    <div className="aliasprofile flex  flex-column items-center">
                      <div className="w-24 flex justify-center mt-16">
                        <img
                          src={`${BASE_URL_IMG}/Avtar/0e89a39e86f97f031340445a76c41f5c88f5ff2b8683e0fa5ae19c52a3f87b7b-6282f877-4824-4b46-9bfd-ead5dd420198.png`}
                          alt=""
                          className="w-100 cursor-pointer"
                        />
                      </div>
                      <h2 className="pt-4">{"Alias Name"}</h2>
                      <h3>{"Sector "} </h3>
                      <h4>{"Country"} </h4>
                    </div>
                  </div>
                  <div className="w-3/4 profilemainbar flex justify-content-center items-center ">
                    <div className="text-center">
                      <h5 style={{ fontSize: "2rem" }}>Account is deleted</h5>
                      <br />
                      <span> (This account has been deleted by the user)</span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </>
  );
};

export default UserProfiles;
