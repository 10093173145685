import moment from 'moment/moment'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { Col, Row } from 'reactstrap'

import newsImage from '../../Assets/images/NewsFeed/news.png'
import { Globalinformation } from '../../Common/MainSectionProvider'
import timeIcon from '../../Assets/images/Social/hrtwo.svg'
import {
  TRANSLATE_URL,
  getNewsFeed,
  getNewsFeedFilter,
  getNewsFeedSave,
  getSearchNewsFeed,
} from '../../Utils/services'
import { Spinner } from 'react-bootstrap'
import NewsCom from './NewsCom'

const NewsMain = ({ categaryValue, searchfilter, selectedfilter }) => {
  const [newshowData, setNewShowData] = useState([])
  const [newsFeed, setNewsFeed] = useState()
  const [news, setNews] = useState('')
  const { fileteValue, setFilterValue, tagsearch, settagsearch } = useContext(
    Globalinformation,
  )
  const { translateLanguage } = useContext(Globalinformation)
  const [ID, setID] = useState('')
  const [loading, setLoading] = useState(false)
  const [saveNews, setSaveNews] = useState([])

  const handleTranslate = ({ item, index }) => {
    let toLang = translateLanguage // translate to norwegian
    let text = `${item.title}`
    let url = TRANSLATE_URL
    url += '&q=' + encodeURI(text)
    url += `&target=${toLang}`
    const token = localStorage.getItem('token')

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`, // notice the Bearer before your token
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setNews(response.data.translations[0].translatedText)
        setID(index)
      })
      .catch((error) => {
      })
  }

  const newsfeed = async (val) => {
    if (searchfilter) {
      setLoading(true)
      const result = await getSearchNewsFeed({
        query: searchfilter,
      })
      setLoading(false)
      setNewsFeed(result.res)
    } else if (selectedfilter.length > 0) {
      setLoading(true)
      const result = await getNewsFeedFilter({
        tag: selectedfilter,
        category: categaryValue,
      })
      setLoading(false)
      setNewsFeed(result.res)
    } else {
      setLoading(true)
      const result = await getNewsFeed({ name: categaryValue })
      setLoading(false)
      setNewsFeed(result.res)
    }
  }

  useEffect(() => {
    newsfeed()
  }, [categaryValue, searchfilter, selectedfilter])

  const handleSaveNewData = async () => {
    const result = await getNewsFeedSave()
    setSaveNews(result.res.savednewsfeed)
  }

  useEffect(() => {
    handleSaveNewData()
  }, [searchfilter])

  return (
    <>
      <div className=""></div>

      {loading ? (
        <div className="loaderfixed">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Row>
          {newsFeed &&
            newsFeed.map((item, index) => {
              return (
                <>
                  <Col md={12}>
                    <NewsCom
                      item={item}
                      index={index}
                      news={news}
                      handleTranslate={handleTranslate}
                      ID={ID}
                      timeIcon={timeIcon}
                      saveNews={saveNews}
                      handleSaveNewData={handleSaveNewData}
                      searchfilter={searchfilter}
                    />
                  </Col>
                </>
              )
            })}
        </Row>
      )}
    </>
  )
}

export default NewsMain
