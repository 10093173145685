import React from 'react';
import { useState } from 'react';
import manu from "../../Assets/images/gallery/Menu.svg";
import filter from '../../Assets/images/MobileIcon/filter.svg'
import socialmark from "../../Assets/images/Social/bookmark2.svg"
import { BASE_URL_IMG } from '../../Utils/services';
import FiterBy from './FiterBy';
import SaveForum from './SaveForum';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



const SearchForm = ({setFilterForum,setTagFilter,selectedcourse,setselectedCourse}) => {
  const userNameID = localStorage.getItem("adminId");
  const [filters,setFilter] =useState(false);
  const [savedShow,setSavedShow] =useState(false);
  let navigate = useNavigate();
  const { t } = useTranslation(['home'])
  const handleFilter = () =>{
    setFilter(!filters)
    setSavedShow(false)

  }

  const handleShow = ( ) =>{
    setSavedShow(!savedShow)
    setFilter(false)
  }





  return (
    <div  className='bg-white mb-4 '>
        <div className="flex  mb-2 justify-start flex-wrap items-center p-4 rounded  " >
            <img src={`${BASE_URL_IMG}/user/${userNameID}/${userNameID}.jpeg`} className="h-8  mr-2 cursor-pointer " onClick={()=>navigate('/profile')}  alt="" />               
            <div className="bg-gray p-2 search-wrapper mr-2">
            <div className="inputsearch">
              <input type="text" placeholder={`${t("Search")}`} className="mx-3 px-3" onChange={(e)=>setFilterForum(e.target.value)} />
            </div>
            </div>
       
            <div className='flex items-center cursor-pointer ml-2 mr-4 mt-2' onClick={handleShow}>
                <img src={socialmark} alt="" className='mr-2' />
                <span className='as_web'>{`${t('Saved Forum')}`}</span>
            </div>
             <div className='flex items-center  cursor-pointer mt-2' onClick={handleFilter}>
              <img src={filter} alt="" className='mr-2 cursor-pointer' />
              <span className='as_web'>{`${t('Filter By')}`}</span>         
            </div>                   
        </div>
        {filters && <FiterBy setFilterForum={setFilterForum} setTagFilter={setTagFilter} selectedcourse={selectedcourse} setselectedCourse ={setselectedCourse}   /> }
        {savedShow && <SaveForum /> }

    </div>
  )
}

export default SearchForm