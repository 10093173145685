import React from "react";
import { useTranslation } from "react-i18next";
import {  Container, Row } from "reactstrap";
import getHelp from "../../Assets/images/gethelp/gethelp2.png";
import { useNavigate } from "react-router-dom";

const Gethelp = () => {
  const { t } = useTranslation(["home"]);
  const navigate = useNavigate();
  

  const HandledirectorPage = () => {
    navigate("/directory")
  }
  const HandledirectorPageChat = () => {
    navigate("/chat")
    


  }

  const HandleEventPage = () => {
    navigate("/get-help-event")
  }




  return (
    <Container>
      <div className="profile ">
        <Row className="pt-2 pb-5"></Row>

        {/* <Row> */}
          <div className=" bg-white getHelp p-8">

            <div className=" flex justify-center items-center ">
              <div className="gethelimage   ">
                <img src={getHelp} alt="" />
              </div>
            </div>
            <div>

              <div className="flex justify-center mt-5 As_help_button ">
                <button className="showDirectory mr-8" onClick={HandledirectorPageChat} >{`${t('Write To Us')}`}</button>
                <button className="writeToUs mr-8" onClick={HandleEventPage} >{`${t('Events')}`}</button>
                <button className="writeToUs" onClick={HandledirectorPage} >{`${t('Show Directory')}`}</button>

              </div>


            </div>

          </div>
        {/* </Row> */}
      </div>
    </Container>
  );
};

export default Gethelp;
