import React, { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';






const TestTask = ({mcq}) => {
   const [qustions,setQustions] =useState();
   const [currentQus,setCurrentQus] =useState(0);
   const [Qustion,setQustion] =useState();
   const [optionSelect,setOptionSelect] =useState();
   const [Ans,setAns] =useState();
   const [nextButton,setNextButton] =useState(false);
   const [finsh,setFinsh] =useState(false)
   const [hint,setHint] =useState()
   const { t } = useTranslation(['home'])
   let navigate = useNavigate(); 
// ----------multichoice ----------- //
const [checkedOptions, setCheckedOptions] = useState([]);
const [selectedcourse, setselectedCourse] = useState([]);

// ----------multichoice ----------- //

  //  const data = qustions[currentQus];
  function areEqual(arr1, arr2)
  {
      let N = arr1.length;
      let M = arr2.length;
      // If lengths of array are not equal means
      // array are not equal
      if (N != M)
          return false;

      // Sort both arrays
      for(let i=0;i<N;i++){
          if(!arr2.includes(arr1[i])){
            return false
          }
      }

      // If all elements were same.
      return true;
  }



  


  useEffect(()=>{
    if(mcq){
      setQustions(mcq)
      setQustion(mcq[currentQus])
    }
   
  },[mcq,currentQus])

const handleCurrectAns =() =>{

  setCurrentQus(prev => prev +1);
  setAns()
  setOptionSelect()
  setNextButton(false)
  setFinsh(false)



}

const handleAnsCheck =(item,e) =>{
  if(Qustion?.Option[parseInt(Qustion?.Answer[0])-1] === item){
    setAns("right")
    setOptionSelect(e);
    setNextButton(true)
    setHint("")
    setFinsh(true)
  }
  else{
    setAns("wrong")
    setOptionSelect(e)
    setNextButton(false)
    setHint(Qustion?.hint)
    setFinsh(false)
  }
 
  
}

const handleFinshTopic =() =>{

  navigate(-1)
}

// -----------multi choice ------//

const handlecheckbox = (e) => {
  var temp = selectedcourse;
  if (e.target.checked === false) {
    if(Qustion?.Answer.includes(e.target.value)){

      const index = checkedOptions.indexOf(e.target.value);
      if (index > -1) { 
        checkedOptions.splice(index, 1);
        setCheckedOptions([...checkedOptions])
      }
    }
  
      temp = temp.filter((a) => {
          return a != e.target.value;
      });
  }
  if (e.target.checked === true) {
    if(Qustion?.Answer.includes(e.target.value)){
      setCheckedOptions([...checkedOptions, e.target.value]);
      setHint()
    }else{
      
        setHint(Qustion?.hint)
    
    }
}
  e.target.checked
      ? setselectedCourse([...selectedcourse, e.target.value])
      : setselectedCourse([...temp]);
};
useEffect(()=>{
  if(Qustion?.Answer){
    setNextButton(areEqual(Qustion?.Answer,checkedOptions))
    setFinsh(areEqual(Qustion?.Answer,checkedOptions))
  }

},[checkedOptions])

  return (
    <div>
      <h1 className='testTitle mb-3 h '>Session web test</h1>
       <div className='mb-2'>
         <h3 className='mb-2'>Qustion {currentQus +1}: {Qustion?.Question}</h3>
         <h4 className=' text-blue mb-6'> {`${t('Select the correct answer')}`}</h4>
        {
          Qustion?.QuestionType ==="Single"
          &&
          <>
           {
            Qustion?.Option?.map((item,index)=>(

            <div className={` qus-options cursor-pointer  ${ index == optionSelect && `${Ans}`}  `} onClick={()=>handleAnsCheck(item,index)}>
             <div className='flex ' >
              <p className='mr-4 optionNumber' >{1+index}</p> <p>{item}</p>
             </div>
            </div>
          ))
         }  
          </>
        }

        {
           Qustion?.QuestionType ==="Multiple"
           &&
           <> 
                <ul class="ux-btn-groups">

                 {Qustion?.Option.map((course, index) => {
                    return (
                        <> 
                            <li key={index} >
                                <input type="checkbox" value={course} id={`${course}`} class={`${checkedOptions.includes(course)?"ux-btn-group-item-correct":"ux-btn-group-item-wrong"}`} name="option" onClick={handlecheckbox} />
                                <label for={`${course}`}   >{course}</label>
                            </li>
                        </>
                    );
                })}
                </ul>
           
           </>
        }
         

       <div className='flex justify-between'>
        {
          currentQus <  qustions?.length -1 
           && 
           <div>
            {
              nextButton  && 
              <button onClick={handleCurrectAns} className="Nextbtn">NEXT</button>
            }
          </div>
        }
       

        {
            hint && <p className='text-center'>{`${t('Hint')}`} : {hint}</p>
        }
       
      
        {
          qustions?.length -1 ===currentQus  && 
          <div>
            {
              finsh && 
              <button onClick={handleFinshTopic} className="Nextbtn">FINISH</button>
            }
          </div>

     
         }

       </div>
      
      
      
       </div>
    </div>
  )
}

export default TestTask