import React from "react";
import ReactDOM from "react-dom/client";
import "./app.css";
import App from "./Components/App";
import "./Assets/styles.css";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./Material_UI_Theme";
import { AuthContextProvider } from "./Utils/auth-context";
import NetworkServices from "./Utils/network-services";
import "./i18n";
import MainSectionProvider from "./Common/MainSectionProvider";
import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";

NetworkServices();

const root = ReactDOM.createRoot(document.getElementById("root"));
if (process.env.NODE_ENV !== 'development') {
  console.info = () => {};
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}
root.render(
  <Suspense fallback={null}>
    <React.StrictMode>
        <AuthContextProvider>
          <ThemeProvider theme={theme}>
            <MainSectionProvider>
              <App />
            </MainSectionProvider>
          </ThemeProvider>
        </AuthContextProvider>
    </React.StrictMode>
    </Suspense>
);
