import React, { useContext, useEffect, useState } from "react";
import { BASE_URL_IMG, galleryServiceapi, SearchGalleryapi } from "../../Utils/services";
import Gallerycard from "./Gallerycard";
import GalleryHead from "./GalleryHead";
import { Globalinformation } from "../../Common/MainSectionProvider";
import SearchGalary from "../../Common/SearchHeader/SearchGalary";
import { Spinner } from "reactstrap";
import { useParams } from "react-router";

const GallaryInnerSocial = ({ galaryfilter, tafFilter, setGalleryFilter, setFilterTag, selectedcourse, setselectedCourse }) => {
  const [galleryData, setGalleryData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const country = localStorage.getItem("country");
  const countryPost = localStorage.getItem("countrypost");

  const { fileteValue, tagsearch } = useContext(Globalinformation);
  const { id } = useParams();

  const galleryservicedata = async () => {
    try {
      let result;
      if (selectedcourse.length > 0) {
        result = await galleryServiceapi({
          country: country,
          name: selectedcourse,
        });
      } else if (galaryfilter) {
        result = await SearchGalleryapi({
          query: galaryfilter,
          country: "India",
        });
      } else {
        result = await galleryServiceapi({
          country: country,
          name: tagsearch,
        });
      }

      if (result) {
        const posts = id ? result.res.data.posts.filter(item => item._id === id) : result.res.data.posts;
        setGalleryData(posts);

        if (result.res.data.userDetails.likedPosts.length > 0) {
          setUserData(result.res.data.userDetails.likedPosts);
        }
      }
    } catch (error) {
      console.error("Error fetching gallery data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    galleryservicedata();
  }, [id, galaryfilter, selectedcourse, page]);

  const HeadDetails = (countryPost) => {
    const data = JSON.parse(countryPost);
    return data ? <GalleryHead galleryservicedata={galleryservicedata} /> : null;
  };

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 > document.documentElement.scrollHeight) {
      setPage(prev => prev + 1);
      setLoading(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="pt-10">
      {HeadDetails(countryPost)}

      <div className="mobilesearch">
        <SearchGalary setFilterForum={setGalleryFilter} setTagFilter={setFilterTag} />
      </div>

      {galleryData &&
        galleryData.map((item, index) => (
          <Gallerycard
            key={index}
            item={item}
            index={index}
            galleryservicedata={galleryservicedata}
            userDetails={userData}
          />
        ))}

      {loading && (
        <div className="loaderfixed">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
};

export default GallaryInnerSocial;
