import LogoImg from "../../Assets/images/sidebar/connecttwo.png";
import { navlist } from "./navlist";

import classNames from "classnames";
import arrowImg from "../../Assets/images/arrow.svg";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { Globalinformation } from "../../Common/MainSectionProvider";

const SideNav = () => {
  const location = useLocation();
  const [showSubMenu, setShowSubMenu] = useState(false);
  const { t } = useTranslation(["home"]);
  const { NotificationMap } = useContext(Globalinformation);
  const handleAroowClick = () => {
    setShowSubMenu(!showSubMenu);
  };


  return (
    <div id="sideNav" className="">
      <div className="profile-info">
        <img src={LogoImg} alt="LogoImg" />
      </div>
      <div className="navlist">
        <ul>
          {navlist.map((item) => (
            <li key={item.ref}>
              <NavLink
                to={item.path}
                className={classNames({
                  "flex items-center justify-center cursor-pointer ": true,
                  "text-sidenav": location.pathname === item.path,
                  "text-navTextColor": location.pathname !== item.path,
                })}
              >
                <div className="grid grid-cols-6 gap-4 items-center">
                  <span className="navicon ">
                    <img src={item.icon} alt="icon" />
                  </span>
                  <span className="capitalize col-span-5 flex justify-content-between" style={{ fontWeight: "bold" }}>
                    <div className="flex items-center" style={{ gap: "0.5rem" }}>
                      {`${t(`${item.label}`)}`}
                      {
                        NotificationMap[item.ref] !== undefined && NotificationMap[item.ref] > 0 && (
                          <div className="notificationdot">{NotificationMap[item.ref]}</div>
                        )
                      }
                    </div>
                    {item.submenu && (
                      <div style={{ transform: `rotate(${showSubMenu ? 180 : 0}deg)` }}>
                        <img
                          src={arrowImg}
                          alt=">"
                          className={showSubMenu ? "pl-3 ml-5 w-6 h-6" : "pr-3 mr-5 w-6 h-6"}
                          onClick={handleAroowClick}
                        />
                      </div>
                    )}
                  </span>
                </div>
              </NavLink>
              {item.submenu && showSubMenu && (
                <ul
                  className="SubMenuData  overflow-y-scroll "
                  id="filtersrollbar"
                >
                  {item.submenu &&
                    item.subMenuData.map((data, index) => {
                      if (index !== item.subMenuData.length) {
                        return (
                          <li key={data.ref}>
                            <NavLink
                              to={data.path}
                              className={classNames({
                                "flex items-center justify-center cursor-pointer": true,
                                "text-sidenav": location.pathname === data.path,
                                "text-navTextColor":
                                  location.pathname !== data.path,
                              })}
                            >
                              <div className="grid grid-cols-6 gap-4 items-center pr-2">
                                <span className="col-span-1">
                                  <img src={data.icon} alt="icon" />
                                </span>
                                <span className="flex fontSizeSideNav text-navTextColor col-span-5" style={{ fontWeight: "bold" }}>
                                  {`${t(data.label)}`}
                                </span>
                              </div>
                            </NavLink>
                          </li>
                        );
                      }
                      return null; // Return null for the last element
                    })}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideNav;
