import icon1 from "../../Assets/images/sidebar/forum.png"
import icon2 from "../../Assets/images/sidebar/gallary.png"
import icon3 from "../../Assets/images/sidebar/resources.png"
import icon4 from "../../Assets/images/sidebar/toolkits.png"
import icon5 from "../../Assets/images/sidebar/events.png"
import icon6 from "../../Assets/images/sidebar/notification.png"
import icon7 from "../../Assets/images/sidebar/stories.png"
import icon8 from "../../Assets/images/sidebar/newsfeed.png"
import icon9 from "../../Assets/images/sidebar/gethelp.png"
import iconSub1 from "../../Assets/images/sidebar/sub1.png"
import iconSub2 from "../../Assets/images/sidebar/sub2.png"
import iconSub3 from "../../Assets/images/sidebar/sub3.png"
import iconSub4 from "../../Assets/images/sidebar/sub4.png"
import iconSub5 from "../../Assets/images/sidebar/sub5.png"
import iconSub6 from "../../Assets/images/sidebar/sub6.png"

import forum from "../../Assets/images/sidebaricon/forum.svg"
import gallery from "../../Assets/images/sidebaricon/gallery.svg"
import event from "../../Assets/images/sidebaricon/event.svg"
import help from "../../Assets/images/sidebaricon/help.svg"
import resource from "../../Assets/images/sidebaricon/resource.svg"
import tool from "../../Assets/images/sidebaricon/tool.svg"
import alert from "../../Assets/images/sidebaricon/alert.svg"
import story from "../../Assets/images/sidebaricon/story.svg"
import news from "../../Assets/images/sidebaricon/newsfeed.svg"


import Newevent from "../../Assets/images/newsidebaricon/event.svg";
import NewGallary from "../../Assets/images/newsidebaricon/gallary.svg";
import Newhelp from "../../Assets/images/newsidebaricon/help.svg";
import NewResource from "../../Assets/images/newsidebaricon/resource.svg";




export const navlist = [
    {
        label: "Forum",
        path: "/",
        ref: "Forum",
        icon: forum,
        submenu: false
    },
    {
        label: "Resources",
        path: "#",
        ref: "Resources",
        icon: NewResource,
        submenu: true,
        subMenuData: [
            {
                label: "Legal provisions",
                path: "/legal-provisions",
                ref: "legal-provisions",
                icon: iconSub1,
            },
            {
                label: "Local support structures",
                path: "/local-support-structures",
                ref: "local-support-structures",
                icon: iconSub2,
            },
            {
                label: "Occupational health and safety",
                path: "/occupational-health-safety",
                ref: "occupational-health-safety",
                icon: iconSub3,
            },
            {
                label: "Social protection programs and benefits",
                path: "/social-protection-programs-benefits",
                ref: "social-protection-programs-benefits",
                icon: iconSub4,
            },
            {
                label: "Financial literacy",
                path: "/financial-literacy",
                ref: "financial-literacy",
                icon: iconSub5,
            },
            {
                label: "Skills Training",
                path: "/skills-training",
                ref: "skills-training",
                icon: iconSub6,
            },
        ]
    },
    {
        label: "Get Help",
        path: "/gethelp",
        ref: "gethelp",
        icon: Newhelp,
        submenu: false
    },
    {
        label: "Events",
        path: "/events",
        ref: "events",
        icon: Newevent,
        submenu: false
    },
    {
        label: "Action Alerts",
        path: "/notification",
        ref: "notification",
        icon: alert,
        submenu: false
    },
    {
        label: "Newsfeed",
        path: "/newsfeed",
        ref: "Newsfeed",
        icon: news,
        submenu: false
    },
    {
        label: "Stories",
        path: "/stories",
        ref: "Stories",
        icon:story,
        submenu: false
    },

    {
        label: "Gallery",
        path: "/gallery",
        ref: "Gallery",
        icon: NewGallary,
        submenu: false
    },



    {
        label: "Toolkits",
        path: "/toolkits",
        ref: "Toolkits",
        icon: tool,
        submenu: false
    },

]