import React, { useContext } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalContext } from '../Context'
import AuthContext from '../Utils/auth-context'
import { DeleteUser } from '../Utils/services'

const DeleteProfile = () => {
  const [deleteText, setDeleteText] = useState('')
  const [error, setError] = useState('')
  const { logout } = useContext(AuthContext)
  const modalContext = useContext(ModalContext)
  const { closeModal, handleModalData } = modalContext
  const { t } = useTranslation(['home'])

  const HandleDelete = async () => {
    if (deleteText === 'Delete') {
      const result = await DeleteUser()
      logout()
      closeModal()
      setError('')
    } else {
      setError('text does not matched')
    }
  }

  return (
    <div>
      <h3 className="font-semibold">{`${t('Confirm Account Deletion')}`}</h3>
      <p>
        {`${t(
          'Once you confirm deletion then the content related to your account will get deleted and will be irrecoverable.',
        )}`}
      </p>
      <br />
      <p>
      {`${t('Please type in ')}`}<span className="font-semibold">Delete</span> to confirm
        the account deletion step
      </p>
      <input
        value={deleteText}
        type="text"
        className="DeleteInput mt-4"
        placeholder={`${t('Delete')}`}
        onChange={(e) => setDeleteText(e.target.value)}
      />

      <p className="error">{error}</p>
      <div className="flex justify-center mt-4">
        <button className="delete-btn mr-8" onClick={() => HandleDelete()}>
          {`${t('Delete')}`}
        </button>
        <button className="cancel-btn" onClick={() => closeModal()}>
          {`${t('Cancel')}`}
        </button>
      </div>
    </div>
  )
}

export default DeleteProfile
