import React, { useContext } from 'react'
import { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'

import Forum from './Forum'
import ForumRightSidebar from './ForumRightSidebar'
import suggesticon from '../../Assets/images/icons/suggest.png'
import { ModalContext } from '../../Context'
import SuggestModal from '../../ModalTemplate/SuggestModal'
import { useTranslation } from 'react-i18next'

const ForumPageMain = () => {
  const [filterFourm, setFilterForum] = useState('')
  const [selectedcourse, setselectedCourse] = useState([])
  const [tagFilter, setTagFilter] = useState('')
  const modalContext = useContext(ModalContext)
  const { closeModal, handleModalData } = modalContext
  const { t } = useTranslation(['home'])

  const handleSuggestmodal = () => {
    const SUGGEST = <SuggestModal />
    handleModalData(SUGGEST, 'sm')
  }

  return (
    <>
      <Container className="formcontainer">
        <Row>
          <Col lg={7} className="leftbar">
            <Forum
              filterFourm={filterFourm}
              tagFilter={tagFilter}
              setFilterForum={setFilterForum}
              setTagFilter={setTagFilter}
              selectedcourse={selectedcourse}
              setselectedCourse={setselectedCourse}
            />

            <div className="flex w-100 justify-center items-center mt-4">
              <img src={suggesticon} alt="" className="w-6 h-6 " />
              <div className="ml-4">
                <p className="">{`${t(
                  "Couldn't find any forum that you like",
                )}`}</p>
                <span
                  className="underline cursor-pointer suggest"
                  onClick={handleSuggestmodal}
                >{`${t('Suggest A Topic')}`}</span>
              </div>
            </div>
          </Col>
          <Col lg={5} className="rightbar">
            <ForumRightSidebar
              setFilterForum={setFilterForum}
              setTagFilter={setTagFilter}
              selectedcourse={selectedcourse}
              setselectedCourse={setselectedCourse}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ForumPageMain
