import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { ModalContext } from "../../Context";
import BlockUser from "../../ModalTemplate/BlockUser";
import { BASE_URL_IMG, blockUserList, getProfile } from "../../Utils/services";
import { useTranslation } from "react-i18next";
import UserProfileReport from "../../ModalTemplate/UserprofileReport";

const UserProfileContainer = ({ deleted }) => {
  const [togaleBlock, setTogleBlock] = useState(false);
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const admin_id = localStorage.getItem("adminId");
  const { t } = useTranslation(["home"]);

  const location = useLocation();
  const Id = location && location.state && location.state.Id;
  const [profileDetails, setProfileDetails] = useState([]);

  const getProfileDetails = async (id) => {
    let result = await getProfile({
      id: id,
    });

    setProfileDetails(result.res.profile);
  };

  useEffect(() => {
    if (Id) {
      getProfileDetails(Id);
    }
  }, []);

  const handleblock = () => {
    const block = (
      <BlockUser
        id={Id}
        setTogleBlock={setTogleBlock}
        togaleBlock={togaleBlock}
      />
    );
    handleModalData(block, "sm");
  };

  const handleBlockList = async () => {
    const result = await blockUserList(admin_id);

    if (result.res.success) {
      result.res.data.filter((item) => {
        if (item === Id) {
          setTogleBlock(true);
        }
      });
    }
  };

  useEffect(() => {
    handleBlockList();
  }, []);

  const handleModal = () => {
    const REPORT_USER_COMMENT = <UserProfileReport threadId={Id} />;
    handleModalData(REPORT_USER_COMMENT, "sm");
  };

  return (
    <>
      <Row>
        <Col>
          <div className="profilecontainer mt-4 flex">
            <div className="w-1/4 profilesidebar">
              <div className="aliasprofile flex  flex-column items-center">
                <div className="w-24 flex justify-center mt-16">
                  <img
                    src={`${BASE_URL_IMG}/user/${Id}/${Id}.jpeg`}
                    alt=""
                    className="w-100 cursor-pointer"
                  />
                </div>

                <h2 className="pt-4">
                  {profileDetails?.aliasName || "Alias Name"}
                </h2>
                <h3>{profileDetails?.sector?.name || "Sector "} </h3>
                <h4>{profileDetails?.country?.name || "Country"} </h4>
              </div>
            </div>
            <div className="w-3/4 profilemainbar ">
              <div className="px-12 mt-4">
                <div className="user-profile pt-3">
                  <div className="my-4">
                    <h2>{`${t("Profile Details")}`}</h2>
                  </div>

                  <div className="bg-white ">
                    <label htmlFor="">
                      <span className="text-blue">User Name / Alias name</span>{" "}
                    </label>
                    <span className="ml-4">{profileDetails?.aliasName}</span>
                  </div>
                  <div className="bg-white mt-4 ">
                    <label htmlFor="">
                      {" "}
                      <span className="text-blue">Country</span>
                    </label>
                    <span className="ml-4">
                      {profileDetails?.country?.name || "Country"}{" "}
                    </span>
                  </div>
                  <div className="bg-white mt-4 ">
                    <span className="text-blue">Sector</span>{" "}
                    <span className="ml-4">
                      {profileDetails?.sector?.name || "Sector "}
                    </span>
                  </div>

                  <div className="flex mt-12 AS_Report">
                    <button
                      className="blockbtn mr-4 mb-2"
                      onClick={handleblock}
                    >
                      {!togaleBlock ? " Block" : "Unblock"}
                    </button>
                    <div className="logout">
                      <button className="logout" onClick={handleModal}>
                        Report user
                      </button>
                    </div>
                    {/* <button className='report ml-8'>Report</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UserProfileContainer;
