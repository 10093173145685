import React, { useEffect } from "react";
import Picker from "emoji-picker-react";
import emoji from "../../../Assets/images/gethelp/smile.png";
import mic from "../../../Assets/images/gethelp/microphone.png";
import mic2 from "../../../Assets/images/gethelp/mute.png";
import Send_Icon from "../../../Assets/images/gethelp/Send_Icon.svg";
import { useState } from "react";
import vmsg from "vmsg";

const recorder = new vmsg.Recorder({
  wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm",
});

const ChatInput = ({
  handleSendMsg,
  getmessageDataApi,
  recordings,
  setrecordings,
}) => {
  const [showEmoji, setShowEmoji] = useState(false);
  const [msg, setMsg] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [isRecording, setisRecording] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const handleEmojiPickerHideAndShow = () => {
    setShowEmoji(!showEmoji);
  };

  const onEmojiClick = (event) => {
    let message = msg;
    message += event.emoji;
    setMsg(message);
  };

  const sendChat = (event) => {
    event.preventDefault();
    if (msg.trim().length > 0) {
      if (event.shiftKey && event.key === "Enter") {
        // If Shift + Enter is pressed, insert a newline character
        setMsg(msg + "\n");
      } else {
        handleSendMsg(msg);
        setMsg("");
      }
    } else if (recordings) {
      handleSendMsg();
      setrecordings(null);
    }
  };

  const showPostDetail = (record) => {
    record();
  };
  const handleFileSelect = () => {
    setrecordings();
  };

  const record = async () => {
    setisLoading(true);
    if (isRecording) {
      const blob = await recorder.stopRecording();
      const msgurl = blob;
      setisLoading(false);
      setisRecording(false);
      setSeconds(0);
      setMinutes(0);
      setrecordings(msgurl);
    } else {
      try {
        await recorder.initAudio();
        await recorder.initWorker();
        recorder.startRecording();
        setisLoading(false);
        setisRecording(true);
      } catch (e) {
        console.error(e);
        setisLoading(false);
      }
    }
  };

  useEffect(() => {
    let timeoutId;

    const tick = () => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 59) {
          setMinutes((prevMinutes) => prevMinutes + 1);
          return 0;
        } else {
          return prevSeconds + 1;
        }
      });
      timeoutId = setTimeout(tick, 1000);
    };

    if (isRecording) {
      tick();
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isRecording]);

  return (
    <>
      <form action="" onSubmit={sendChat}>
        <div className="border-t-2 p-4 md:p-8 flex flex-col md:flex-row justify-between AS_gh_inputdiv">
          <div className="md:w-3/4 md:mr-4 bg-grey mb-4 md:mb-0">
            <div className="pt-2 px-4 flex justify-between items-center AS_gh_chatinput">
              <div className="flex items-center">
                <img
                  src={emoji}
                  alt=""
                  onClick={handleEmojiPickerHideAndShow}
                  className="cursor-pointer"
                />
                <div className="ml-5">
                  {isRecording ? (
                    <div className="testMessageShowAudio">
                      <span>
                        Recording Started...
                        {minutes.toString().padStart(2, "0")}:
                        {seconds.toString().padStart(2, "0")}
                      </span>
                    </div>
                  ) : recordings ? (
                    <div className="testMessageShowAudio">
                      <span>Send Recorded Message ...</span>
                      <span
                        onClick={handleFileSelect}
                        className="crossbtn cursor-pointer"
                      >
                        X
                      </span>
                    </div>
                  ) : (
                    <textarea
                    rows={3}
                    cols={45}
                      type="text"
                      placeholder="Type Here"
                      value={msg}
                      className="AS_gh_input bg-transparent outline-none"
                      onChange={(e) => setMsg(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.shiftKey && e.key === "Enter") {
                          e.preventDefault(); // Prevent the default behavior (inserting a newline)
                          setMsg(msg + "\n");
                        }
                        if (!e.shiftKey && e.key === "Enter") {
                          sendChat(e)
                        }
                      }}
                    />
                  )}
                </div>
              </div>
              <button
                className="muteunmute"
                type="button"
                disabled={isLoading}
                onClick={() => showPostDetail(record)}
              >
                {isRecording ? (
                  <img src={mic} className="h-8"></img>
                ) : (
                  <img src={mic2} className="h-8"></img>
                )}
              </button>
            </div>
          </div>
          <div className="chatbtn">
            <button type="submit" className=" AS_mobile_button as_mobile">
              <div>
                <img src={Send_Icon} alt="Send_Icon" />
              </div>
            </button>
            <button type="submit" className="as_web">
              Send
            </button>
          </div>
        </div>
      </form>
      {showEmoji && (
        <Picker
          preload
          emojiUrl="https://cdn.jsdelivr.net/npm/emoji-datasource@6.0.1/img/apple/sheets/32.png"
          onEmojiClick={onEmojiClick}
        />
      )}
    </>
  );
};

export default ChatInput;
