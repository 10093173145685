import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Filter from '../../Common/Filter'
import SearchTool from '../../Common/SearchHeader/SearchTool'
import {
  getStories,
  storyFilterApi,
  storySearchApi,
} from '../../Utils/services'
import FeatureStory from './FeatureStory'
import StoryMain from './StoryMain'
import Spinner from 'react-bootstrap/Spinner'
import { useTranslation } from 'react-i18next'

const Story = () => {
  const [featurestory, setFeatureStory] = useState([])
  const [story, setStory] = useState([])
  const [filter, setFilter] = useState('')
  const [filterTag, setFilterTag] = useState('')
  const [selectedcourse, setselectedCourse] = useState([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation(['home'])

  const getStoryData = async () => {
    if (selectedcourse.length > 0) {
      setLoading(true)
      const result = await getStories({
        name: selectedcourse,
      })
      setLoading(false)
      setFeatureStory(result.res)
    } else if (filter) {
      setLoading(true)
      const result = await storySearchApi({
        query: filter,
      })
      setLoading(false)
      setFeatureStory(result.res.data.data)
    } else {
      setLoading(true)
      const result = await getStories()
      setLoading(false)
      let _fetureStory = result.res.filter((item) => item.isfeatured === true)
      setFeatureStory(_fetureStory)
      let _recommendedStory = result.res.filter(
        (item) => item.isfeatured === false,
      )
      setStory(_recommendedStory)
    }
  }

  useEffect(() => {
    getStoryData()
  }, [filterTag, filter, selectedcourse])


  return (
    <>
      <Container>
        <Row>
          <div className="mobilesearch mt-20">
            <SearchTool setFilterForum={setFilter} />
          </div>

          <Col lg={7} className="toolmainbar">
            {loading ? (
              <>
                <div className="loaderfixed">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </>
            ) : (
              <>
                {filter || selectedcourse.length > 0 ? (
                  <>
                    <Row className="mt-8">
                      {featurestory &&
                        featurestory.map((item) => (
                          <Col lg={12} md={12} sm={12}>
                            <FeatureStory
                              item={item}
                              getStoryData={getStoryData}
                            />
                          </Col>
                        ))}

                      {featurestory.length == 0 && (
                        <h3 className="text-center nopost mt-4">No Story</h3>
                      )}
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col lg={12}>
                        <div className="storyMains pt-10">
                          <h3>
                            {`${t('Featured')}`} {`${t('Stories')}`}
                          </h3>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {featurestory &&
                        featurestory.map((item) => (
                          <Col lg={12} md={12} sm={12}>
                            <FeatureStory
                              item={item}
                              getStoryData={getStoryData}
                            />
                          </Col>
                        ))}
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="storyMains pt-2">
                          <h3>{`${t('Recommended for you')}`}</h3>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {story &&
                        story.map((item) => (
                          <Col lg={6} md={6} sm={6}>
                            <StoryMain
                              item={item}
                              getStoryData={getStoryData}
                            />
                          </Col>
                        ))}
                    </Row>
                  </>
                )}
              </>
            )}
          </Col>
          <Col lg={5} className="toolsidebar">
            <Filter
              setGalleryFilter={setFilter}
              setFilterTag={setFilterTag}
              selectedcourse={selectedcourse}
              setselectedCourse={setselectedCourse}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Story
