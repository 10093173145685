import React, { useContext } from 'react'
import { useState } from 'react';
import PhoneInput , { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber }from 'react-phone-number-input'
import { ModalContext } from '../Context';
import { editProfileApi } from '../Utils/services';


const Phone = ({number,getProfileDetails}) => {
    const [value,setValue] =useState(number)
    const [valid,setValid] =useState("");
    const [errors, setErrors] = useState("");
    const admin_id = localStorage.getItem('adminId');
    const modalContext = useContext(ModalContext);
    const countryCode =localStorage.getItem("country")
    const { closeModal} = modalContext;
    
   




   const handleMobileNumber =() =>{
     let number =formatPhoneNumber(value);
     let numberstring =number.substring(1).replace(/\s+/g, "")
     let numberpars =JSON.parse(numberstring)
     return numberpars;
   }





 

    const editProfiles = async() =>{
      try{      
        
         if(value && isValidPhoneNumber(value)){
                
           const result =await editProfileApi({
            "id": admin_id,
            "phone":handleMobileNumber(),
            "code" :countryCode,
            "isverify":false                
           })
            getProfileDetails()
            closeModal()

        }

        else{
          setErrors("phone number is not valid")
        }
        
        // getProfileDetails()
     
  
      }
      catch(error){
        //console.error((error,"error")
      }
      

  
  
    }




   






  return (
    <>
         <div className='phoneNumberbg'>

          <PhoneInput
          placeholder="Enter phone number"
          value={value}
          onChange={setValue}/>
            
         </div>
          <div className='text-danger'>{errors } </div>
  
    
          <div className='mt-4 flex justify-center '>
          <button  onClick={()=>editProfiles()} className="submitbtn" >Submit</button>
          </div>
 
    </>
  )
}

export default Phone