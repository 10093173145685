import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import {
  getAboutusApi,
  getAboutUsApi,
  getCodeOfConductApi,
  getCodeOfffConductApi,
  getDesclaimerApi,
  getDesclaimerUsApi,
  getFaqApi,
} from "../../Utils/services";
import { useNavigate } from "react-router";
import { useContext } from "react";
import { ModalContext } from "../../Context";
import BlockUser from "../../ModalTemplate/BlockUser";

const ProfileAccordian = ({ blockusers }) => {
  const [open, setOpen] = useState("0");
  const [about, setAbout] = useState("");
  const [desclaimer, setDesclaimer] = useState("");
  const [codeConduct, setCodeConduct] = useState("");
  const [codeFaq, setFaq] = useState("");
  const { t } = useTranslation(["home"]);
  let navigate = useNavigate()
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const handleAboutUs = async () => {
    const result = await getAboutusApi();
    setAbout(result?.res?.data?.about[0]?.about);
  };

  const handleDesclaimer = async () => {
    const result = await getDesclaimerUsApi();
    setDesclaimer(result?.res?.data?.disclaimer[0]?.Disclaimer);
  };
  const handleCodeOfConduct = async () => {
    const result = await getCodeOfffConductApi();
    setCodeConduct(result?.res?.data?.conduct[0]?.conduct);
  };

  const handleFaq = async () => {
    const result = await getFaqApi();
    setFaq(result?.res?.data?.faq);
  };

  useEffect(() => {
    handleAboutUs();
    handleDesclaimer();
    handleCodeOfConduct();
    handleFaq();
  }, []);


  const handleuserProfile = (item) => {
    navigate(`/userprofile`,{
        state: {
          Id: item._id,
        },
      })
  }
  const resetpassword = (item) => {
    navigate(`/resetpassword`)
  }


  // =======================Unblock User ======================================
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const [togaleBlock,setTogleBlock] =useState(true)
  const handleblock = (Id) =>{
    const block = <BlockUser   id={Id}  setTogleBlock ={setTogleBlock}  togaleBlock={togaleBlock} />
    handleModalData(block,"sm")

  }


















  return (
    <div className="pt-5">
      <Accordion open={open} toggle={toggle}>
        <AccordionItem>
          <AccordionHeader targetId="1">{`${t("About Us")}`}</AccordionHeader>
          <AccordionBody accordionId="1">
            {about ? (
              <div
                className=" h-52 overflow-auto"
                dangerouslySetInnerHTML={{ __html: about }}
              />
            ) : (
              <div>{`${t("No Data Found")}`}</div>
            )}
          </AccordionBody>
        </AccordionItem>
      </Accordion>

      <Accordion open={open} toggle={toggle} className="mt-3">
        <AccordionItem>
          <AccordionHeader targetId="2">{`${t("Disclaimer")}`}</AccordionHeader>
          <AccordionBody accordionId="2">
            {desclaimer ? (
              <div
                className=" h-52 overflow-auto"
                dangerouslySetInnerHTML={{ __html: desclaimer }}
              />
            ) : (
              <div>{`${t("No Data Found")}`}</div>
            )}
          </AccordionBody>
        </AccordionItem>
      </Accordion>

      <Accordion open={open} toggle={toggle} className="mt-3" >
        <AccordionItem>
          <AccordionHeader targetId="3">{`${t(
            "Code Of Conduct"
          )}`}</AccordionHeader>
          <AccordionBody accordionId="3" >
            {codeConduct ? (
              <div
                className=" h-52 overflow-auto"
                style={{background:"none"}}
                dangerouslySetInnerHTML={{ __html: codeConduct }}
              />
            ) : (
              <div>{`${t("No Data Found")}`}</div>
            )}
          </AccordionBody>
        </AccordionItem>
      </Accordion>

      {/* 
      Block User Drop Down 
      */}

      <Accordion open={open} toggle={toggle} className="mt-3">
        <AccordionItem>
          <AccordionHeader targetId="4">{`${t("Block User")}`}</AccordionHeader>
          <AccordionBody accordionId="4">
            <div className="h-52 overflow-auto" >
              {blockusers.length > 0 ? (
                blockusers &&
                blockusers?.map((item) => {
                  return (
                    <>
                      <div className="flex justify-between p-2 items-center mb-2 "  >
                        <div className="flex items-center AS_block_user">
                          <img
                          className="mr-2 w-9 h-9 rounded-full cursor-pointer"
                            src={`https://d1v4waqh4od8qe.cloudfront.net/user/${item._id}/${item._id}.jpeg`}
                            onClick={() => handleuserProfile(item)}
                            alt="....."
                          />
                          <div>{item.aliasName}</div>
                        </div>

                        <div>
                          <div className="logout">
                            <button
                            style={{width:"100%",height:"100%",padding:"9px"}}
                              className="AS_profife_Button"
                              onClick={()=>handleblock(item._id)}
                            >{`${t(!togaleBlock ? 'Block' : 'Unblock')}`}</button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div>{`${t("No Data Found")}`}</div>
              )}
            </div>
          </AccordionBody>
        </AccordionItem>
      </Accordion>

      <Accordion open={open} toggle={toggle} className="mt-3">
        <AccordionItem>
          <AccordionHeader targetId="5">{`${t("FAQ'S")}`}</AccordionHeader>
          <AccordionBody accordionId="5">
            <div className="h-52 overflow-auto">
              {codeFaq ? (
                codeFaq &&
                codeFaq?.map((item) => {
                  return (
                    <>
                      <h5 className="text-blue">{item.quation}</h5>
                      <p
                        className=""
                        dangerouslySetInnerHTML={{ __html: item.answer }}
                      />
                    </>
                  );
                })
              ) : (
                <div>{`${t("No Data Found")}`}</div>
              )}
            </div>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
      <Accordion open={0} toggle={resetpassword} className="mt-3">
        <AccordionItem>
          <AccordionHeader targetId="6">{`${t("Reset Password")}`}</AccordionHeader>

        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default ProfileAccordian;
