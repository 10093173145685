import React from "react";
import { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Filter from "../../Common/Filter";
import SearchFilter from "../../Common/SearchFilter";
import SearchTool from "../../Common/SearchHeader/SearchTool";
import Notify from "./Notify";

import { BASE_URL_IMG, getTagFormService } from "../../Utils/services";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const userName = localStorage.getItem("adminName");
  const userNameID = localStorage.getItem("adminId");
  const { t } = useTranslation(["home"]);
  const [filter, setFilter] = useState("");
  let navigate = useNavigate()

  return (
    <Container>
      <Row>
        {/* <div className="mobilesearch mt-20">
            <SearchTool setFilterForum={setFilter} />
        </div> */}
        <Col lg={7} className="skillmain mt-3"  >
          <Notify filter={filter} />
        </Col>
        <Col lg={5} className="skillsidebar">
        <div className="rightSideBar">
            <div className="flex items-center mb-4 mt-1" onClick={()=>navigate('/profile')}>
               <div className="w-10 mr-2 cursor-pointer">
               <img src={`${BASE_URL_IMG}/user/${userNameID}/${userNameID}.jpeg`} className=""   alt="" />
          </div>
          <div>
            <h3 className="aliasname cursor-pointer" onClick={()=>navigate('/profile')}> { userName ||"Alias Name"}</h3>
            <p className="underline text-blue cursor-pointer" onClick={()=>navigate('/profile')}>View Profile</p>
          </div>
        </div>
        </div>
          {/* <SearchFilter setGalleryFilter={setFilter} /> */}
        </Col>
      </Row>
    </Container>
  );
};

export default Notification;
