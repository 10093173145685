import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Container, Row, Col } from "reactstrap";
import { editProfileApi, getProfile } from "../../Utils/services";

import ProfileAlias from "./ProfileAlias";
import ProfileDetails from "./ProfileDetails";
import { useTranslation } from "react-i18next";
import { ModalContext } from "../../Context";

const ProfileContainer = () => {
  const { i18n } = useTranslation(["home"]);
  const [proFile, setProFile] = useState({});
  const [blockusers, setblockusers] = useState([]);
  const [fullName, setFullName] = useState();
  const [aliasName, setAliasName] = useState();
  const [language, setLanguage] = useState();
  const [age, setAge] = useState();
  const [gender, setGender] = useState();
  const [code, setCode] = useState();
  const [sectordata, setSectordata] = useState("");
  const [profileId, setProfileId] = useState("");
  const admin_id = localStorage.getItem("adminId");
  const [errors, setErrors] = useState({});
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const getProfileDetails = async () => {
    let result = await getProfile();
    setblockusers(result.res.blockedUserlist);
    setProFile(result.res.profile);
    setFullName(result.res.profile.name);
    setAliasName(result.res.profile.aliasName);
    setLanguage(result.res.profile.language.name);
    setAge(result.res.profile.age);
    setGender(result.res.profile.gender);
    setSectordata(result?.res?.profile?.sector?.name);
    setProfileId(result?.res?.profile);
    setCode(result.res.country_code);
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const editProfile = async () => {
    try {
      if (handleValidation()) {
        const result = await editProfileApi({
          id: admin_id,
          name: fullName,
          aliasName: aliasName.replaceAll(" ", ""),
          sector: sectordata,
          language: language,
          age: age,
          gender: gender,
        });
        getProfileDetails();
        localStorage.setItem("lng", language);
        // getProfileDetails();
        changeLanguage(language);
        window.location.reload();
        closeModal();
      } else {
        if (errors.title) {
          toast.error(errors.title);
        } else if (errors.aliasName) {
          toast.error(errors.aliasName);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  const hardRefresh = () => {
    const t = setTimeout(() => {
      window.location.reload();
    }, 3000);

    return () => {
      clearTimeout(t);
    };
  };

  const handleValidation = () => {
    let _errors = {};
    let formIsValid = true;
    if (!fullName) {
      formIsValid = false;
      _errors["title"] = "Please enter the full name";
    } else if (fullName.trim() === "") {
      formIsValid = false;
      _errors["title"] = "Please enter the full name";
    } else if (!aliasName) {
      formIsValid = false;
      _errors["title"] = "Please enter the alias name";
    } else if (aliasName.trim() === "") {
      formIsValid = false;
      _errors["title"] = "Please enter the alias name";
    }

    setErrors(_errors);
    return formIsValid;
  };

  return (
    <>
      <Row className="">
        <Col>
          <div className="profilecontainer mt-4 flex">
            <div className="w-1/4 profilesidebar">
              <ProfileAlias
                proFile={proFile}
                getProfileDetails={getProfileDetails}
                hardRefresh={hardRefresh}
              />
            </div>
            <div className="w-3/4 profilemainbar ">
              <ProfileDetails
                proFile={proFile}
                blockusers={blockusers}
                fullName={fullName}
                setFullName={setFullName}
                aliasName={aliasName}
                setAliasName={setAliasName}
                language={language}
                setLanguage={setLanguage}
                age={age}
                setAge={setAge}
                gender={gender}
                setGender={setGender}
                sectordata={sectordata}
                setSectordata={setSectordata}
                editProfile={editProfile}
                code={code}
                getProfileDetails={getProfileDetails}
                errors={errors}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ProfileContainer;
