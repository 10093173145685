import React, { useState } from "react";
import { languageMap } from "../Constant";
import Play from "../Assets/images/play.svg";
import { franc } from 'franc-min';

const TexttoSpeach = ({ text = "", discription = "" }) => {
    const [isPaused, setIsPaused] = useState(false);
    const [utterance, setUtterance] = useState(null);

    const handlePlay = () => {
        if ('speechSynthesis' in window) {
            const synth = window.speechSynthesis;
            const combinedText = stripHtml(text + discription);
            const u = new SpeechSynthesisUtterance(combinedText);

            // Detect language using franc
            const langCode = franc(combinedText);
            const language = languageMap[langCode] || "en-US"; // Set language

            u.lang = language;
            setUtterance(u);

            // Stop any ongoing speech synthesis
            synth.cancel();

            if (isPaused && synth.speaking) {
                synth.resume();
                setIsPaused(false);
            } else {
                synth.speak(u);
                setIsPaused(false);
            }

            // Add event listener to update isPaused state when speech ends
            u.onend = () => {
                setIsPaused(false);
                setUtterance(null);
            };
        } else {
            console.error("Text-to-Speech not supported in this browser.");
        }
    };

    // Function to strip HTML tags from the description
    const stripHtml = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    return (
        <div onClick={handlePlay} className="playiconContainer">
            {isPaused ? "Resume" : <img height={10} width={10} src={Play} alt="play" />}
        </div>
    );
};

export default TexttoSpeach;
