import React from "react";
import { Route, Routes } from 'react-router-dom';
import { routes } from "../../routes";

const MainWrapper = ({ handleModalData, isModalOpen, modalData }) => {
  return (
    <Routes>
      {routes.map((route, i) =>{
        return(
        <Route
          key={i}
          path={route.path}
          element={
            <route.component
              modalData={modalData}
              handleModalData={handleModalData}
              isModalOpen={isModalOpen}
            />
          }
        />
      )})}
    </Routes>
  );
};

export default MainWrapper;

// import React, { Suspense } from "react";
// import { Route, Routes } from "react-router-dom";
// import ForumPageMain from "../Forum";
// import UserProfile from "../Profile";

// const MainWrapper = () => {
//   return (
//     <div className="main-wraper">
//       <Suspense fallback={"loading..."}>
//         <Routes>
//           <Route exact path="/" element={<ForumPageMain />} />
//           <Route path="/profile" element={<UserProfile />} />
//         </Routes>
//       </Suspense>
//     </div>
//   );
// };

// export default MainWrapper;
