import React, { useContext, useState } from 'react'
import { ModalContext } from '../Context';
import inputbtn from "../Assets/images/Social/inputbtn.png"
import { galleryEditapi } from '../Utils/services';

const EditReply = ({id, CommentId, handlegetComment,commentdata,ReplyId,PostId}) => {
 const [comment,setcoment] =useState(commentdata);
 const modalContext = useContext(ModalContext);
 const { closeModal} = modalContext;

 const EditComment = async (e) => {
    e.preventDefault();
   const result = await galleryEditapi({
     "postId": PostId,
     "commentId":CommentId,
     "contents": comment,
     "replyId" :ReplyId

    });
     handlegetComment(PostId);
     closeModal();    
 } 




  return (
    <form action="" onSubmit={EditComment} >
    <div id="focusinput" className="flex justify-between border p-2 my-3 rounded-xl">
     <input type="text" 
      value={comment}
      placeholder="Edit comment"
      className="commentinput"
      onChange={(e) => setcoment(e.target.value)}  required/>
     <button className="w-10 " ><img src={inputbtn} alt="" className="w-5" /></button>
    </div>
    </form>
  )
}

export default EditReply