import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ProfileAccordian from './ProfileAccordian'
import ProfileUserDetail from './ProfileUserDetail'

const ProfileDetails = ({
  blockusers,
  proFile,
  fullName,
  setFullName,
  aliasName,
  setAliasName,
  language,
  setLanguage,
  age,
  setAge,
  gender,
  setGender,
  sectordata,
  setSectordata,
  editProfile,
  code,
  getProfileDetails,
  errors,
}) => {
  const [otherBtn, setOtherBtn] = useState(false)
  const [details, setDetails] = useState(true)
  const { t } = useTranslation(['home'])

  const handDetailsBtn = () => {
    setOtherBtn(false)
    setDetails(true)
  }

  const handOtherBtn = () => {
    setOtherBtn(true)
    setDetails(false)
  }

  return (
    <div>
      <div className="profiledetails">
        <button
          className={`w-1/2 h-12  ${details && 'activebtn shadow-md  '}`}
          onClick={handDetailsBtn}
        >
          {`${t('Details')}`}
        </button>
        <button
          className={`w-1/2 h-12  ${otherBtn && 'activebtn shadow-md '}`}
          onClick={handOtherBtn}
        >
          {`${t('Other')}`}
        </button>
      </div>
      {otherBtn && (
        <div className="px-12 mt-4">
          <ProfileAccordian blockusers={blockusers} />
        </div>
      )}

      {details && (
        <div className="px-12 mt-4">
          <ProfileUserDetail
            proFile={proFile}
            fullName={fullName}
            setFullName={setFullName}
            aliasName={aliasName}
            setAliasName={setAliasName}
            language={language}
            setLanguage={setLanguage}
            age={age}
            setAge={setAge}
            gender={gender}
            setGender={setGender}
            sectordata={sectordata}
            setSectordata={setSectordata}
            editProfile={editProfile}
            code={code}
            getProfileDetails={getProfileDetails}
            errors={errors}
          />
        </div>
      )}
    </div>
  )
}

export default ProfileDetails
