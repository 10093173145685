import React, { useContext } from "react";
import banner from "../../../Assets/images/Social/image.png";
import { AnimatePresence, motion } from "framer-motion";
import hr from "../../../Assets/images/Social/hrtwo.svg";
import comment from "../../../Assets/images/Social/comments.svg";
import diamond from "../../../Assets/images/Social/dimond2.svg";
import diamond2 from "../../../Assets/images/Social/diomand3.svg";
import InputText from "../../../Common/InputText";
import {
  TRANSLATE_URL,
  addCommentForum,
  formServiceapi,
  getBlockUserData,
  getComments,
  likeandunlikeservice,
} from "../../../Utils/services";

import { BASE_URL_IMG } from "../../../Utils/services";
import TimeAgo from "react-timeago";

import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import ForumInnerComment from "./ForumInnerComment";
import { ModalContext } from "../../../Context";
import VerifyNumber from "../../../ModalTemplate/VerifyNumber";
import { useTranslation } from "react-i18next";
import { Globalinformation } from "../../../Common/MainSectionProvider";
import { useLocation, useParams } from "react-router-dom";
import TexttoSpeach from "../../TexttoSpeach";
import Shareicon from "../../ShareFunction";

const ForumInnerPage = () => {
  const [userData, setUserData] = useState([]);
  const [forumDetails, setFormDetails] = useState({});
  const [addcomment, setAddcomment] = useState();
  const [loader, setLoader] = useState(false);
  const [forumComment, setForumComment] = useState([]);
  const [forumUser, setForumUser] = useState([]);
  const [userReply, setUserReply] = useState([]);

  const [qusTion, setQustion] = useState("");
  const [content, setContent] = useState("");
  const [originalqusTion, setoriginalqusTion] = useState("");
  const [translatedcontent, settranslatedcontent] = useState("");
  const [isTranslated, setisTranslated] = useState(false);
  const { translateLanguage } = useContext(Globalinformation);
  const [blockData, setBlockData] = useState([]);
  const admin_id = localStorage.getItem("adminId");

  const { t } = useTranslation(["home"]);

  function getText(html) {
    var tmp = document.createElement("div");
    tmp.innerHTML = html;

    return tmp.textContent || tmp.innerText;
  }

  const handleChangeButton = () => {
    setisTranslated(!isTranslated);
    if (translatedcontent === "" && qusTion === "") {
      let toLang = translateLanguage; // translate to norwegian
      let text = `${forumDetails?.question}`;

      let textContent = `${getText(content)}`;
      let url = TRANSLATE_URL;
      url += "&q=" + encodeURI(text);
      url += "&q=" + encodeURI(textContent);
      url += `&target=${toLang}`;
      const token = localStorage.getItem("token");

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`, // notice the Bearer before your token
        },
      })
        .then((res) => res.json())
        .then((response) => {
          console.log(response.data.translations);
          setQustion(response.data.translations[0].translatedText);
          settranslatedcontent(
            response.data.translations[1].translatedText === ""
              ? false
              : response.data.translations[1].translatedText
          );
          //console.log((response)
        })
        .catch((error) => {
          //console.log(('There was an error with the translation request: ', error)
        });
    }
  };
  const location = useLocation();

  const [hasClicked, setHasClicked] = React.useState(false);//location.state.hasClicked
  const [Totallikes, settotallikes] = useState(0);

  //console.log((location,"location");
  const { slug: id } = useParams();
  const times = new Date(forumDetails?.date);
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;
  const [formInnerImage, setFormInnerImage] = useState();

  const IsVerify = localStorage.getItem("phoneverification");

  const formservicedata = async () => {
    try {
      //console.log((id);
      const data = await formServiceapi({ id: id })
      // console.log(data.res.data.threads[0],"data");
      const Fourm = data.res.data.threads[0];
      // const Fourm = data.res.data.threads[0];
      setFormDetails(Fourm);
      settotallikes(Fourm.likes.length)
      setContent(Fourm.content);
      // settotallikes(Fourm.likes.length)
      setFormInnerImage(Fourm?.media[0]?.link);
      if (data.res.data.userDetails) {
        if (data.res.data.userDetails.likedThreads.includes(id)) {
          setHasClicked(true)
        }
        setUserData(data.res.data.userDetails.likedThreads);
      }
      //console.log((Fourm.media[0].link, 'working')
    } catch (error) { }
  };

  // //console.log((id,"himansu")
  //like and dislike //

  const handlelike = async () => {
    try {
      const result = await likeandunlikeservice({
        threadId: id,
        commentId: null,
      });
      // formservicedata()
      if (result.res.success) {
        // toast.success(result.res.message)
        if (!hasClicked) {
          settotallikes(Totallikes + 1);
        } else {
          settotallikes(Totallikes - 1);
        }
        setHasClicked(!hasClicked);
      }
      // if (result.res.success) {
      //   // toast.success(result.res.message)
      //   setHasClicked(!hasClicked)
      // }
    } catch (error) {
      //console.log((error)
    }
  };

  const handleVeriFication = () => {
    let VERIFYPHONE = <VerifyNumber />;
    handleModalData(VERIFYPHONE, "sm");
  };

  useEffect(() => { }, []);

  const handleAddcomment = async (event) => {
    event.preventDefault();
    if (addcomment && addcomment.length > 0) {
      if (IsVerify == "true") {
        setLoader(true);
        const result = await addCommentForum({
          threadId: id,
          comment: addcomment,
        });
        //console.log((result)

        // handlegetComment(postid)

        if (result.res.success) {
          setLoader(false);
          setAddcomment("");
          handlegetComment(id);
          formservicedata();
        }
      } else {
        handleVeriFication();
      }
    }
  };

  const handlegetComment = async (posstId) => {
    try {
      const result = await getComments(posstId);
      setForumComment(result.res.comments);
      setForumUser(result.res.users);
      setUserReply(result.res.user_reply);
      //  setCommentdata(result.res)
    } catch (error) {
      //console.log((error)
    }
  };

  useMemo(() => {
    userData &&
      userData.filter((item) => {
        if (item === id) {
          setHasClicked(true);
        }
      });
  }, [userData]);

  useEffect(() => {
    handlegetComment(id);
  }, []);

  useEffect(() => {
    //console.log((id,"id anubhav");
    if (id) {
      formservicedata();
    }
  }, [id]);

  const handleBLockData = async () => {
    //console.log(('check log data')
    const result = await getBlockUserData(admin_id);
    setBlockData(result?.res?.data);
    // //console.log((result, 'block')
  };

  useEffect(() => {
    handleBLockData();
  }, []);

  //  //console.log((translateLanguage,"check translate")

  return (
    <div className="my-8">
      <div class="card">
        <div class="card-body">
          <div className="form-image">
            <img
              src={`${BASE_URL_IMG}${formInnerImage}`}
              alt="userimage"
              className="socialimagedetails"
            />
            <button onClick={handleChangeButton}>{`${t("Translate")}`}</button>
          </div>
          <h5 class="card-title mt-2  text-justify formdetailstile  ">
            {isTranslated
              ? qusTion === ""
                ? forumDetails.question
                : qusTion
              : forumDetails?.question}{" "}
            {/* {qusTion ? qusTion : forumDetails?.question}{" "} */}
          </h5>
          <p class="card-text formdetailstags mb-2 mt-2 text-xsm ">
            {`${t("Tags")}`}:{" "}
            {forumDetails &&
              forumDetails.tag &&
              forumDetails.tag.map((u) => u.name).join(", ")}{" "}
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html:
                isTranslated && content !== ""
                  ? (translatedcontent === ""
                    ? content
                    : translatedcontent)
                  : content,
            }}
          />
          <TexttoSpeach text={isTranslated
            ? qusTion === ""
              ? forumDetails.question
              : qusTion
            : forumDetails?.question}

            discription={
              isTranslated && content !== ""
                ? (translatedcontent === ""
                  ? content
                  : translatedcontent)
                : content
            }

          />

          <div className="flex mt-4 socialbtn ">
            <p className="mr-2 flex items-center h-5 cursor-pointer">
              <motion.div
                whileTap={{ scale: 4 }}
                transition={{ duration: 0.5 }}
                onClick={() => handlelike()}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={hasClicked ? diamond : diamond2}
                  alt=""
                  className="h-4"
                />
              </motion.div>
              <span className="px-2">
                {Totallikes} {Totallikes > 1 ? "Likes" : "Like"}{" "}
              </span>
            </p>
            <p className="mr-2 flex items-center h-5">
              {" "}
              <img src={comment} alt="" className="h-4 cursor-pointer" />{" "}
              <span className="px-2 cursor-pointer">
                {forumComment.length} {`${t("Comments")}`}
              </span>{" "}
            </p>
            <Shareicon link={window.location.origin + `/forum/${forumDetails?._id}`} />

            <p className="mr-2 flex items-center h-5">
              {" "}
              <img src={hr} alt="" className="h-4 cursor-pointer" />{" "}
              <span className="px-2 cursor-pointer">
                <TimeAgo date={times} />
              </span>
            </p>
          </div>
          <InputText
            handleAddcomment={handleAddcomment}
            setAddcomment={setAddcomment}
            addcomment={addcomment}
          />
          {loader ? (
            <Spinner type="grow" color="warning" children={false} />
          ) : (
            <>
              {forumComment &&
                forumComment.map((item, i) => (
                  <React.Fragment key={i}>
                    <div className={blockData.includes(item?.user) && `hidden`}>
                      <ForumInnerComment
                        userReply={userReply}
                        forumUser={forumUser}
                        id={id}
                        handlegetComment={handlegetComment}
                        forumComment={forumComment}
                        item={item}
                        userData={userData}
                      />
                    </div>
                  </React.Fragment>
                ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForumInnerPage;
