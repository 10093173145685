import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalContext } from '../Context'

const ConfirmSubmit = ({ editProfile }) => {
  const modalContext = useContext(ModalContext)
  const { closeModal, handleModalData } = modalContext
  const { t } = useTranslation(['home'])
  return (
    <div>
      <h1 className="text-32 text-center">{`${t('Confirm save')}`}</h1>
      <p className="text-center">{`${t('Are you sure you want to save')}`}</p>
      <div className="flex justify-around mt-4">
        <button onClick={closeModal}>{`${t('Cancel')}`}</button>
        <button onClick={editProfile}>{`${t('Submit')}`}</button>
      </div>
    </div>
  )
}

export default ConfirmSubmit
