import React from "react";
import { useState } from "react";

import { BASE_URL_IMG } from "../../Utils/services";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SearchTool = ({ setFilterForum, setTagFilter }) => {
  const userNameID = localStorage.getItem("adminId");
  let navigate = useNavigate();
  const { t } = useTranslation(['home'])
  return (
    <div className="bg-white mb-4 ">
      <div className="flex  mb-2 justify-start flex-wrap items-center p-4 rounded  ">
        <img
          src={`${BASE_URL_IMG}/user/${userNameID}/${userNameID}.jpeg`}
          className="h-8  mr-2 cursor-pointer"
          onClick={() => navigate("/profile")}
          alt=""
        />
        <div className="bg-gray p-2 search-wrapper mr-2">
          <div className="inputsearch ">
            <input
              type="text"
              placeholder={`${t("Search")}`}
              className="mx-3 px-3"
              onChange={(e) => setFilterForum(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchTool;
