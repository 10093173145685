import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import releatedserch from "../../../Assets/images/releatedsearchimage.png";
import { Globalinformation } from "../../../Common/MainSectionProvider";
import { getStories } from "../../../Utils/services";
import { BASE_URL_IMG } from "../../../Utils/services";
import { useTranslation } from "react-i18next";

const Recomanded = () => {
  const [userData, setUserData] = useState([]);
  const [forumDetails, setFormDetails] = useState([]);
  const [content, setContent] = useState("");
  let navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(["home"]);
  const { id } = useParams();
  const { fileteValue, setFilterValue, tagsearch, settagsearch } =
    useContext(Globalinformation);
  const formservicedata = async () => {
    try {
      const result = await getStories();
      const Fourm = result.res.filter((item) => item._id !== id);
      setFormDetails(Fourm);
      setContent(Fourm[0].content);
      if (result.res.data.userDetails.likedThreads.length > 0) {
        setUserData(result.res.data.userDetails.likedThreads);
      }
    } catch (error) { }
  };
  useEffect(() => {
    if (id) {
      formservicedata();
    }
  }, [id]);
  const handleStoryInnerPage = (id, item) => {
    navigate({
      // not with HashHistory!
      pathname: `/stories/${id}`,
    });
  };

  return (
    <div className="rightSideBar">
      <div className="bg-white p-4 rounded-lg">
        <div className="bg-gray p-2 search-wrapper">
          <div className="inputsearch">
            <input
              type="text"
              placeholder={`${t("Search")}`}
              className="mx-3 px-3"
              onChange={(e) => setFilterValue(e.target.value)}
            />
          </div>
        </div>
        <section className="filterSection mt-4">
          <h3>{`${t("Recommended for you")}`}</h3>

          <div className="h-40 overflow-y-scroll " id="filtersrollbar">
            {forumDetails &&
              forumDetails
                .filter((val) => {
                  if (fileteValue == "") {
                    return val;
                  } else if (
                    val &&
                    val.title &&
                    val.title.toLowerCase().includes(fileteValue.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val &&
                    val.caption &&
                    val.caption
                      .toLowerCase()
                      .includes(fileteValue.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((item) => {
                  return (
                    <>
                      <div
                        className="flex justify-between border-b py-2 mb-1 cursor-pointer"
                        onClick={() => handleStoryInnerPage(item._id, item)}
                      >
                        <div className="realeted_search">
                          <img
                            src={`${BASE_URL_IMG}${item.media[0].link}`}
                            alt=""
                            className=""
                          />
                        </div>
                        <div className="reatedtext">
                          <p className=" leading-5 ps-4 pe-2 filterContent">
                            {item?.title}
                            {/* Government programme for training and development. */}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Recomanded;
