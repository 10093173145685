
import React, { useContext } from 'react'
import { ModalContext } from '../Context';
import { FormDeleteComment, storyDeletecomment} from '../Utils/services';
import { useTranslation } from 'react-i18next';

const DeleteStoryReplyComment = ({setCountComment,id,commentid,handlegetComment,ReplyId}) => {
  const { t } = useTranslation(['home'])
    const modalContext = useContext(ModalContext);
    const { closeModal} = modalContext;
    const deletePost = async () => {
        const result = await storyDeletecomment({
          "postId": id,
          "commentId": commentid,
          "replyId" :ReplyId

         });
          handlegetComment(id);
          setCountComment(prev=>prev-1)
          closeModal();    
      }

  return (
    <div>   
    <h3 className='font-semibold text-center' >{`${t('Are you sure you want to delete this comment?')}`}</h3>
     <div className='d-flex justify-between mt-4'>
        <button className='cancel-btn' onClick={()=>closeModal()} >{`${t('Cancel')}`}</button>
        <button className='delete-btn' onClick={deletePost}>{`${t('Delete')}`}</button>
     </div>
      
  </div>
  )
}

export default DeleteStoryReplyComment ;