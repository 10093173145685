import React from 'react'
import Player from './Player'

const VideoModal = ({link}) => {
  return (
    <div className=''>
        <Player link={link} />
    </div>
  )
}

export default VideoModal