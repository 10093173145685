import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { ModalContext } from "../../Context";
import { BASE_URL_IMG, editProfileApi, getAvtarApi } from "../../Utils/services";

const ProfileAvtar = ({getProfileDetails,hardRefresh}) => {
  const [avtar, setAvatar] = useState();
  const [profileId,setProfileId] =useState("")
  const admin_id = localStorage.getItem('adminId');
  const modalContext = useContext(ModalContext);
  const { closeModal } = modalContext;

  const proFileAvtar = async () => {
    const result = await getAvtarApi();
    setAvatar(result.res.data.avtar);
  };

  const handleChangeAvatar =async(id,avtar) =>{
       let avtars=`${avtar}`
       const result =await editProfileApi({
        "path":avtars,
        "id":admin_id

    }) 
     getProfileDetails()
     closeModal()  
  }
 



  useEffect(() => {
    proFileAvtar();
  }, []);

  return (
    <Container>
      <Row>
        {avtar &&
          avtar.map((item) => (
            <Col md={3} >
                <div className="avatarBg flex justify-center m-1 ">
                    
               
                <div className="w-50 h-50 m-3  p-1 ">
                    <img src={`${BASE_URL_IMG}${item.Avtar}?${Date.now()}`} alt="" className="cursor-pointer" onClick={()=>handleChangeAvatar(item._id,item.Avtar)} />
                </div>
                </div>
            </Col>
          ))}
      </Row>
    </Container>
  );
};

export default ProfileAvtar;
