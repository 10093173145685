import React from 'react'
import ForumRightSidebar from '../ForumRightSidebar'
import CommentSection from './CommentSection'
import { Container, Row, Col } from 'reactstrap'
import RealatedThread from './RealatedThread'
import ForumInnerPage from './ForumInnerPage'

const CommentPagemain = () => {
  return (
    <>
      <Container>
        <Row>
          <Col lg={7} className="forummainInnerpage mt-3">
            <ForumInnerPage />
          </Col>
          <Col lg={5} className="forummainSidebar">
            <RealatedThread />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default CommentPagemain
