import React from 'react'
import { useContext } from 'react';
import { ModalContext } from '../Context';
import { mobileVerifictionApi, mobileVerifictionOTPApi } from '../Utils/services';
import MobileOtpVerify from './MobileOtpVerify';
import { useTranslation } from 'react-i18next';

const VerifyNumber = () => {
  const { t } = useTranslation(['home'])
  const modalContext = useContext(ModalContext);
  const { closeModal,handleModalData} = modalContext;
  const phone =localStorage.getItem('phone');
  const fcmToken =localStorage.getItem('fcmToken');
  const country =localStorage.getItem("country")
  const handleBackbtn =() =>{
    closeModal()
  }

  const handleSendBtn = async() =>{

      const result = await mobileVerifictionApi(
       {"phone": phone, 
        "fcmToken": fcmToken,
        "code" :country,
       }
      )
      if(await result.res.success){
       closeModal()
       let OTP = <MobileOtpVerify />
       handleModalData(OTP ,"sm");
       
     }
  }




  return (
   <div>
      <h3 className='text-blue font-medium'>{`${t('Phone Number not Verified')}`}</h3>
      <p className='font-medium'>{`${t('Your number is not verified. You need to verify your phone number first. We will send a 5 digit OTP through push notification')}`}</p>
       
      <div className='flex justify-between mt-4'>
        <button className='verifybackBtn' onClick={handleBackbtn}>{`${t('Back')}`}</button>
        <button className='verifysend' onClick={handleSendBtn}>{`${t('Send OTP')}`}</button>

       </div>

   </div>
  )
}

export default VerifyNumber;