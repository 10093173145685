import React, { useContext, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import avtar from "../Assets/images/modalavtar.png";
import { useEffect } from "react";
import { galleryEdittPost, getTagFormService } from "../Utils/services";
import { ModalContext } from "../Context";
import { useTranslation } from "react-i18next";

const issuesData = [
  {
    id: 1,
    title: "Why are you reporting this post?",
  },
  {
    id: 2,
    title: "It's spam",
  },
  {
    id: 3,
    title: "Nudity or sexual activity",
  },
  {
    id: 4,
    title: "Hate speech or symbols",
  },
  {
    id: 5,
    title: "False information",
  },
  {
    id: 6,
    title: "Bullying or harassment",
  },
  {
    id: 7,
    title: "Scam or fraud",
  },
  {
    id: 8,
    title: "Violence or dangerous organizations",
  },
  {
    id: 9,
    title: "Intellectual property violation",
  },
  {
    id: 10,
    title: "Sale of illegal or regulated goods",
  },
  {
    id: 11,
    title: "Suicide or self-injury",
  },
  {
    id: 12,
    title: "Eating disorders",
  },
  {
    id: 12,
    title: "Other",
  },
];

function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

const GalleryEditPost = ({ postId, captions, galleryservicedata,postTitle }) => {
  const [title, setTitle] = useState(postTitle);
  const [comment, setComment] = useState(captions);
  const [issue, setIssue] = useState([]);
  const [tags, setTags] = useState([]);
  const modalContext = useContext(ModalContext);
  const { closeModal } = modalContext;
  const { t } = useTranslation(['home'])
  const handlegettag = async () => {
    try {
      const result = await getTagFormService();
      setTags(result.res.data);
    } catch (error) {}
  };

  const handleIssueData = (event, newInputValue, name) => {
    setIssue({
      ...issue,
      [name]: newInputValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let tagdata;
    tagdata = issue?.tags?.map((item) => item.name);
    try {
      const results = await galleryEdittPost({
        id: postId,
        title: title,
        tags: tagdata,
        caption: comment,
      });
      closeModal();
      galleryservicedata();
    } catch (error) {}
  };
  useEffect(() => {
    handlegettag();
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <div>
            <h3 className="text-blue font-bold text-lg">EDIT POST</h3>
          </div>
        </Row>
        <Row>
          <Col md={12}>
            <form action="" onSubmit={handleSubmit}>
              <div className="my-4 p-2 formBackground flex items-center rounded-md">
                <label htmlFor="" className="w-10 mr-2">
                  <img src={avtar} alt="" />
                </label>
                <input
                  className="formBackground"
                  placeholder="post title"
                  type="text"
                  defaultValue={postTitle}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="my-4 formBackground rounded-md pl-2">
                <Autocomplete
                  id="combo-box-demo2"
                  multiple={true}
                  options={tags?.sort(dynamicSort("name"))}
                  getOptionLabel={(option) => option?.name}
                  // value={issue?.title || ""}
                  onChange={(e, newVal) => handleIssueData(e, newVal, "tags")}
                  renderInput={(params) => (
                    <TextField
                      className=""
                      id="report"
                      {...params}
                      label="tags"
                    />
                  )}
                />
              </div>
              <textarea
                id=""
                cols="33"
                rows="7"
                placeholder="Comment here for other issue"
                name="comment"
                className="formBackground p-2 rounded-md"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>

              <button className="delete-btn">{`${t('submit')}`}</button>
            </form>
          </Col>
        </Row>
      </Container>

      <input maxLength={4} />
    </div>
  );
};

export default GalleryEditPost;
