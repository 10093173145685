import moment from 'moment/moment'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { Col, Row } from 'reactstrap'

import newsImage from '../../Assets/images/NewsFeed/news.png'
import { Globalinformation } from '../../Common/MainSectionProvider'
import timeIcon from '../../Assets/images/Social/hrtwo.svg'
import {
  getNewsFeed,
  getNewsFeedFilter,
  getNewsFeedSave,
  getSaveNewApi,
  getSearchNewsFeed,
} from '../../Utils/services'
import { Spinner } from 'react-bootstrap'
import NewsCom from './NewsCom'

const BookMark = () => {
  const [newshowData, setNewShowData] = useState([])
  const [newsFeed, setNewsFeed] = useState()
  const [news, setNews] = useState('')
  const { fileteValue, setFilterValue, tagsearch, settagsearch } = useContext(
    Globalinformation,
  )
  const { translateLanguage } = useContext(Globalinformation)
  const [ID, setID] = useState('')
  const [loading, setLoading] = useState(false)
  const [saveNews, setSaveNews] = useState([])

  const handleTranslate = ({ item, index }) => {
    let toLang = translateLanguage // translate to norwegian
    let text = `${item.title}`
    let url = ``
    url += '&q=' + encodeURI(text)
    url += `&target=${toLang}`

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setNews(response.data.translations[0].translatedText)
        setID(index)
      })
      .catch((error) => {
      })
  }

  const newsfeed = async (val) => {
    setLoading(true)
    const result = await getSaveNewApi()
    setLoading(false)
    setNewsFeed(result.res.data)
  }

  useEffect(() => {
    newsfeed()
  }, [])

  const handleSaveNewData = async () => {
    const result = await getNewsFeedSave()
    setSaveNews(result.res.savednewsfeed)
  }

  useEffect(() => {
    handleSaveNewData()
  }, [])

  return (
    <>
      <div className=""></div>

      {loading ? (
        <div className="loaderfixed">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Row>
          {newsFeed &&
            newsFeed.map((item, index) => {
              return (
                <>
                  <Col md={12}>
                    <NewsCom
                      item={item}
                      index={index}
                      news={news}
                      handleTranslate={handleTranslate}
                      ID={ID}
                      timeIcon={timeIcon}
                      saveNews={saveNews}
                      handleSaveNewData={handleSaveNewData}
                      newsfeed={newsfeed}
                    />
                  </Col>
                </>
              )
            })}
        </Row>
      )}
    </>
  )
}

export default BookMark
