import React, { useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import CommonModal from "../CommonModal";
import { ModalContext } from "../../Context";

import NavbarResponsive from "./MobileHeader";
import MainWrapper from "./MainWrapper";
import { Container, Row, Col } from "reactstrap";

const Layout = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("");
  const [data, setData] = useState({});

  const handleModalData = (data, size = "xl") => {
    setModalData(data);
    setModalSize(size);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <ModalContext.Provider
        value={{
          handleModalData,
          closeModal,
          setData,
          data,
          isModalOpen,
          modalData,
        }}
      >
        <div className="bgtheme  ">
          <div className="w-full sticky top-0 h-7 bg-sidenav mobilehidebg  z-50 "></div>
          <div className="w-full bgcolor "></div>
          <div className="Navbar_Responisive bg-blue">
            <NavbarResponsive />
          </div>
        
          <Container>
            <Row>
              <div className="layout ">
                <div className="side-nav">
                  <SideNav />
                </div>
                <div className="page-container">
                  <MainWrapper/>
                </div>
              </div>
            </Row>
          </Container>

          <div></div>
        </div>

        <CommonModal
          handleModalData={handleModalData}
          isModalOpen={isModalOpen}
          modalData={modalData}
          modalSize={modalSize}
          closeModal={closeModal}
        />
      </ModalContext.Provider>
    </React.Fragment>
  );
};

export default Layout;
