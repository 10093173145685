import React, { useContext, useEffect } from 'react';
import diamond from "../Assets/images/Social/dimond2.svg"
import diamond2 from "../Assets/images/Social/diomand3.svg";
import deleteIcon from "../Assets/images/Social/delete.svg"
import { useState } from 'react';
import { ModalContext } from '../Context';
import EditReply from '../ModalTemplate/EditReply';
import DeleteUserComment from '../ModalTemplate/DeleteUserComment';
import UserReport from '../ModalTemplate/UserReport';
import { galleryLikeapi, galleryLikeReplayapi } from '../Utils/services';
import { useTranslation } from 'react-i18next';

 const Replay = ({user,commentdata,handlegetComment,PostId,CommentId,ReplyId,likes,handlesubcomment,index,usersDataName}) => {
    const admin_id = localStorage.getItem('adminId');
    const [hasClicked, setHasClicked] = React.useState(false);
    const [adminId] =useState(admin_id);
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const { t } = useTranslation(["home"]);



    const handleSocial =(indexvalue ) =>{
      let tabsValue =indexvalue ;
      const EDIT_REPlY =<EditReply ReplyId={ReplyId} CommentId={CommentId} commentdata={commentdata} handlegetComment={handlegetComment} PostId={PostId} /> 
      const DELETE_REPLY = <DeleteUserComment handleGetComment={handlegetComment} CommentId={CommentId} PostId={PostId} ReplyId={ReplyId}    />
      const REPORT =<UserReport ReplyId={ReplyId} PostId={PostId} CommentId={CommentId} handleGetComment={handlegetComment} />
      switch (tabsValue) {
        case 0:
          handleModalData(DELETE_REPLY ,"sm");
          break;
    
        case 1:
          handleModalData(EDIT_REPlY ,"sm");
          break;
        case 2:
            handleModalData(REPORT ,"sm");
            break;
    
         default:
          handleModalData("report","sm");
          break;
      }
    
    }


    const handlelike = async () => {

      try {
        const result = await galleryLikeReplayapi({
          "postId":PostId,
          "commentId":CommentId,
          "replyId" :ReplyId ,  
        })
      
        if (result.res.success) {
          // toast.success(result.res.message)
          setHasClicked(!hasClicked);
          handlegetComment(PostId)
          // formservicedata()
        
        }
      } catch (error) {
        //console.log((error)
      }

    }

    useEffect(() => {
      //  //console.log((like,"like data")
      likes && likes.filter((item) => {    
          if (item.user === adminId) {
            setHasClicked(true);
          }
       })
    
      },[likes])


      // useEffect(()=>{
       
      //  //console.log((usersDataName,"reply name match")

      // },[])

      // //console.log((usersDataName,"reply")


      








    return (
      <div className="flex mt-2">
             
             { 
              adminId  !=user ?  
              <span className="mr-2 text-sm font-normal text-redies cursor-pointer pr-2 " onClick={()=>handleSocial(2)} >{`${t('Report')}`}</span>
              :
              <span className="mr-2 text-sm font-normal text-blue cursor-pointer pr-2"  onClick={() =>handleSocial(1)}> {`${t('Edit')}`}</span>
              }
             
            
             <span className="mr-2 text-sm font-normal  text-blue flex items-center cursor-pointer " onClick={handlelike}  ><img src={hasClicked ?diamond :diamond2} alt="" className="h-3 " /> <p className="px-2">{likes.length}</p> </span>
             { 
             adminId  !=user ? 
             <span  className="mr-2  border-b border-blue  text-sm font-normal  text-blue cursor-pointer"  onClick={()=>handlesubcomment({index,commentdata})}  > {`${t('Translate')}`}</span>
             :
             <span className="mr-2 text-sm font-normal  text-blue flex items-center cursor-pointer " onClick={()=>handleSocial(0)} ><img src={deleteIcon} alt="" className="h-3 " />  </span>
             }
             
        </div>
  )
}

export default Replay