import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { BASE_URL_IMG, getSkillTrainning } from "../../../Utils/services";
import SKillMain from "./SKillMain";

const SkillDetails = () => {
  const [skilldetails, setSkillDetails] = useState([]);
  const [subTopic, setSubTopic] = useState([]);
  const [completedcourse, setcompletedcourse] = useState([]);

  const [enrollCourse, setEnrollCourse] = useState([]);
  const location = useLocation();

  const userName = localStorage.getItem("adminName");
  const userNameID = localStorage.getItem("adminId");

  const { t } = useTranslation(["home"]);
  const [filter, setFilter] = useState("");
  let navigate = useNavigate();

  const SkillTrainingApi = async (item) => {
    const result = await getSkillTrainning();
    const resultfilter = result.res.course.filter(
      (items) => items._id == item._id
    );
    setSkillDetails(resultfilter[0]);
    setSubTopic(result.res.userDetails.subtopic);
    setcompletedcourse(result.res.userDetails.completedCourses);
    setEnrollCourse(result.res.userDetails?.enrollCourse);
  };

  useEffect(() => {
    if (location) {
      const { item } = location && location.state;
      SkillTrainingApi(item);
    }
  }, []);

  return (
    <Container>
      <Row>
        <Col lg={7} className="skillmain mt-20">
          <SKillMain
            skilldetails={skilldetails}
            subTopic={subTopic}
            enrollCourse={enrollCourse}
            completedcourse={completedcourse}
          />
        </Col>
        <Col lg={5} className="skillsidebar">
          <div className="rightSideBar">
            <div
              className="flex items-center mb-4 mt-1"
              onClick={() => navigate("/profile")}
            >
              <div className="w-10 mr-2 cursor-pointer">
                <img
                  src={`${BASE_URL_IMG}/user/${userNameID}/${userNameID}.jpeg`}
                  className=""
                  alt=""
                />
              </div>
              <div>
                <h3
                  className="aliasname cursor-pointer"
                  onClick={() => navigate("/profile")}
                >
                  {" "}
                  {userName || "Alias Name"}
                </h3>
                <p
                  className="underline text-blue cursor-pointer"
                  onClick={() => navigate("/profile")}
                >
                  View Profile
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SkillDetails;
