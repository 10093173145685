import React, { useEffect } from "react";
import { useContext } from "react";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row, Spinner } from "reactstrap";
import { Globalinformation } from "../../Common/MainSectionProvider";
import SearchForm from "../../Common/SearchHeader/SearchForm";
import {
  formFilterApi,
  formSearchApi,
  formServiceActiveApi,
  formServiceapi,
  formServicepollsApi,
} from "../../Utils/services";
import PollingCard from "./PollingCard";
import ActiveForm from "./Social/ActiveForm";
import SocialImageCard from "./Social/SocialImageCard";
import InfiniteScroll from "react-infinite-scroll-component";

const Forum = ({
  filterFourm,
  tagFilter,
  setFilterForum,
  setTagFilter,
  selectedcourse,
  setselectedCourse,
}) => {
  const [featureData, SetFeatureData] = useState([]);
  const [activeData, SetActiveData] = useState([]);
  const [activePoll, setactivePoll] = useState([]);
  const [Poll, setPoll] = useState([]);
  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const [page3, setPage3] = useState(0);

  const userid = localStorage.getItem("adminId");
  const [show, setshow] = useState(true);
  const [showActive, setshowActive] = useState(true);
  const [showoldpolls, setshowoldpolls] = useState(true);
  const [userData, setUserData] = useState([]);
  const [saveForm, setSaveForm] = useState([]);
  const [searchForm, setSearchForum] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["home"]);

  const { fileteValue, setFilterValue, tagsearch, settagsearch } =
    useContext(Globalinformation);

  // check user tick

  const checkuser = (choices) => {
    var count = 0;
    choices.filter((item) => {
      item.users.map((vote) => {
        //console.log(vote === userid, "choies");

        if (vote === userid) {
          count = count + 1;
        }
      });
    });
    if (count === 0) {
      return false;
    } else {
      return true;
    }
  };

  // Get Expire poll
  function getRemainingHours(date, length) {
    var now = new Date();
    var postedTime = Math.floor((now - new Date(date)) / 60000); // Difference in minutes
    var remainingMinutes = length - postedTime;
    var remainingHours = Math.floor(remainingMinutes / 60);
    // return remainingHours;
    if (remainingHours < 0) {
      return false;
    } else {
      return true;
    }
  }

  //post api hit//
  const formservicedata = async (scroll) => {
    //console.log(scroll, page);
    try {
      if (selectedcourse.length > 0) {
        defaultfunc();
        setLoading(true);
        const result = await formFilterApi({
          name: selectedcourse,
          country: "India",
        });
        //console.log(result, "res filter");
        setLoading(false);
        //console.log(result.res,"Sashbhdbhedbjdsc");
        SetFeatureData(result.res.thread);
        SetActiveData([]);

        if (result.res.data.userDetails.likedThreads.length > 0) {
          setUserData(result.res.userDetails.likedThreads);
        }

        if (result.res.data.userDetails.savedForums.length > 0) {
          setSaveForm(result.res.data.userDetails.savedForums);
        }
      } else if (filterFourm) {
        defaultfunc();

        setLoading(true);
        const result = await formSearchApi({
          query: filterFourm,
        });
        setLoading(false);
        //console.log(result, "res filter");

        // const _activeForm = result.res.data.threads.filter(item => item.isFeatured == false)
        // const _featureForm = result.res.data.threads.filter(item => item.isFeatured == true)

        // //console.log((_featureForm,"data");
        SetFeatureData(result.res.data);
        //console.log(result.res.data,"avadvabkjasbfghyjcgbkaysgkfu");
        SetActiveData([]);

        if (result.res.data.userDetails.likedThreads.length > 0) {
          setSearchForum(result.res.data.userDetails.likedThreads);
        }
        if (result.res.data.userDetails.savedForums.length > 0) {
          setSaveForm(result.res.data.userDetails.savedForums);
        }
      } else {
        setLoading(true);
        const result = await formServiceapi({ page: 1, size: 10 });
        setPage(1);
        const resultactive = await formServiceActiveApi({
          page: 1,
          size: 10,
        });
        setPage2(1);


        const _activeForm = resultactive.res.data.threads; // For Active Form

        const _featureForm = result.res.data.threads; // for Featured Forum
        const _poll = result.res.data.polls; // For polls
        if (_featureForm.length < 10) {
          setshow(false);
        }
        const resultpoll = await formServicepollsApi({
          page: 1,
          size: 10,
        });
        setPage3(1);

        const _activepoll = resultpoll.res.data.polls; // For polls

        ////console.log((_activeForm, "_activeForm");
        ////console.log((_featureForm, "_featureForm ");
        ////console.log((_activepoll, "_activepoll ");
        ////console.log((_poll, "_poll ");

        setPoll(_poll);
        setactivePoll(_activepoll);
        SetFeatureData(_featureForm);
        SetActiveData(_activeForm);
        setLoading(false);

        if (result.res.data.userDetails.likedThreads.length > 0) {
          setUserData(result.res.data.userDetails.likedThreads);
        }
        if (result.res.data.userDetails.savedForums.length > 0) {
          setSaveForm(result.res.data.userDetails.savedForums);
        }
      }
    } catch (error) {}
  };

  // Infinite scroller nextpage

  const nextForum = async () => {
    // if(page<1){
    //   const _featureForm =featureData;
    //   setPage(page + 1);
    //   SetFeatureData((prevItems) => [...prevItems, ..._featureForm]);
    //   if (_featureForm.length < 10) {
    //     setshow(false);
    //   }
    // }
    // else{
      const result = await formServiceapi({ page: page + 1, size: 10 });
      const _featureForm = result.res.data.threads;
      setPage(page + 1);
      SetFeatureData((prevItems) => [...prevItems, ..._featureForm]);
      if (_featureForm.length < 10) {
        setshow(false);
      }
    // }

  };

  const nextActiveForum = async () => {
    const result = await formServiceActiveApi({ page: page2 + 1, size: 10 });
    const _activeForm = result.res.data.threads;
    setPage2(page2 + 1);
    SetActiveData((prevItems) => [...prevItems, ..._activeForm]);
    if (_activeForm.length < 10) {
      setshowActive(false);
    }
  };

  const nextPollForum = async () => {
    const result = await formServicepollsApi({ page: page2 + 1, size: 10 });
    const _activepoll = result.res.data.polls;
    setPage2(page2 + 1);
    setactivePoll((prevItems) => [...prevItems, ..._activepoll]);
    if (_activepoll.length < 10) {
      setshowoldpolls(false);
    }
  };

  useEffect(() => {
    // nextForum();
    nextActiveForum();
    nextPollForum();
  }, []);
  const defaultfunc = () => {
    setPage(0);
    setPage2(0);
    setPage3(0);
    setshow(true);
    setshowActive(true);
    setshowoldpolls(true);
  };

  //update//

  useEffect(() => {
    formservicedata(false);
  }, [tagFilter, filterFourm, selectedcourse]);

  return (
    <>
      <div className="mobilesearch mt-10">
        <SearchForm
          setFilterForum={setFilterForum}
          setTagFilter={setTagFilter}
          selectedcourse={selectedcourse}
          setselectedCourse={setselectedCourse}
        />
      </div>
      {loading ? (
        <>
          <div className="loaderfixed">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </>
      ) : (
        <>
          {/* Active Featured Section  */}

          <div className="FeaturedMain pt-10 mt-3" id="Moniterglpid-0001">
            <h2>
              {featureData?.length > 0 ? <> {`${t("Featured")}`}</> : null}
            </h2>
            {Poll &&
              Poll.map((item, i) => {
                return (
                  <>
                    {item?.isPoll && (
                      <Col md="12" key={i}>
                        <>
                          <PollingCard
                            choices={item.choices}
                            _id={item?._id}
                            formservicedata={formservicedata}
                            length={item?.length}
                            item={item}
                            click={true}
                          />
                        </>
                      </Col>
                    )}
                  </>
                );
              })}

            {filterFourm ? (
              <>
                {featureData &&
                  featureData.map((item, i) => {
                    //console.log(item,"Anubhav item");
                    return (
                      <React.Fragment key={i}>
                        {item?.isPoll ? (
                          // <PollingCard click={true} />
                          <></>
                        ) : (
                          <SocialImageCard
                            qustion={item?.question}
                            image={item.media[0].link}
                            commentcount={item.comments}
                            tag={item?.tag}
                            likes={item?.likes}
                            index={i}
                            item={item}
                            contant={item?.content}
                            _id={item?._id}
                            userDetails={userData}
                            formservicedata={formservicedata}
                            saveForm={saveForm}
                            time={item?.date}
                            filterFourm={filterFourm}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
              </>
            ) : (
              <>
                <InfiniteScroll
                  dataLength={featureData.length}
                  pageStart={1}
                  next={nextForum}
                  hasMore={show}
                  loader={
                    <div className="loaderfixed">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  }
                >
                  {/* Render the featureData */}
                  {featureData &&
                    featureData.map((item, i) => {
                      //console.log(item,"Anubhav scscdesadsaef");
                      return (
                        <React.Fragment key={i}>
                          {item?.isPoll ? (
                            <>
                              <PollingCard
                                choices={item.choices}
                                _id={item?._id}
                                formservicedata={formservicedata}
                                length={item?.length}
                                item={item}
                                click={true}
                              />
                            </>
                          ) : (
                            <SocialImageCard
                              qustion={item?.question}
                              image={item.media[0].link}
                              commentcount={item.comments}
                              tag={item?.tag}
                              likes={item?.likes}
                              index={i}
                              item={item}
                              content={item?.content}
                              _id={item?._id}
                              userDetails={userData}
                              formservicedata={formservicedata}
                              saveForm={saveForm}
                              time={item?.date}
                              filterForum={filterFourm}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                </InfiniteScroll>
              </>
            )}
            {featureData.length === 0 && (
              <h3 className="text-center nopost mt-4">No posts</h3>
            )}
          </div>
          {/* Active Form Section  */}

          <div className="activesocial">
            {!show && activeData && (
              <>
                <h2 className="mb-2">
                  {activeData.length > 0 ? (
                    <> {`${t("Active Forum")}`}</>
                  ) : null}
                </h2>
              </>
            )}
            {/* <Row> */}
            {!show && (
              <>
                <InfiniteScroll
                  dataLength={activeData.length}
                  pageStart={1}
                  next={nextActiveForum}
                  hasMore={showActive}
                  loader={
                    <div className="loaderfixed">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  }
                >
                  {activeData &&
                    activeData.map((item, i) => {
                      //console.log(item, "_activeForm");
                      return (
                        <>
                          {!item?.isPoll && (
                            <Col md="12" key={i}>
                              <ActiveForm
                                qustion={item.question}
                                image={item.media[0].link}
                                commentcount={item.comments}
                                tag={item.tag}
                                likes={item.likes}
                                item={item}
                                index={i}
                                contant={item.content}
                                _id={item._id}
                                userDetails={userData}
                                formservicedata={formservicedata}
                                saveForm={saveForm}
                                time={item.date}
                              />
                            </Col>
                          )}
                        </>
                      );
                    })}
                </InfiniteScroll>
              </>
            )}
            {/* </Row> */}
          </div>

          {/* Active Poll Section  */}

          <div className="activesocial">
            {!show && !showActive && activePoll.length > 0 && (
              <>
                <h2 className="mb-2">
                  {activeData.length > 0 ? <> {`${t("Polls")}`}</> : null}
                </h2>
              </>
            )}

            <Row>
              {!show && !showActive && (
                <>
                  <InfiniteScroll
                    dataLength={activePoll.length}
                    pageStart={1}
                    next={nextPollForum}
                    hasMore={showoldpolls}
                    loader={
                      <div className="loaderfixed">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    }
                  >
                    {activePoll &&
                      activePoll.map((item, i) => {
                        //console.log(item, "_activeForm");
                        return (
                          <>
                            {item?.isPoll && (
                              <Col md="12" key={i}>
                                <>
                                  <PollingCard
                                    choices={item.choices}
                                    _id={item?._id}
                                    formservicedata={formservicedata}
                                    length={item?.length}
                                    item={item}
                                    click={false}
                                  />
                                </>
                              </Col>
                            )}
                          </>
                        );
                      })}
                  </InfiniteScroll>
                </>
              )}
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default Forum;
