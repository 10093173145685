import React, { useContext, useEffect, useState } from "react";
import socialimage from "../../../Assets/images/Social/image.png";
import socialmark from "../../../Assets/images/Social/bookmark3.svg";
import socialmark2 from "../../../Assets/images/Social/bookmark2.svg";
import diamond from "../../../Assets/images/Social/dimond2.svg";
import diamond2 from "../../../Assets/images/Social/diomand3.svg";
import hr from "../../../Assets/images/Social/hrtwo.svg";
import comment from "../../../Assets/images/Social/comments.svg";

import {
  BASE_URL_IMG,
  TRANSLATE_URL,
  bookmarkservice,
  likeandunlikeservice,
} from "../../../Utils/services";
import { toast } from "react-toastify";

import { AnimatePresence, motion } from "framer-motion";
import { Globalinformation } from "../../../Common/MainSectionProvider";
import TimeAgo from "react-timeago";
// import { id } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TexttoSpeach from "../../TexttoSpeach";
import Shareicon from "../../ShareFunction";


const SocialImageCard = ({
  qustion,
  image,
  commentcount,
  tag,
  index,
  likes,
  contant,
  item,
  _id,
  formservicedata,
  userDetails,
  saveForm,
  time,
  filterFourm,
}) => {
  //console.log((qustion,"Anubhav Logs");
  const [qusTion, setQustion] = useState("");
  const [qusTionoriginal, setqusTionoriginal] = useState(qustion);
  const [isTranslated, setisTranslated] = useState(false);
  const [Totallikes, settotallikes] = useState(likes.length);
  const [hasClicked, setHasClicked] = React.useState(false);
  const [bookmarkClicked, setbookmarkClicked] = React.useState(false);
  const { getSavedForumAPiData, translateLanguage } =
    useContext(Globalinformation);
  const { t } = useTranslation(["home"]);

  let navigate = useNavigate();

  //function//
  const times = new Date(time);

  const handleCommentSection = ({
    qustion,
    image,
    commentcount,
    tag,
    index,
    likes,
    contant,
  }) => {
    // //console.log((_id,"_id");
    formservicedata();
    navigate(`/forum/${_id}`);
  };

  //post like api hit //

  const handlelike = async (e) => {
    try {
      const result = await likeandunlikeservice({
        threadId: _id,
        commentId: null,
      });

      if (result.res.success) {
        // toast.success(result.res.message)
        if (!hasClicked) {
          settotallikes(Totallikes + 1);
        } else {
          settotallikes(Totallikes - 1);
        }
        setHasClicked(!hasClicked);
        // settotallikes((Totallikes)+1)
        // formservicedata()
      }
    } catch (error) {
      //console.log((error)
    }
  };

  //post book_mark api hit //
  const handlebookmark = async () => {
    try {
      const result = await bookmarkservice({
        threadId: _id,
      });
      getSavedForumAPiData();
      if (result.res.success) {
        setbookmarkClicked(!bookmarkClicked);
        toast.success(result.res.message);
      }
    } catch (error) { }
  };

  //update//
  useEffect(() => {
    if (userDetails.includes(_id)) {
      setHasClicked(true);
    } else {
      setHasClicked(false);
    }

    saveForm &&
      saveForm.filter((id) => {
        if (id === _id) {
          setbookmarkClicked(true);
        }
      });
  }, [filterFourm, userDetails, saveForm]);

  const handleChangeButton = () => {
    setisTranslated(!isTranslated);
    if (qusTion === "") {
      let toLang = translateLanguage; // translate to norwegian
      let text = `${qusTionoriginal}`;
      let url = TRANSLATE_URL;
      url += "&q=" + encodeURI(text);
      url += `&target=${toLang}`;
      const token = localStorage.getItem("token");

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`, // notice the Bearer before your token
        },
      })
        .then((res) => res.json())
        .then((response) => {
          setQustion(response.data.translations[0].translatedText);
        })
        .catch((error) => {
          //console.log(('There was an error with the translation request: ', error)
        });
    }
  };

  //console.log((userDetails, _id, 'Check data')

  return (
    <div className="my-6">
      <div className="card">
        <div className="card-body">
          <div className="flex justify-between">
            <div className="flex items-center">
              <h5 className="card-title cardhead ">
                {isTranslated
                  ? qusTion === ""
                    ? qusTionoriginal
                    : qusTion
                  : qusTionoriginal}
              </h5>
              <TexttoSpeach text={isTranslated
                ? qusTion === ""
                  ? qusTionoriginal
                  : qusTion
                : qusTionoriginal} />

            </div>

            <div className="socialimage">
              <motion.div
                whileTap={{ scale: 2 }}
                transition={{
                  duration: 0.5,
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                }}
                onClick={() => handlebookmark()}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={bookmarkClicked ? socialmark2 : socialmark}
                  alt=""
                  className="h-4"
                />{" "}
              </motion.div>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <p class="card-text formdetailstags mb-2 mt-2 ">
              {`${t("Tags")}`} : {tag && tag.map((u) => u.name).join(", ")}{" "}
            </p>
            <span
              className="underline text-blue cursor-pointer translateres"
              onClick={handleChangeButton}
            >{`${t("Translate")}`}</span>
          </div>

          <img
            className="card-img-bottom filter brightness-90 cursor-pointer object-cover form-social"
            src={`${BASE_URL_IMG}${image}` || socialimage}
            alt="Card cap"
            onClick={() =>
              handleCommentSection({
                qustion,
                image,
                commentcount,
                tag,
                index,
                likes,
                contant,
                _id,
              })
            }
          />
          <div className="flex mt-2 socialbtn ">
            <p className="mr-2 flex items-center h-5 cursor-pointer  formlikeres">
              {" "}
              <motion.div
                whileTap={{ scale: 4 }}
                transition={{ duration: 0.5 }}
                onClick={(e) => handlelike(e)}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={hasClicked ? diamond : diamond2}
                  alt=""
                  className="h-4"
                />
              </motion.div>
              <span className="px-2">
                {Totallikes} {Totallikes > 1 ? "Likes" : "Like"}
              </span>
            </p>
            <p className="mr-2 flex items-center h-5 formcommentres">
              {" "}
              <img src={comment} alt="" className="h-4 cursor-pointer" />{" "}
              <span className="px-2 cursor-pointer">
                {commentcount} Comments
              </span>
            </p>
            <Shareicon link={window.location.origin + `/forum/${item?._id}`} />
            <p className="mr-2 flex items-center h-5 formtimeres">
              {" "}
              <img src={hr} alt="" className="h-4 cursor-pointer" />{" "}
              <span className="px-2 cursor-pointer">
                <TimeAgo date={times} />
              </span>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialImageCard;
