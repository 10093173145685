import React, { useContext } from 'react'
import { ModalContext } from '../Context';
import { galleryDeleteComment } from '../Utils/services'
import { useTranslation } from 'react-i18next';

const DeleteUserComment = ({PostId,CommentId,handleGetComment,ReplyId }) => {
  const modalContext = useContext(ModalContext);
 const { closeModal } = modalContext;
 const { t } = useTranslation(['home'])
  const DeleteComment = async (data) => {
    const result = await galleryDeleteComment({
      "commentId": CommentId,
      "postId": PostId,
      "replyId" :ReplyId 
     })
     
     handleGetComment();
     closeModal();
  }


  return (
    <div>
       <h3 className='font-semibold text-center' >{`${t('Are you sure you want to delete this comment?')}`} </h3>
       <div className='d-flex justify-between mt-4'>
          <button className='cancel-btn' onClick={()=>closeModal()}>{`${t('Cancel')}`}</button>
          <button className='delete-btn' onClick={DeleteComment}>{`${t('Delete')}`}</button>
       </div>
    
    </div>
  )
}

export default DeleteUserComment