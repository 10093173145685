import moment from "moment";
import React from "react";
import { BASE_URL_IMG } from "../Utils/services";
import time from "../Assets/images/event/time.svg";
import orgainser from "../Assets/images/event/organisation.svg";
import sector from "../Assets/images/event/sector.png";
import url from "../Assets/images/event/link.svg";
import { useTranslation } from "react-i18next";

const EventHelp = ({ item }) => {
  const { t } = useTranslation(["home"]);
  return (
    <div>
      <div className="event-details flex justify-between items-center p-4 AS_Event">
        <div className="event-detail">
          <h3>{item.name}</h3>
          <p>{item.description}</p>
        </div>
        {item.image === null ? (
          ""
        ) : (
          <div className=" EventImage ml-2">
            <img src={`${BASE_URL_IMG}${item.image}`} alt="" />
          </div>
        )}
      </div>

      <div className="event-time justify-between  flex  items-center mt-4 ">
        <div className="mr-8">
          <h3 className="flex items-center ">
            {" "}
            <img src={time} alt="" className="w-6 mr-2" />
            <span>{`${t("Time")}`}</span>{" "}
          </h3>
        </div>

        <div className="events-detailslist">
          <p>
            {`${moment.utc(item.startTime).format("LT")} to ${moment
              .utc(item.endTime)
              .format("LT")}`}
            , {moment.utc(item.start).format("LL")}
          </p>
          <p>{item.timezone}</p>
        </div>
      </div>
      <div className="event-time justify-between flex  items-center mt-4 ">
        <div className="">
          <h3 className="flex items-center">
            {" "}
            <img src={orgainser} alt="" className="w-6 mr-2" />{" "}
            <span>{`${t("Organiser")}`}</span>
          </h3>
        </div>

        <div className="events-detailslist">
          <p>{item.organiser}</p>
        </div>
      </div>

      <div className="event-time  justify-between  flex  items-center mt-4 ">
        <div className="mr-8">
          <h3 className="flex items-center">
            <img src={sector} alt="" className="w-6 mr-2" />{" "}
            <span>{`${t("Sector")}`} </span>
          </h3>
        </div>

        <div className="events-detailslist">
          <p>
            {" "}
            {item.sector
              .filter((item) => item.language === localStorage.getItem("lng"))
              .map((item) => item.name)
              .join(", ")}
          </p>
        </div>
      </div>
      {item?.location && (
        <div className="event-time  justify-between  flex  items-center mt-4 ">
          <div className="mr-8">
            <h3 className="flex items-center">
              {" "}
              <img src={url} alt="" className="w-6 mr-2" />{" "}
              <span>{`${t("Location")}`} </span>{" "}
            </h3>
          </div>
          <div className="events-detailslist">{item?.location}</div>
        </div>
      )}
      {item && item.links.length > 0 && (
        <div className="event-time  justify-between  flex  items-center mt-4 ">
          <div className="mr-8">
            <h3 className="flex items-center">
              {" "}
              <img src={url} alt="" className="w-6 mr-2" />{" "}
              <span>{`${t("Meeting link")}`} </span>{" "}
            </h3>
          </div>
          <div className="events-detailslist">
            {item &&
              item.links.map((items) => (
                <p>
                  <a href={items} target="_blank" rel="noopener noreferrer">
                    {items}
                  </a>
                </p>
              ))}
          </div>
        </div>
      )}

      {item && item.otherlink.length > 0 && (
        <div className="event-time  justify-between  flex  items-center mt-4 ">
          <div className="mr-8">
            <h3>{`${t("Other link")}`}</h3>
          </div>
          <div className="events-detailslist">
            {item &&
              item.otherlink.map((items) => (
                <p>
                  <a href={items} target="_blank" rel="noopener noreferrer">
                    {items}
                  </a>
                </p>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default EventHelp;
