import { Box, Tab, Tabs } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import React from 'react'
import { useTranslation } from 'react-i18next'
import BookMark from '../Components/Newsfeed/BookMark'
import NewsMain from '../Components/Newsfeed/NewsMain'
import { tabsClasses } from '@mui/material'

const NewsTabs = ({ searchfilter, selectedfilter }) => {
  const [value, setValue] = React.useState('1')
  const { t } = useTranslation(['home'])

  const Tabdata = [
    {
      id: '1',
      labal: `${t('All')}`,
      Tabvalue: (
        <NewsMain
          categaryValue=""
          searchfilter={searchfilter}
          selectedfilter={selectedfilter}
        />
      ),
    },
    {
      id: '2',
      labal: `${t('National')}`,
      Tabvalue: (
        <NewsMain
          categaryValue="National"
          searchfilter={searchfilter}
          selectedfilter={selectedfilter}
        />
      ),
    },
    {
      id: '3',
      labal: `${t('Regional')}`,
      Tabvalue: (
        <NewsMain
          categaryValue="Regional"
          searchfilter={searchfilter}
          selectedfilter={selectedfilter}
        />
      ),
    },
    {
      id: '4',
      labal: `${t('International News')}`,
      Tabvalue: (
        <NewsMain
          categaryValue="International News"
          searchfilter={searchfilter}
          selectedfilter={selectedfilter}
        />
      ),
    },

    {
      id: '5',
      labal: `${t('Bookmarked')}`,
      Tabvalue: (
        <BookMark
          categaryValue=""
          searchfilter={searchfilter}
          selectedfilter={selectedfilter}
        />
      ),
    },
  ]

  
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <div className="lg:mt-20 tab ">
        <TabContext value={value} position="fixed">
        {/* <Box
      sx={{borderBottom: 1,
        flexGrow: 1,
        maxWidth: { xs: 320, sm: 480 },
        bgcolor: 'background.paper',
      }}
    >

            <div className="tablebar">
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                  },
                }}
              >
                {Tabdata?.map((item) => (
                  <Tab label={item.labal} value={item.id} />
                ))}
              </TabList>
            </div>
          </Box> */}
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
      }}
    >
      <Tabs
      className='AS_NF_tabbar'
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        aria-label="visible arrows tabs example"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
                {Tabdata?.map((item) => (
                  <Tab label={item.labal} value={item.id} />
                ))}
      </Tabs>
    </Box>
          {Tabdata?.map((item) => (
            <TabPanel value={item.id}>{item.Tabvalue}</TabPanel>
          ))}
        </TabContext>
      </div>
    </>
  )
}

export default NewsTabs
