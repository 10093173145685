import React, { useState } from 'react'
import { BASE_URL_IMG, SkillSession } from '../../../Utils/services'
import Curriculum from './Curriculum';
import Details from './Details';
import { useTranslation } from 'react-i18next';

const SKillMain = ({skilldetails,subTopic,enrollCourse,completedcourse}) => {
    const [otherBtn, setOtherBtn] = useState(false);
    const [details, setDetails] = useState(true);
    const [circulum,setcirculum] =useState([])
    const { t } = useTranslation(['home'])

    const handDetailsBtn = () => {
        setOtherBtn(false);
        setDetails(true);
      };
    
    const handOtherBtn = async() => {
        const result = await SkillSession({
            "courseId" :skilldetails._id
        })
        setcirculum(result.res.session)
        setOtherBtn(true);
        setDetails(false);

    };

    



  return (
    <div>
        <div className=''>
            <img src={`${BASE_URL_IMG}${skilldetails?.image}`} alt=""  className='w-100'/>
        </div>
        <div>

        <div className="profiledetails">
            <button
                className={`w-1/2 h-12  ${details && "activebtn shadow-md  "}`}
                onClick={handDetailsBtn}
            >
              Details
            </button>

            <button
                className={`w-1/2 h-12  ${otherBtn && "activebtn shadow-md "}`}
                onClick={handOtherBtn}
            >
              {`${t('Curriculum')}`}
            </button>
         </div>

            {
                details &&
                <Details skilldetails={skilldetails} subTopic={subTopic} enrollCourse={enrollCourse} />
            }

            {
                otherBtn &&
                 <Curriculum completedcourse={completedcourse} circulum={circulum} subTopic={subTopic} skillId ={skilldetails._id} enrollCourse={enrollCourse}/>
            }
          


        </div>
    </div>
   )
}

export default SKillMain