import React, { useContext, useEffect, useState } from "react";
import AvtarImg from "../Assets/images/avtar.png";
import { BASE_URL_IMG, getTagFormService } from "../Utils/services";
import { toast } from "react-toastify";
import { Globalinformation } from "./MainSectionProvider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


const SearchFilter = ({setGalleryFilter,setFilterTag}) => {
  const [tag, setTag] = useState([]);
  const {fileteValue,setFilterValue,tagsearch,settagsearch} =useContext(Globalinformation);
  const userName = localStorage.getItem("adminName");
  const userNameID = localStorage.getItem("adminId");
  const { t } = useTranslation(["home"]);


  let navigate = useNavigate();



  const getTag = async () => {
    try {
      const result = await getTagFormService();

      setTag(result.res.data);
      //console.log((result.res.data, "response");
    } catch (error) {
      // toast.error("list not found");
    }
  };


  //update //
  useEffect(() => {
    getTag();
  }, []);

  return (
    <>
      <div></div>
      <div className="rightSideBar">
        <div className="flex items-center mb-4 mt-1" onClick={()=>navigate('/profile')}>
          <div className="w-10 mr-2 cursor-pointer">
            <img src={`${BASE_URL_IMG}/user/${userNameID}/${userNameID}.jpeg?${Date.now()}`} className=""   alt="" />
          </div>
          <div>
            <h3 className="aliasname cursor-pointer" onClick={()=>navigate('/profile')}> { userName ||"Alias Name"}</h3>
            <p className="underline text-blue cursor-pointer" onClick={()=>navigate('/profile')}>View Profile</p>
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg">
          <div className="bg-gray p-2 search-wrapper">
            <div className="inputsearch">
              <input type="text" placeholder={`${t("Search")}`} className="mx-3 px-3" onChange={(e)=>setGalleryFilter(e.target.value)} />
            </div>
          </div>
    
        </div>
      </div>
    </>
  );
};

export default SearchFilter;
