export const LOGIN = "admin-signin";
export const LOGIN_FIRST = "signin";
export const SIGNUP = "Signup";
export const ADD_ADMIN = "add-admin";
export const RESET_PASSWORD = "reset-password";
export const REFRESH_TOKEN = "refresh";
export const CHANGEPASSWORD_API = "changePassword";
export const OTPVERIFICATION_API = "otpVerification";
export const OTPVERIFICATION_API_LOGIN = "verify-second";
export const FORGOTPASSWORD_API = "forgotPassword";
export const GET_SECTOR = "getsector";
export const GET_LANGUAGE = "getlanguage";

//formsection//
export const FORMSERVICE = "V2/getAllThread-v2";
// export const FORUNSINGLEPOST = "getThread";

export const GETALLACTIVETHREATS = "getAllactivethread";
export const GETALLPOLL = "getAll-poll-v2";
export const FILTER_FORMSERVICE = "getfilterForums";
export const LiKE_END_UNLIKE_FORMSER = "likeForThread";
export const BOOK_MARK = "bookMarkForums";
export const SAVE_FORM = "getSavedForums";
export const GET_TAG = "getTags";
export const GET_COMMENTS = "comment";
export const ADD_COMMENTS = "commentForThread";
export const DEL_FORM_COMMENTS = "delReply";
export const EDIT_FORM_COMMENTS = "editCommentInForums";
export const REPORT_FORM_COMMENT = "reportForums";
export const SEARCH_FORM = "search";
export const SUGGEST = "suggentionTopic";
export const likereply = "likeForReply";

//gelarysection //
export const GALLERYSERVICE = "feed";
export const LiKEGALLERY = "like";
export const GET_GALLERY_COMMENTS = "comments";
export const ADD_COMMENT_GALLERY = "comment";
export const DELETE_COMMENT_GALLERY = "delComment";
export const DELETE_POST_GALLERY = "post/delete";
export const REPORT_POST_GALLERY = "report";
export const REPORT_COMMENT_GALLERY = "reportComment";
export const EDIT_POST_GALLERY = "editPost";
export const SEARCH_POST_GALLERY = "searchPost";
export const EDIT_POST_GALLERY_COMMENT = "editComment";
export const LIKE_REPLY = "like-reply";

//profile//
export const PROFILE = "getProfile";
export const ABOUT_US = "getAbout-us";
export const DESCLAIMER = "getDisclaimer";
export const CODE_OF_CONDUCT = "getConduct";
export const FAQ = "getFaq";
export const EDIT_PROFILE = "editProfile";
export const EDIT_AVATAR_PROFILE = "getAvtar";
export const DELETE_PROFILE = "deleteUserAndEraseAllDataAndBeIrrecoverable";

//newsFeed //
export const GET_CATEGARY = "getcategory";

export const GET_RESOURCE = "getResource";
//toolkit //
export const GET_TOOLKIT = "getToolkit";
export const SEARCH_TOOLKIT = "searchToolkit";

export const GET_NOTIFACTION = "notificationsV2";
//newsfeed //
export const NEWSFEED = "getnewsfeed";
export const SEARCHNEWSFEED = "searchNewsfeed";
export const NEWSFEEDFILTER = "filterNewsfeed";

//events //
export const EVENTS = "events";

//stories //
export const STORIES = "getstories";
export const LIKESTORIES = "likeStories";
export const ADDCOMMENTSTORY = "commentinstories";
export const COMMENTSTORY = "getcomments";
export const EDIT_COMENT_STORY = "editCommentInStory";
export const DELETE_STORY_COMMENT = "delCommentReply";
export const LIKE_STORY_COMMENT = "likeStories";
export const ADD_POST = "post";
export const FILTER_POST = "filterStory";
export const SEARCH_STORY_POST = "searchStory";
export const LIKE_RPLY_STORY = "like-reply-story";

//gethelp //

export const DIRETORY = "directory";

//msg //

export const MESSSAGES = "getMessages";
export const SEND_MESSSAGES = "addMessage";

//verifymobileotp //

export const SENDVERIFYCODE = "signin-first";
export const VERIFYCODE = "verify-second";

// bock user api //

export const BLOCK = "block";
export const BLOCKLIST = "getBlockedUser";

// Count Api //

export const VIEW_COUNT_RESOURCE = "ViewResource";
export const DOWNLOAD_COUNT_RESOURCE = "downloadResource";
export const VIEW_COUNT_TOOLKIT = "viewToolkit";
export const DOWNLOAD_COUNT_TOOLKIT = "downloadToolkit";
export const NOTIFICATION_READ = "notifications/read";
export const SINGLE_NOTIFICATION_READ = "notifications/readOne";

export const DIRECTORYSEARCH = "search-directory";
export const SKILLTRAINNING = "get-course";
export const SKILLSESSION = "get-session";
export const COPLETE_SEBSESSION = "complete-subsession";
export const GET_SUB_SESSION = "get-sub-session";
export const LOGOUT = "logout";
export const COURES_FILTER = "filter-course";
export const COURSE_SEARCH = "search-course";
export const ENROLL_COURSE = "enroll-course";
export const DOWNLOADPDF = "download-file";
export const ALIASMATCH = "get-aliasName";
export const POLL = "vote-poll";
export const POLLV2 = "vote-poll-v2";

export const BOOKMARKNEEWSFEED = "bookMarkNeewsfeed";
export const GETSAVENEWS = "get-savednews";

/**
 * By anubhav Sharma
 */

// For Filter Mention the name

export const MENTIONAPI = "/usersearch";

// For Report User in User Profile

export const USERREPORTSPI = "/userReport";

// for Course Download

export const DOWNLOADCOURSEAPI = "/downloadCertificate";

// Reset Password


export const RESRT_PASSWORD = "/resetpassword";

// Get Meeting Code 

export const GET_MEETING_CODE = "/get_room_detail";

// Task Complete 

export const TASKCOMPLETE = "/complete-course"

// Notification Count

export const NOTIFICATION_COUNT ="/notifications/unread"


export const SINGLE_RESOURCE ="/getOneResource"