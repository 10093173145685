import React, { useContext } from 'react'
import AvtarImg from "../../Assets/images/avtar.png";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ModalContext } from '../../Context';
import EventModal from '../../ModalTemplate/EventModal';
import { BASE_URL_IMG } from '../../Utils/services';
import { useNavigate } from 'react-router-dom';

const CalenderDetail = ({ eventAllData,getEventApiData,value, onChange,setTitleMonth}) => {
  
    const userName = localStorage.getItem("adminName");
    const userNameID = localStorage.getItem("adminId");

    const [toggle, settoggle] = useState(false);

    const [currentDate, setCurrentDate] = useState(new Date());
    const [showEvent, setShowEvent] = useState([]);
    const [year, setYear] = useState(new Date().getUTCFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const { t } = useTranslation(["home"]);
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    let navigate = useNavigate();
  
    const handleToggleButton = (e) => {
      e.preventDefault();
      settoggle(!toggle)
    }
  
    const formatDate = date => {
      const _date = new Date(date)
      const month = _date.getMonth() + 1
      const year = _date.getFullYear()
      const newdate = _date.getDate()
      return `${newdate}/${month}/${year}`
    }
  
    const getAllApiDataOnLoad = () => {
      setShowEvent([])
      let arry = []
      eventAllData && eventAllData.map((item) => {
        const _date = new Date(item.current)
        const _d1 = formatDate(_date)
        const _d2 = formatDate(currentDate)
        if (_d1 === _d2) {
          arry.push(item)
        }
        else {
          //console.log((false);
        }
      })
      setShowEvent(arry)
    }
  
    useEffect(() => {
      getAllApiDataOnLoad();
    }, [eventAllData])
  
    const dateHandler = (date) => {
    
      setCurrentDate(date)
      setShowEvent([])
      let arry = []
      eventAllData.map((item) => {
        const _date = new Date(item.current)
        const _d1 = formatDate(_date)
        const _d2 = formatDate(date)
        if (_d1 === _d2) {
          arry.push(item)
        }
        else {
          //console.log((false);
        }
        setShowEvent(arry)
        const eventmodal = <EventModal showEvent={arry}  />
        handleModalData(eventmodal,"lg")

     
      })
   
      
    }
  
    const handleActiveStartDate = (value) => {
      //console.log((value.activeStartDate, "Change Month")
      const _yearMonth = value.activeStartDate;
      const year = _yearMonth.getFullYear();
      const month = _yearMonth.getMonth() + 1;
      setCurrentDate(value.activeStartDate)
      setYear(year)
      setMonth(month)
      setTitleMonth(value.activeStartDate)
    }
    useEffect(() => {
      getEventApiData(year, month);
    }, [year, month]);

    const handleTileClassName = ({ date }) => {
        const _newData = [];
        eventAllData && eventAllData.map((item) => {
            _newData.push(item.current)
        })
       const _res = _newData.filter((item) => {
            return formatDate(item) === formatDate(date)
        })
    
        for (let x in _newData) {
            if (formatDate(_res[0]).includes(formatDate(_newData[x]))) {
                return "cutome_calander_class"
            }
        }
    }
 
  return (
       <>
         <div className="rightSideBar">
        <div className="flex items-center mb-4 mt-1">
          <div className="w-10 mr-2 cursor-pointer" onClick={()=>navigate('/profile')}  >
            <img src={`${BASE_URL_IMG}/user/${userNameID}/${userNameID}.jpeg` || AvtarImg} alt="" />
          </div>
          <div>
            <h3 className="aliasname cursor-pointer" onClick={()=>navigate('/profile')}> { userName ||"Alias Name"}</h3>
            <p className="underline text-blue cursor-pointer" onClick={()=>navigate('/profile')}>View Profile</p>
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg">
          {/* <div className="bg-gray p-2 search-wrapper">
            <div className="inputsearch">
              <input type="text" placeholder={`${t("Search")}`} className="mx-3 px-3"  />
            </div>
          </div> */}
          <section className="filterSection mt-4 flex justify-center border rounded-md">
            {/* <h3>Filter By</h3> */}
            <Calendar
              onChange={dateHandler}
              value={currentDate}
              onActiveStartDateChange={handleActiveStartDate}
              next2Label={null}
              prev2Label={null}
              tileClassName={handleTileClassName}
            />

           
          </section>

    
        </div>
      </div>
    </>
  )
}

export default CalenderDetail