import React from "react";
import { useContext } from "react";
import { ModalContext } from "../../Context";
import { BASE_URL_IMG } from "../../Utils/services";
import ProfileAvtar from "./ProfileAvtar";
import editIcon from "../../Assets/images/icons/edit.png";
import DeleteProfile from "../../ModalTemplate/DeleteProfile";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ProfileAlias = ({ proFile, getProfileDetails, hardRefresh }) => {
  const userNameID = localStorage.getItem("adminId");
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  let navigate = useNavigate();
  const { t } = useTranslation(["home"]);

  const handleAvtar = () => {
    const AVATAR = (
      <ProfileAvtar
        getProfileDetails={getProfileDetails}
        hardRefresh={hardRefresh}
      />
    );
    handleModalData(AVATAR, "lg");
  };

  const handleRedirectToAdminChat = () => {
    navigate("/chat");
  };

  const HanndleDelete = () => {
    const deleteProfile = <DeleteProfile />;
    handleModalData(deleteProfile, "sm");
  };

  return (
    <div className="aliasprofile flex flex-col items-center">
      <div className="w-24 flex justify-center mt-16 profile-logo">
        <img
          src={`${BASE_URL_IMG}${proFile?.image}?${Date.now()}`}
          alt=""
          className="w-100 cursor-pointer"
          onClick={handleAvtar}
        />
        <div className="editLogo cursor-pointer" onClick={handleAvtar}>
          <img src={editIcon} alt="" />
        </div>
      </div>

      <h2 className="pt-4">{proFile?.aliasName || "Alias Name"}</h2>
      <h3>{proFile?.sector?.name || "Sector "}</h3>
      <h4>{proFile?.country?.name || "Country"}</h4>

      <div className="contact-admin mt-4">
        <button className="AS_profife_Button" onClick={handleRedirectToAdminChat}>{`${t(
          "Contact Admin"
        )}`}</button>
      </div>

      <div className="logout mt-4">
        <button className="AS_profife_Button" onClick={HanndleDelete}>{`${t("Delete")}`}</button>
      </div>
    </div>
  );
};

export default ProfileAlias;
