import Logo from "../../Assets/images/glp-logo-white.png"
import Login from "./Login";

const Auth = ({history,setHistory}) => {
    return (
        <div className="w-full bg-loginBg">
            <div className="grid lg:grid-cols-2 md:grid-cols-1  lg:gap-4  md:gap-0   mobileresposiveauth">
                <div className="w-full h-full md:none">
                    <div className="glpLoginBgBanner">
                     <img src={Logo} alt="bg banner" className="w-1/2"  />
                    </div>
                </div>
                <div className="flex justify-center items-center mobileLoginscreen">
                    <Login history={history} setHistory={setHistory} />
                </div>
            </div>
        </div>
    )
}

export default Auth;