import { useState } from 'react'
import CornerImg from '../../Assets/images/signincorner.png'
import Logo from '../../Assets/images/glp-logo-white.png'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import { useEffect } from 'react'
import {
  getLanguageApi,
  getSectorApi,
  matchAliasName,
  SignupApi,
} from '../../Utils/services'
import { toast } from 'react-toastify'
import { getCountryCallingCode, getCountries } from 'react-phone-number-input'
import mibilelogo from '../../Assets/images/glplogotwo.png'
import TermConditionModal from '../../ModalTemplate/TermConditionModal'
import { useTranslation } from 'react-i18next'
import { getToken } from '../firebaseInit'
import india from '../../Assets/images/country/india.png'
import CountryModal from '../../ModalTemplate/CountryModal'
import { useNavigate } from 'react-router-dom'

const Signup = () => {
  const { t } = useTranslation(['home'])
  const [isButtonActive, setIsButtonActive] = useState(false)
  const [mobileCode, setMobileCode] = useState()
  const [sectorData, setSectorData] = useState([])
  const [languageData, setLanguageData] = useState([])
  const [formData, setFormData] = useState({})
  const navigate = useNavigate()
  // const [countryCode, setCountryCode] = useState("");
  const [isTrue, setIstrue] = useState(false)
  const [error, seterror] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [rulesmodalShow, setRulesModalShow] = useState(false)
  const [aliasName, setAliasName] = useState('')
  const [errors, setErrors] = useState('')

  const [deviceToken, setDeviceToken] = useState('')
  const language_services = localStorage.getItem('lng')
  const [countryCode, setCountryCode] = useState({
    Icon: india,
    Code: '91',
    cntyCode: '+91',
  })

  useEffect(() => {
    if (
      formData?.fullname &&
      aliasName &&
      formData?.sector &&
      formData?.age &&
      formData?.gender &&
      formData?.password &&
      formData?.confirmPassword &&
      formData?.phoneNumber
    ) {
      // if (formData?.password === formData?.confirmPassword) {
        setIsButtonActive(true)
        //console.log(('hello')
      // }
    }
    //console.log(('check')
  }, [formData, mobileCode])

  const getSectorApiFun = async () => {
    try {
      const result = await getSectorApi({"language":language_services})
      if (result.res.success) {
        setSectorData(result.res.data)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  // const getLanguageApiFun = async () => {
  //     try {
  //         const result = await getLanguageApi();
  //         if (result.res.success) {
  //             setLanguageData(result.res.data.languages)
  //             //console.log(("data res",result)
  //         }
  //     } catch (error) {
  //         toast.error(error.message)
  //         //console.log(("data res",error.message)
  //     }
  // }
  useEffect(() => {
    getSectorApiFun()
    // getLanguageApiFun();
  }, [language_services])
  const handlesignInClick = () => {
    navigate('/login')
  }
  const handleMobileChange = (value) => {
    setMobileCode(value)
  }

  const handleInputChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setFormData({
      ...formData,
      [name]: value,
    })
  }
// Function to validate the password
const isPasswordValid = (password) => {
  const hasCapital = /[A-Z]/.test(password);
  const hasSmall = /[a-z]/.test(password);
  const hasSpecial = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);

  return hasCapital && hasSmall && hasSpecial;
};


  const handleFormSubmit = async (e) => {
    e.preventDefault()
    // "language": formData.language,
    try {
      if (formData?.password === formData?.confirmPassword) {
        if (isPasswordValid(formData?.password)) {
          const result = await SignupApi({
            name: formData.fullname,
            aliasName: aliasName,
            // "code": countryCode ? countryCode : '+91',
            code: countryCode?.Code,
            sector: formData.sector,
  
            phone: formData.phoneNumber,
            age: formData.age,
            gender: formData.gender,
            Password: formData.password,
            language: 'English',
            termCondition: isTrue,
            fcmToken: deviceToken,
          })
          if (result.res.success) {
            toast.success(result.res.message)
            navigate('/login')
          }
        }else{
          toast.error("Password must contain at least one capital, one small, one number, min 8 digit length & no special symbols.")
        }
      }else{
        toast.error("Password and Confirm Password not match")
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  //   const handleCountryChange = (e) => {
  //     setCountryCode(e)
  //   }

  useEffect(() => {
    let data

    async function tokenFunc() {
      data = await getToken(setDeviceToken)
      if (data) {
        //console.log(('Token is', data)
        setDeviceToken(data)
      }
      return data
    }

    tokenFunc()
  }, [setDeviceToken])

  //   const handleValidation = async () => {
  //     let _errors = {}
  //     let formIsValid = true
  //     const result = await matchAliasName({
  //       name: formData.aliasName,
  //     })

  //     if (result.res.message != 'available') {
  //       formIsValid = false
  //       _errors['aliasName'] = `${result.res.message}`
  //     }
  //     setErrors(_errors)
  //     return formIsValid
  //   }

  const handleChangeAliasName = async (e) => {
    setAliasName(e.target.value)
    const result = await matchAliasName({
      name: e.target.value,
    })

    const regex = /^[.\w-]*$/

    if (regex.test(e.target.value)) {
      setErrors(result.res.message)
    } else {
      setErrors('Input must only contain word characters')
    }
  }

  //   //console.log((aliasName, 'check')

  return (
    <div className="w-full bg-loginBg">
      <div className="grid lg:grid-cols-2 md:grid-cols-1  lg:gap-4  md:gap-0   mobileresposiveauth">
        <div className="w-full h-full">
          <div className="glpLoginBgBanner">
            <img src={Logo} alt="bg banner" className="w-1/2" />
          </div>
        </div>
        <div className="flex justify-center items-center py-8 ">
          <div className="w-2/3 mx-auto auth-container ">
            <img
              src={CornerImg}
              alt="corner img"
              className="h-24 w-24 absolute right-0 top-0 cornerImg"
            />
            <div className="w-2/3 mx-auto mobileformscreen">
              <div className="flex justify-center mobilelogo">
                <img src={mibilelogo} alt="" />
              </div>
              <h2 className="primary-color font-sans text-2xl	 font-bold mb-10 mobilescreentitle">
                {`${t('Sign Up')}`}
              </h2>
              <form
                className="auth-form authFormInputStyle forSignUpAuthForm"
                onSubmit={handleFormSubmit}
              >
                {/* <select name="language" id="language" className="languageSelect" value={formData.language} onChange={handleInputChange}>
                                    <option value="" disabled selected>Preffered Language</option>
                                    {
                                        languageData?.map((data) => {
                                            return (
                                                <option value={data?.name} key={data?._id}>{data?.name}</option>
                                            )
                                        })
                                    }
                                </select> */}
                <input
                  type="text"
                  placeholder={`${t('Full Name')}`}
                  name="fullname"
                  className="fullname"
                  value={formData.fullname}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  placeholder={`${t('Alias (Imaginary Name for Privacy)')}`}
                  name="aliasName"
                  value={aliasName}
                  onChange={handleChangeAliasName}
                  maxLength={14}
                />

                {errors === '' ? (
                  ''
                ) : errors === 'available' ? (
                  ''
                ) : (
                  <p
                    className={`text-sm text-left text-danger pt-1 ${
                      errors !== '' && errors !== 'available' && 'mb-8'
                    }`}
                  >
                    {errors}
                  </p>
                )}

                <div className=" flex w-100">
                  <div
                    className="flex items-center countrycode cursor-pointer"
                    onClick={() => setModalShow(true)}
                  >
                    <div className="country-img">
                      <img src={countryCode.Icon} alt="" />
                    </div>

                    <p>{countryCode.cntyCode}</p>
                  </div>
                  <input
                    type="text"
                    placeholder={`${t('Enter Mobile Number')}`}
                    name="phoneNumber"
                    minLength={8}
                    maxLength={12}
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                  />
                </div>
                {/* <PhoneInput
                                    international
                                    placeholder="Enter Mobile Number"
                                    defaultCountry="IN"
                                    withCountryCallingCode
                                    countryCallingCodeEditable={false}
                                    value={mobileCode}
                                    onChange={handleMobileChange}
                                    onCountryChange={handleCountryChange}
                                /> */}
                <div className="sectorSelectMain w-full">
                  <select
                    name="sector"
                    id="sector"
                    className="sectorSelect"
                    value={formData.sector}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled selected>{`${t(
                      'sector',
                    )}`}</option>
                    {sectorData?.map((data) => {
                      return (
                        <option value={data?.name} key={data?._id}>
                          {data?.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className="ageandGenderMain w-full">
                  <input
                    type="text"
                    placeholder={`${t('age')}`}
                    name="age"
                    maxLength={2}
                    className="ageInput"
                    value={formData.age}
                    onChange={handleInputChange}
                  />
                  <select
                    name="gender"
                    id="gender"
                    className="genderSelect"
                    onChange={handleInputChange}
                  >
                    <option value="" disabled selected>{`${t(
                      'gender',
                    )}`}</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <input
                  type="password"
                  placeholder={`${t('Enter Password')}`}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <input
                  type="password"
                  placeholder={`${t('Confirm Password')}`}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                />
                <FormGroup className="checkboxsignup w-full ">
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        color="success"
                        checked={isTrue}
                        onChange={(e) => setIstrue(e.target.checked)}
                      />
                    }
                  />{' '}
                  <span
                    className="termsAndCondition cursor-pointer"
                    onClick={() => setRulesModalShow(true)}
                  >
                    <p>{`${t('Terms & Conditions')}`}</p>
                  </span>
                </FormGroup>
                <button
                  className={`${
                    isButtonActive ? 'ActiveGetOtpBtn' : 'inactiveGetOtpBtn'
                  } mt-2`}
                >
                  {' '}
                  {`${t('Sign Up')}`}
                </button>
              </form>
              <div className="text-center signUpSectionLogin">
                {/* <p className="mt-8">{`${t('Not Registered Yet')}`}</p> */}
                <button
                  className="btn signUpBtn mt-8"
                  onClick={handlesignInClick}
                >{`${t('Already Registered')}`}</button>
              </div>
            </div>
          </div>
          <TermConditionModal
            show={rulesmodalShow}
            onHide={() => setRulesModalShow(false)}
          />
          <CountryModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            setModalShow={setModalShow}
            setCountryCode={setCountryCode}
          />
        </div>
      </div>
    </div>
  )
}

export default Signup
