import React from 'react';
import CourseImg from "../../Assets/images/gallery/gallerybanner.png";
import courseType from  "../../Assets/images/elearning/courseType.png"
import inProgress from  "../../Assets/images/elearning/inprogress.png"
import completed from  "../../Assets/images/elearning/completed.png"
import time from  "../../Assets/images/elearning/time.svg"
import { BASE_URL_IMG } from '../../Utils/services';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SkillTraning = ({skill,skillprogress,skillcommpleted}) => {
  let navigate = useNavigate();  
  const { t } = useTranslation(['home'])

  const handleSkillProgress =(item) =>{
    let _Id =item._id;
    if(skillcommpleted.includes(_Id)){
      return(
   
        <div className='flex items-center'>
            <img src={completed} alt="" className='h-3' />
            <span className='green ml-1'>{`${t('Completed')}`}</span>
        </div> 
      ) 
    }
    else if (skillprogress.includes(_Id)){
      return(
   
        <div className='flex items-center'>
         <img src={inProgress} alt="" className='h-5' />
          <span className='progres'>progress</span>
        </div>
      ) 
    }
    else{
      return(
        <div></div>
      )
    }
  
  }


  //function//

  const handleSkillDtails=(item)=>{
    navigate(`/skills-training/details`, {
      state: {
        item:item
       }
    });

  }


   return (
    <div className='row'>
         <div className='legmainheader my-3'>
            <h1>
            {`${t('New Courses')}`}
            </h1>
         </div>
         {skill && skill.map((item)=>(
            <div className="col-6 mb-2 mt-1">
            <div>
                <div className='mb-2 cursor-pointer' onClick={()=>handleSkillDtails(item)} >
                  <img src={`${BASE_URL_IMG}${item.image}`} alt="" className='rounded-lg '/>
                </div>           
                <h3 className='course-heading mb-2'>{item.title}</h3>
                <div className='flex items-center justify-between skill-media'>
                    <div className='flex'>
                        <div className='flex items-center'>
                          <img src={time} alt="" className='h-3' />
                          <span className='text-blue ml-1'>{item.CourseDuretion}</span>
                        </div>
                        <div className='ml-3 flex items-center'>
                           <img src={courseType} alt="" className='h-3' />
                            <span className='text-blue ml-1'>{item.CourseType}</span>
                         </div>

                    </div>
                    <div>                    
                        {handleSkillProgress(item)}
                    </div>
                 
                </div>
               
            </div>
        </div>

         ))}
        
     
    </div>
  )
}

export default SkillTraning