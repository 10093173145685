import React, { useState } from 'react';
import { languageMap } from "../Constant";

const SpeechRecognitionComponent = ({ setAddcomment }) => {
  const [listening, setListening] = useState(false);
  // const [finalTranscript, setFinalTranscript] = useState('');

  const toggleListening = () => {
    if (!listening) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      const language = localStorage.getItem("language") || "en"; // Default to English if language not found in localStorage
      recognition.lang = languageMap[language] || "en-US"; // Set your desired language code

      recognition.onresult = (event) => {
        let interim_transcript = '';
        let final_transcript = '';

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            final_transcript += event.results[i][0].transcript;
            // document.getElementById('transcript').value = final_transcript;
            setListening(false);
            setAddcomment(final_transcript)
            recognition.stop();
          } else {
            interim_transcript += event.results[i][0].transcript;
            document.getElementById('mention-input').value = interim_transcript;
            // setFinalTranscript(interim_transcript)

          }
        }

        console.log(interim_transcript, final_transcript);

        setAddcomment(final_transcript);
      };

      recognition.start();
    } else {
      // If listening is true, stop recognition.
      const recognition = new window.webkitSpeechRecognition();
      recognition.stop();
    }

    setListening(!listening);
  };

  return (
    <div>
      {/* <textarea id="transcript" value={finalTranscript} readOnly /> */}
      <button onClick={toggleListening}>
        {listening ? 'Stop Recording' : 'Start Recording'}
      </button>
    </div>
  );
};

export default SpeechRecognitionComponent;
