export const languageMap = {
    "cmn": "zh-CN", // Mandarin Chinese
    "spa": "es-ES", // Spanish
    "English": "en-US", // English
    "rus": "ru-RU", // Russian
    "arb": "ar-SA", // Standard Arabic
    "ben": "bn-BD", // Bengali
    "hin": "hi-IN", // Hindi
    "por": "pt-BR", // Portuguese
    "ind": "id-ID", // Indonesian
    "jpn": "ja-JP", // Japanese
    "fra": "fr-FR", // French
    "deu": "de-DE", // German
    "jav": "jv-ID", // Javanese
    "kor": "ko-KR", // Korean
    "tel": "te-IN", // Telugu
    "vie": "vi-VN", // Vietnamese
    "mar": "mr-IN", // Marathi
    "ita": "it-IT", // Italian
    "tam": "ta-IN", // Tamil
    "tur": "tr-TR", // Turkish
    "urd": "ur-PK", // Urdu
    "guj": "gu-IN", // Gujarati
    "pol": "pl-PL", // Polish
    "ukr": "uk-UA", // Ukrainian
    "kan": "kn-IN", // Kannada
    "mai": "mai-IN", // Maithili
    "mal": "ml-IN", // Malayalam
    "pes": "fa-IR", // Iranian Persian
    "mya": "my-MM", // Burmese
    "swh": "sw-KE", // Swahili
    "sun": "su-ID", // Sundanese
    "ron": "ro-RO", // Romanian
    "pan": "pa-IN", // Panjabi
    "bho": "bho-IN", // Bhojpuri
    "amh": "am-ET", // Amharic
    "hau": "ha-NE", // Hausa
    "fuv": "ff-NG", // Nigerian Fulfulde
    "bos": "bs-BA", // Bosnian (Latin)
    "hrv": "hr-HR", // Croatian
    "nld": "nl-NL", // Dutch
    "srp": "sr-RS", // Serbian (Latin)
    "tha": "th-TH", // Thai
    "ckb": "ckb-IQ", // Central Kurdish
    "yor": "yo-NG", // Yoruba
    "uzn": "uz-UZ", // Northern Uzbek (Latin)
    "zlm": "ms-MY", // Malay (individual language) (Latin)
    "ibo": "ig-NG", // Igbo
    "npi": "ne-NP", // Nepali (individual language)
    "ceb": "ceb-PH", // Cebuano
    "skr": "skr-PK", // Saraiki
    "tgl": "tl-PH", // Tagalog
    "hun": "hu-HU", // Hungarian
    "azj": "az-AZ", // North Azerbaijani (Latin)
    "sin": "si-LK", // Sinhala
    "koi": "koi-RU", // Komi-Permyak
    "ell": "el-GR", // Modern Greek
    "ces": "cs-CZ", // Czech
    "mag": "mag-IN", // Magahi
    "run": "rn-BI", // Rundi
    "bel": "be-BY", // Belarusian
    "plt": "mg-MG", // Plateau Malagasy
    "qug": "qu-EC", // Chimborazo Highland Quichua
    "mad": "mad-ID", // Madurese
    "nya": "ny-MW", // Nyanja
    "zyb": "za-CN", // Yongbei Zhuang
    "pbu": "ps-PK", // Northern Pashto
    "kin": "rw-RW", // Kinyarwanda
    "zul": "zu-ZA", // Zulu
    "bul": "bg-BG", // Bulgarian
    "swe": "sv-SE", // Swedish
    "lin": "ln-CD", // Lingala
    "som": "so-SO", // Somali
    "hms": "hms-CN", // Southern Qiandong Miao
    "hnj": "hnj-CN", // Hmong Njua
    "ilo": "ilo-PH", // Iloko
    "kaz": "kk-KZ", // Kazakh
    "uig": "ug-CN", // Uighur (Latin)
    "hat": "ht-HT", // Haitian
    "khm": "km-KH", // Khmer
    "prs": "prs-AF", // Dari
    "hil": "hil-PH", // Hiligaynon
    "sna": "sn-ZW", // Shona
    "tat": "tt-RU", // Tatar
    "xho": "xh-ZA", // Xhosa
    "hye": "hy-AM", // Armenian
    "min": "min-ID", // Minangkabau
    "afr": "af-ZA", // Afrikaans
    "lua": "lua-CD", // Luba-Lulua
    "sat": "sat-IN", // Santali
    "bod": "bo-CN", // Tibetan
    "tir": "ti-ER", // Tigrinya
    "fin": "fi-FI", // Finnish
    "slk": "sk-SK", // Slovak
    "tuk": "tk-TM", // Turkmen (Latin)
    "dan": "da-DK", // Danish
    "nob": "nb-NO", // Norwegian Bokmål
    "suk": "suk-TZ", // Sukuma
    "als": "sq-AL", // Tosk Albanian
    "sag": "sg-CF", // Sango
    "nno": "nn-NO", // Norwegian Nynorsk
    "heb": "he-IL", // Hebrew
    "mos": "mos-BF", // Mossi
    "tgk": "tg-TJ", // Tajik
    "cat": "ca-ES", // Catalan
    "sot": "st-ZA", // Southern Sotho
    "kat": "ka-GE", // Georgian
    "bcl": "bcl-PH", // Central Bikol
    "glg": "gl-ES", // Galician
    "lao": "lo-LA", // Lao
    "lit": "lt-LT", // Lithuanian
    "umb": "umb-AO", // Umbundu
    "tsn": "tn-BW", // Tswana
    "vec": "vec-IT", // Venetian
    "nso": "nso-ZA", // Pedi
    "ban": "ban-ID", // Balinese
    "bug": "bug-ID", // Buginese
    "knc": "kr-NG", // Central Kanuri
    "kng": "kng-CD", // Koongo
    "ibb": "ibb-NG", // Ibibio
    "lug": "lg-UG", // Ganda
    "ace": "ace-ID", // Achinese
    "bam": "bam-ML", // Bambara
    "tzm": "tzm-MA", // Central Atlas Tamazight
    "ydd": "yi-001", // Eastern Yiddish
    "kmb": "kmb-AO", // Kimbundu
    "lun": "lun-ZM", // Lunda
    "shn": "shn-MM", // Shan
    "war": "war-PH", // Waray
    "dyu": "dyu-BF", // Dyula
    "wol": "wo-SN", // Wolof
    "kir": "ky-KG", // Kirghiz
    "nds": "nds-DE", // Low German
    "fuf": "ff-SN", // Pular
    "mkd": "mk-MK", // Macedonian
    "vmw": "vmw-MZ", // Makhuwa
    "zgh": "zgh-MA", // Standard Moroccan Tamazight
    "ewe": "ee-GH", // Ewe
    "khk": "khk-MN", // Halh Mongolian
    "slv": "sl-SI", // Slovenian
    "ayr": "ayr-BO", // Central Aymara
    "bem": "bem-ZM", // Bemba
    "emk": "emk-GN", // Eastern Maninkakan
    "bci": "bci-CI", // Baoulé
    "bum": "bum-CM", // Bulu (Cameroon)
    "epo": "eo-001", // Esperanto
    "pam": "pam-PH", // Pampanga
    "tiv": "tiv-NG", // Tiv
    "tpi": "tpi-PG", // Tok Pisin
    "ven": "ve-ZA", // Venda
    "ssw": "ss-ZA", // Swati
    "nyn": "nyn-UG", // Nyankole
    "kbd": "kbd-RU", // Kabardian
    "iii": "ii-CN", // Sichuan Yi
    "yao": "yao-MW", // Yao
    "lvs": "lv-LV", // Standard Latvian
    "quz": "quz-PE", // Cusco Quechua
    "src": "src-IT", // Logudorese Sardinian
    "rup": "rup-RO", // Macedo-Romanian
    "sco": "sco-GB", // Scots
    "tso": "ts-ZA", // Tsonga
    "men": "men-SL", // Mende
    "fon": "fon-BJ", // Fon
    "nhn": "nhn-MX", // Central Nahuatl
    "dip": "dip-SS", // Northeastern Dinka
    "kde": "kde-TZ", // Makonde
    "kbp": "kbp-TG", // Kabiyè
    "tem": "tem-SL", // Timne
    "toi": "toi-ZM", // Tonga (Zambia)
    "ekk": "et-EE", // Standard Estonian
    "snk": "snk-SN", // Soninke
    "cjk": "cjk-AO", // Chokwe
    "ada": "ada-GH", // Adangme
    "aii": "aii-IR", // Assyrian Neo-Aramaic
    "quy": "quy-PE", // Ayacucho Quechua
    "rmn": "rmn-RO", // Balkan Romani
    "bin": "bin-NG", // Bini
    "gaa": "gaa-GH", // Ga
    "ndo": "ng-NA" // Ndonga
};
