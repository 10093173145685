import React, { useContext } from 'react'
import { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import Select from 'react-select'
import {
  editProfileApi,
  getLanguageApi,
  getSectorApi,
  getTagFormService,
} from '../../Utils/services'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalContext } from '../../Context'
import Phone from '../../ModalTemplate/Phone'
import DeleteProfile from '../../ModalTemplate/DeleteProfile'
import AuthContext from '../../Utils/auth-context'
import LgoutModal from '../../ModalTemplate/LgoutModal'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css'
import ConfirmSubmit from '../../ModalTemplate/ConfirmSubmit'

const ProfileUserDetail = ({
  proFile,
  fullName,
  setFullName,
  aliasName,
  setAliasName,
  language,
  setLanguage,
  age,
  setAge,
  gender,
  setGender,
  sectordata,
  setSectordata,
  editProfile,
  code,
  getProfileDetails,
  errors,
}) => {
  const [disble, setDesble] = useState(false)
  const [selected, setSelected] = useState()
  const [option, setOption] = useState([])
  const [languages, setLanguages] = useState([])
  const { t } = useTranslation(['home'])
  const modalContext = useContext(ModalContext)
  const { closeModal, handleModalData } = modalContext
  const authCtx = useContext(AuthContext)
  const { logout } = authCtx



  const handleDisable = () => {
    setDesble(false)
  }

  const handleChange = (e) => {
    setFullName(e.target.value)
  }

  const handleSelectbutton = (e) => {
    setLanguage(e.target.value)
  }

  const getTag = async () => {
    try {
      const result = await getSectorApi({"language":proFile?.language?.name})

      let optionData = result.res.data.map(function (item) {
        return { label: item.name, value: item.name }
      })
      setOption(optionData)
    } catch (error) {}
  }

  const handlePhoneNumberChange = (number) => {
    const countryNumber = `+${code.country_code}${number}`
    const PHONENUMBER = (
      <Phone number={countryNumber} getProfileDetails={getProfileDetails} />
    )
    handleModalData(PHONENUMBER, 'sm')
  }

  //update //
  useEffect(() => {
    getTag()
  }, [proFile])

  useEffect(() => {
    const fetchLanguageData = async () => {
      const result = await getLanguageApi();
      setLanguages(result.res.data.languages);
    };
  
    fetchLanguageData();
  }, []);

  const HandleLogOut = () => {
    const deleteProfile = <LgoutModal />
    handleModalData(deleteProfile, 'sm')
  }

  const handleConfirm = () => {
    const deleteProfile = <ConfirmSubmit editProfile={editProfile} />
    handleModalData(deleteProfile, 'sm')
  }

  return (
    <div className="user-profile pt-3">
      <div className="my-4">
        <h2>{`${t('Profile Details')}`}</h2>
      </div>
      <form action="">
        <Row>
          <Col md={3} className="flex items-center">
            <label htmlFor="" className="text-blue font-semibold">
              {`${t('Full Name')}`}
            </label>
          </Col>
          <Col md={8} className="flex items-center flex-column">
            <div className="fromBg w-100 px-4" onClick={handleDisable}>
              <input
                type="text"
                disabled={disble}
                placeholder="full name"
                value={fullName}
                onChange={handleChange}
                maxLength={20}
              />
              {/* {
                      errors.title && <p className="text-sm text-left text-danger pt-1">{errors.title}</p>
                      } */}
            </div>
          </Col>

          <Col md={3} className="flex items-center mt-3">
            <label htmlFor="" className="text-blue font-semibold">
              {`${t('Alias Name')}`}
            </label>
          </Col>
          <Col md={8} className="flex items-center mt-3">
            <div className="fromBg w-100 px-4" onClick={handleDisable}>
              <input
                type="text"
                placeholder="User Name"
                disabled={disble}
                value={aliasName}
                onChange={(e) => setAliasName(e.target.value)}
                maxLength={20}
              />
            </div>
          </Col>

          <Col md={3} className="flex items-center mt-3">
            <label htmlFor="" className="text-blue font-semibold">
              {`${t('language')}`}
            </label>
          </Col>
          <Col md={8} className="flex items-center mt-3">
            <div className="fromBg w-100 pl-4 pe-4" onClick={handleDisable}>
              <select
                name="gender"
                id="gender"
                className="genderSelect bg-transparent w-100 outline-none"
                value={language}
                onChange={handleSelectbutton}
                disabled={disble}
              >
                <option value="" disabled selected>
                  language
                </option>
                {/* <option value="Hindi">Hindi</option> */}

                {languages &&
                  languages.map((item) => (
                    <option value={item.name}>{item.name}</option>
                  ))}
              </select>
            </div>
          </Col>
          <Col md={3} className="flex items-center mt-3">
            <label htmlFor="" className="text-blue font-semibold">
              {`${t('country')}`}
            </label>
          </Col>
          <Col md={8} className="flex items-center mt-3">
            <div className="fromBg w-100 px-4">
              <input
                type="text"
                placeholder="Country"
                disabled
                value={proFile?.country?.name}
              />
            </div>
          </Col>
          <Col md={3} className="flex items-center mt-3">
            <label htmlFor="" className="text-blue font-semibold">
              {`${t('sector')}`}
            </label>
          </Col>
          <Col md={8} className="flex items-center mt-3">
            <div className="fromBg w-100  pe-4" onClick={handleDisable}>
              <select
                name="sector"
                id="setordata"
                disabled={disble}
                className="genderSelect bg-transparent w-100 outline-none"
                value={sectordata && sectordata}
                defaultValue={sectordata && sectordata}
                onChange={(e) => setSectordata(e.target.value)}
              >
                {option &&
                  option.map((item) => (
                    <option value={item.value}>{item.value}</option>
                  ))}
              </select>
            </div>
          </Col>
          <Col md={3} className="flex items-center mt-3">
            <label htmlFor="" className="text-blue font-semibold">
              {`${t('age')}`}
            </label>
          </Col>
          <Col md={8} className="flex items-center mt-3">
            <div className="fromBg w-100 px-4" onClick={handleDisable}>
              <input
                type="number"
                className="agescroll"
                placeholder="Age"
                maxLength={2}
                disabled={disble}
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
            </div>
          </Col>
          <Col md={3} className="flex items-center mt-3">
            <label htmlFor="" className="text-blue font-semibold">
              {`${t('gender')}`}
            </label>
          </Col>
          <Col md={8} className="flex items-center mt-3">
            <div className="fromBg w-100  pl-4 pe-4" onClick={handleDisable}>
              {/* <input type="text" placeholder="Gender" /> */}
              <select
                name="gender"
                id="gender"
                disabled={disble}
                className="genderSelect bg-transparent w-100 outline-none"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="" disabled selected>
                  Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </Col>
          <Col md={3} className="flex items-center mt-3">
            <label htmlFor="" className="text-blue font-semibold">
              {`${t('Phone Number')}`}
            </label>
          </Col>
          <Col
            md={8}
            className="flex items-center mt-3"
            // onClick={() => handlePhoneNumberChange(proFile?.phone)}
          >
            <div className="fromBg w-100 px-4 cursor-pointer">
              <input
                type="text"
                placeholder="Phone Number"
                value={proFile?.phone}
                disabled
                className="cursor-pointer"
              />
            </div>
          </Col>
        </Row>
        <div className="mt-3 flex">
          <button className="delete mr-4" type="button" onClick={HandleLogOut}>
            {`${t('Log Out')}`}
          </button>
          <button
            className={`${disble ? 'greybtn' : 'submit-btn'}`}
            type="button"
            disabled={disble}
            // onClick={editProfile}
            onClick={handleConfirm}
          >
            {`${t('Submit')}`}
          </button>
        </div>
      </form>
    </div>
  )
}

export default ProfileUserDetail
