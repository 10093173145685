import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import inputbtn from "../Assets/images/Social/inputbtn.png";
import { MentionData } from "../Utils/services";
import SpeechRecognitionComponent from "../Components/SpeachtoText"


const InputText = ({ handleAddcomment, setAddcomment, addcomment }) => {
  const { t } = useTranslation(["home"]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [mentionleter, setMentionLetter] = useState("");

  useEffect(() => {
    if (showSuggestions && addcomment.includes("@")) {
      const mentionName = addcomment
        .substring(addcomment.lastIndexOf("@") + 1)
        .trim();
      fetchMentions(mentionName);
    }
  }, [showSuggestions, addcomment]);

  const handleSuggestion = (e) => {
    const newPlainTextValue = e.target.value;
    setAddcomment(newPlainTextValue);
    const words = newPlainTextValue.trim().split(" ");
    const lastWord = words[words.length - 1];

    if (lastWord.startsWith("@")) {
      setMentionLetter(lastWord.substring(1));
      setShowSuggestions(true);
    } else {
      setMentionLetter(null);
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    }
  };

  

  const fetchMentions = async (mentionName) => {
    setLoadingSuggestions(true);
    //console.log((mentionleter, "mentionleter");
    if (mentionleter !== "") {
      try {
        const result = await MentionData({
          query: mentionleter,
          country: "62b05555a27141abc1fd80d0",
        });
        setFilteredSuggestions(result.res.data);
      } catch (error) {
        console.error("Error fetching mentions:", error);
      }
    }
    setLoadingSuggestions(false);
  };

  const handleSuggestionClick = (suggestion) => {
    const mentionRegex = /@\S+\s?$/; // Regular expression to match the mention
    const updatedComment = addcomment.replace(
      mentionRegex,
      `@${suggestion.aliasName} `
    );
    setAddcomment(updatedComment);
    setShowSuggestions(false);
  };

  const renderSuggestions = () => {
    if (showSuggestions) {
      // if (loadingSuggestions) {
      //   return (
      //     <div className="mention-suggestion p-4"> Loading suggestions...</div>
      //   );
      // }

      const suggestionStyle = {
        position: "absolute",
        top: "29%", // Adjust the value based on your desired position
        // left: inputLeft,
        maxHeight: "200px",
        overflowY: "scroll",
        width: "90%", // Set the width to match the input width
        background: "#ffffff", // Add background color
        boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)", // Add box shadow
        zIndex: 1, // Ensure the suggestion box is above other elements
      };

      return (
        <div className="mention-suggestions" style={suggestionStyle}>
          {filteredSuggestions.map((mention) => (
            <div
              key={mention.id}
              onClick={() => handleSuggestionClick(mention)}
              className="mention-suggestion p-4"
            >
              <div className="w-14">
                <img
                  className="w-9 h-9 rounded-full cursor-pointer"
                  src={`https://d1v4waqh4od8qe.cloudfront.net/user/${mention._id}/${mention._id}.jpeg`}
                  alt=""
                  srcset=""
                />
              </div>
              {mention.aliasName}
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <div>{renderSuggestions()}</div>
      <form action="" onSubmit={handleAddcomment}>
        <div
          id="focusinput"
          className="flex justify-between border p-2 my-3 rounded-xl"
        >
          <textarea
            style={{ resize: "none" }}
            id="mention-input" // Add id attribute
            type="text"
            value={addcomment}
            onChange={handleSuggestion}
            placeholder={t("Write a comment")}
            onKeyDown={(e) => {
              if (e.shiftKey && e.key === "Enter") {
                e.preventDefault(); // Prevent the default behavior (inserting a newline)
                setAddcomment(addcomment + "\n");
              }
              if (!e.shiftKey && e.key === "Enter") {
                handleAddcomment(e)
              }
            }}
            className="commentinput"
          />
          <SpeechRecognitionComponent setAddcomment={setAddcomment} />
          <button className="w-10 ">
            <img src={inputbtn} alt="" className="w-5" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default InputText;
