import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import Filter from "../../Common/Filter";
import SearchFilter from "../../Common/SearchFilter";
import SearchGalary from "../../Common/SearchHeader/SearchGalary";
import SearchTool from "../../Common/SearchHeader/SearchTool";

import Legalmain from "../../Resources/Legal_Provisions/Legalmain";
import {
  DownloadToolCount,
  getToolkit,
  ViewToolkitCount,
} from "../../Utils/services";

const ToolKit = () => {
  const [document, setDocument] = useState([]);
  const [video, setVideo] = useState([]);
  const [loader, setloader] = useState(false);
  const [filter, setFilter] = useState("");
  const [filterTag, setFilterTag] = useState("");

  const toolKitData = async () => {
    setloader(true);
    const result = await getToolkit();

    setloader(false);
    setDocument(result.res.pdfdata);
    setVideo(result.res.videodata);
  };

  const HandaleViewCount = async (item) => {
    const result = await ViewToolkitCount({
      id: item._id,
    });
  };

  const HandleDownloadCount = async (item) => {
    const result = await DownloadToolCount({
      id: item._id,
    });
  };

  useEffect(() => {
    toolKitData();
  }, []);

  return (
    <Container>
      <Row>
        <div className="mobilesearch mt-20">
          <SearchTool setFilterForum={setFilter} />
        </div>

        <Col lg={7} className="toolmainbar">
          {loader ? (
            <div className="mt-20 flex justify-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Legalmain
              HandaleViewCount={HandaleViewCount}
              HandleDownloadCount={HandleDownloadCount}
              pdfData={document}
              videoData={video}
              filter={filter}
            />
          )}
        </Col>
        <Col lg={5} className="toolsidebar">
          <SearchFilter setGalleryFilter={setFilter} />
        </Col>
      </Row>
    </Container>
  );
};

export default ToolKit;
