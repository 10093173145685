import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { getTagFormService } from "../../Utils/services";
import { useTranslation } from "react-i18next";

const FiterBy = ({ setFilterForum,setTagFilter,selectedcourse,setselectedCourse}) => {
  const [tag, setTag] = useState([]);
  const { t } = useTranslation(['home'])
  const getTag = async () => {
    try {
      const result = await getTagFormService();

      setTag(result.res.data);
      //console.log((result.res.data, "response");
    } catch (error) {
      // toast.error("list not found");
    }
  };

  //update //
  useEffect(() => {
    getTag();
  }, []);

  const handlecheckbox = (e) => {
    var temp = selectedcourse;
    if (e.target.checked === false) {
        temp = temp.filter((a) => {
            return a != e.target.value;
        });
    }
    e.target.checked
        ? setselectedCourse([...selectedcourse, e.target.value])
        : setselectedCourse([...temp]);


};

//console.log((selectedcourse,"data")
  return (
    <section className="filterSection px-4 pb-4">
      <h3>{`${t('Filter By')}`}</h3>
      <div className="h-48 overflow-y-scroll " id="filtersrollbar">


{tag &&
  tag.map((item,i) => {
    return (
      <React.Fragment key={i}  >
         
         <ul class="ux-btn-group">

            <>
            <li key={i} className="py-2 " >
                  <input type="checkbox" value={item.name} id={`${item.name}`} class="ux-btn-group-item cursor-pointer" name="option" onClick={handlecheckbox} />
                  <label for={`${item.name}`} className="ml-3 cursor-pointer"  >{item.name}</label>
            </li>
           </>

         </ul>
     

      
        
         {/* <div onClick={()=>handleActive(i)} > <p key={i} onClick={()=>setTagFilter(item.name)} className= {`${active ===i ? "activefilter" :null} cursor-pointer`} >{item.name}</p>  </div> */}
         
         
       </React.Fragment>                     
  
    );
  })}
</div>
    </section>
  );
};

export default FiterBy;
