import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { BASE_URL_IMG, CompleateTask, GetSubSession } from '../../../../Utils/services';
import TestTask from './TestTask';
import { useTranslation } from 'react-i18next';

const Task = () => {
  const location = useLocation();
  const [src, setSrc] = useState();
  const [pdf, setPdf] = useState();
  const [qusType, setQusType] = useState();
  const [videoLink, setVideoLink] = useState();
  const [mcq, setMcq] = useState()
  let navigate = useNavigate();
  const { t } = useTranslation(['home'])
  const userName = localStorage.getItem("adminName");
  const userNameID = localStorage.getItem("adminId");


  const GetTaskApi = async (circulumId, sessionId, subId) => {
    const result = await GetSubSession({
      "courseId": circulumId,
      "sessionId": sessionId,
      "sub_SessionId": subId
    });
    setSrc(result.res.sub_Session[0]?.pdfimg)
    setPdf(result.res.sub_Session[0]?.media?.link)
    setVideoLink(result.res.sub_Session[0]?.media?.mp4link)
    setQusType(result.res.sub_Session[0]?.QuestionType)
    setMcq(result.res.sub_Session)

  }


  useEffect(() => {
    if (location) {
      const { circulumId, sessionId, subId } = location && location.state;
      GetTaskApi(circulumId, sessionId, subId)
    }

  }, [])

  const handleFinshTopic = async () => {
    console.log(location?.state);
    if (location?.state?.isEnd) {
      await CompleateTask({
        isfirst: false,
        courseId: location.state?.circulumId
      })
    }
    navigate(-1)
  }


  return (

    <Container>
      <Row>
        <Col lg={7} className="skillmain mt-20">

          {
            qusType === "pdf"
            &&
            <div className=''>
              <div className=''>
                <img src={`${BASE_URL_IMG}${src}`} alt="" className='' />
              </div>
              <div>
                <p className='text-center'>To read pdf download  <a href={`${BASE_URL_IMG}${pdf}`} download className='text-blue'> this url link</a></p>
              </div>
              <div className='flex justify-center'>
                <button className='finish' onClick={handleFinshTopic}>{`${t('Finish Topic')}`}</button>
              </div>

            </div>

          }

          {
            qusType === "video"
            &&
            <div>
              {
                `${BASE_URL_IMG}${src}`
                &&
                <div>
                  <video width="100%" height="100%" controls={false} autoPlay={true} >
                    <source src={`${BASE_URL_IMG}${videoLink}`} type="video/mp4" />
                  </video>
                  <div className='flex justify-center mt-8'>
                    <button className='finish' onClick={handleFinshTopic}>{`${t('Finish Topic')}`}</button>
                  </div>
                </div>
              }

            </div>
          }

          {
            qusType === "Single"
            &&
            <div className=''>
              <TestTask mcq={mcq} />
            </div>
          }



        </Col>
        <Col lg={5} className="skillsidebar">
          <div className="rightSideBar">
            <div className="flex items-center mb-4 mt-1" onClick={() => navigate('/profile')}>
              <div className="w-10 mr-2 cursor-pointer">
                <img src={`${BASE_URL_IMG}/user/${userNameID}/${userNameID}.jpeg`} className="" alt="" />
              </div>
              <div>
                <h3 className="aliasname cursor-pointer" onClick={() => navigate('/profile')}> {userName || "Alias Name"}</h3>
                <p className="underline text-blue cursor-pointer" onClick={() => navigate('/profile')}>View Profile</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>

  )
}

export default Task