import logo from "../../Assets/images/glplogo.png";
import { otpVerificationApi } from "../../Utils/services";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import authBg from "../../Assets/images/bg_banner.png";
import OtpInput from "react-otp-input";
import Logo from "../../Assets/images/glp-logo-white.png";
import CornerImg from "../../Assets/images/signincorner.png";
import mibilelogo from "../../Assets/images/glplogotwo.png"
import { useNavigate } from "react-router/dist";


const OtpVerification = () => {
  const [otp, setOtp] = useState();
  const location = useLocation();
  const [emailValue, setEmailValue] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [CuntryCode,setCuntryCode] =useState() 
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (location.state !== undefined) {
      const { verification_key,phone,code } = location.state;
      setEmailValue(phone);
      setVerificationCode(verification_key);
      setCuntryCode(code)
    //   //console.log((verification_key, email, "verification_key, email,");
    }
    if (location.state === undefined) {
      navigate("/login");
    }
  }, []);

  const handleOtpChange = (e) => {
    //console.log((e);
    setOtp(e);
  };
  const handleOtpSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const result = await otpVerificationApi({
        phone: emailValue,
        verification_key: verificationCode,
        otp: parseInt(otp),
        code:CuntryCode
      });
      
      //console.log((result,"data")

      if (result.res.success) {
        setLoading(false);
        toast.success(result.res.message);
        localStorage.setItem('code', CuntryCode)
        navigate("/changepassword",{
          state: {
            phone: result.res.data.phone,
            token: result.res.data.token,
          },
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    // <div className="w-full relative">
    //     <img src={authBg} alt="" />
    //     <div className="flex absolute top-1/2 left-0 w-full">
    //         <div className="w-2/3 mx-auto auth-container">
    //             <div className="w-1/2 mx-auto text-center">
    //                 <img
    //                     src={logo}
    //                     className="mx-auto mb-10"
    //                     alt="logo"
    //                 />
    //                 <h2 className="primary-color font-sans text-2xl	 font-bold mb-10">
    //                     VERIFY OTP
    //                 </h2>
    //                 <form className="flex flex-col items-center" onSubmit={handleOtpSubmit}>
    //                     <div className="mt-2 flex otpSection justify-center items-center">
    //                         <OtpInput
    //                             value={otp}
    //                             onChange={handleOtpChange}
    //                             numInputs={6}
    //                             isInputNum={true}
    //                         />
    //                     </div>
    //                     {
    //                         loading ? <p>Loading...</p> :
    //                             <button
    //                                 className="primary-btn mt-10"
    //                                 type="submit"
    //                             >
    //                                 Verify OTP
    //                             </button>
    //                     }

    //                     <span
    //                         className="text-btn"
    //                     >
    //                         <Link to="/login">Remember password ?</Link>
    //                     </span>
    //                 </form>
    //             </div>
    //         </div>
    //     </div>
    // </div>

    <div className="w-full bg-loginBg">
      <div className="grid lg:grid-cols-2 md:grid-cols-1  lg:gap-4  md:gap-0   mobileresposiveauth">
        <div className="w-full h-full">
          <div className="glpLoginBgBanner">
            <img src={Logo} alt="bg banner" className="w-1/2" />
          </div>
        </div>
        <div className="flex justify-center items-center mobileLoginscreen">
          <div className="w-2/3 mx-auto auth-container">
            <img
              src={CornerImg}
              alt="corner img"
              className="h-24 w-24 absolute right-0 top-0 cornerImg"
            />
            <div className="w-1/2 mx-auto">
              <div className="flex justify-center mobilelogo">
                  <img src={mibilelogo} alt="" />
              </div>


              <h2 className="primary-color font-sans text-2xl	 font-bold mb-10 text-center">
                {" VERIFY OTP"}
              </h2>
              {/* <div className="auth-form authFormInputStyle"> */}
              <form
                className="flex flex-col items-center"
                onSubmit={handleOtpSubmit}
              >
                <div className="mt-2 flex otpSection justify-center items-center">
                  <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={5}
                    isInputNum={true}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                <div className="flex flex-column">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <button className="primary-btn mt-10 " type="submit">
                    Verify OTP
                  </button>
                )}

                <span className="text-btn">
                  <Link to="/login">Remember password ?</Link>
                </span>

                </div>
              
              </form>
            </div>
          </div>
        </div>
      </div>
     </div>
    // </div>
  );
};

export default OtpVerification;
