import React, { useContext } from 'react'
import { ModalContext } from '../Context';
import { FormDeleteComment} from '../Utils/services';
import { useTranslation } from 'react-i18next';

const DeleteFormComment = ({id,commentid,handlegetComment}) => {
  const { t } = useTranslation(['home'])
    const modalContext = useContext(ModalContext);
    const { closeModal} = modalContext;
    const deletePost = async () => {
        const result = await FormDeleteComment({
          "threadId": id,
          "commentId": commentid,
         });
          handlegetComment(id);
          closeModal();    
      }


  return (
    <div>
    
      <h3 className='font-semibold text-center' >{`${t('Are you sure you want to delete this comment?')}`}</h3>
       <div className='d-flex justify-between mt-4'>
          <button className='cancel-btn' onClick={()=>closeModal()} >{`${t('Cancel')}`}</button>
          <button className='delete-btn' onClick={deletePost}>{`${t('Delete')}`}</button>
       </div>
        
    </div>
  )
}

export default DeleteFormComment;